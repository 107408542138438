import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import clsx from "clsx";
import { admin_talent_project as $ } from "strings";
import Button from "../../../../../talent/components/Button";
import AddNewProjectModal from "../AddNewProjectModal";
import { ProjectList } from "./ProjectList";
import { groupAndSortProjects } from "./utils";
import { getTalentProjectsV2 } from "utils/adminApi";
import useCachedFetch from "hooks/useCachedFetch";

const projectGroupings = [
  { label: $.active_label, value: "active" },
  { label: $.candidate_label, value: "candidate" },
  { label: $.completed_label, value: "completed" },
  { label: $.rejected_label, value: "rejected" },
  {
    label: $.inactive_label,
    value: "inactive",
    description:
      "This talent was considered for the following projects but never officially hired or rejected. These projects have already been completed, lost, or filled with a different candidate."
  }
];

/** Selection of project statuses to display */
const ProjectStatusBar = ({ statuses, selectedStatus, setSelectedStatus }) => {
  return (
    <div className="flex cursor-pointer items-center border-b-2 font-bold">
      {statuses.map((status) => (
        <div
          key={status.value}
          className={clsx(
            "px-2.5 py-2",
            selectedStatus === status.value
              ? "relative border-b-2 border-electric-indigo text-electric-indigo"
              : "text-midnight"
          )}
          onClick={() => setSelectedStatus(status.value)}
        >
          {status.label}
          {status.count > 0 && ` (${status.count})`}
        </div>
      ))}
    </div>
  );
};

const ProjectsOverview = ({
  projects,
  loading,
  talent,
  onSelectProject,
  onUpdate
}) => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const selectedStatus = searchParams.get("status") || "active";

  const [showAddNewProjectModal, setShowAddNewProjectModal] = useState(false);

  useEffect(() => {
    if (!searchParams.has("status")) {
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.set("status", "active");
      history.replace({ search: newSearchParams.toString() });
    }
  }, [searchParams, history, location.search]);
  const groupedProjects = useMemo(() => {
    return groupAndSortProjects(projects);
  }, [projects]);

  const setSelectedStatus = (status) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("status", status);
    history.push({ search: searchParams.toString() });
  };

  const selectedStatusDescription = projectGroupings.find(
    (status) => status.value === selectedStatus
  )?.description;

  return (
    <div className="flex h-full flex-col rounded-2xl bg-white p-4">
      <div className="flex justify-between">
        {talent?.fullname && (
          <p className="text-xl font-bold">{talent.fullname} Projects</p>
        )}
        <Button
          className="ml-auto"
          noMinW
          onClick={() => {
            setShowAddNewProjectModal(true);
          }}
        >
          {$.add_project_title}
        </Button>
      </div>
      <div className="mt-4 flex min-h-0 flex-1 flex-col gap-2 text-sm text-midnight">
        {/* Selection for project status to display */}
        <ProjectStatusBar
          statuses={projectGroupings.map((status) => ({
            label: status.label,
            value: status.value,
            count: groupedProjects[status.value]?.length
          }))}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
        />
        {selectedStatusDescription && (
          <p className="mb-0.5 mt-1">{selectedStatusDescription}</p>
        )}
        {/* Project list for selected status */}
        <div className="mt-2 flex min-h-0 flex-1 flex-col rounded-lg bg-lightest-grey p-2">
          <ProjectList
            projects={groupedProjects[selectedStatus]}
            status={selectedStatus}
            loading={loading}
            onSelectProject={onSelectProject}
            refresh={onUpdate}
          />
        </div>
      </div>
      {showAddNewProjectModal && (
        <AddNewProjectModal
          onCancel={() => setShowAddNewProjectModal(false)}
          existingProjectIds={projects.map((p) => p.project_id)}
          onSave={() => {
            setShowAddNewProjectModal(false);
            onUpdate();
          }}
        />
      )}
    </div>
  );
};

export default ProjectsOverview;
