import React, { useEffect, useState, useMemo } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useLocation
} from "react-router-dom";
import NotFoundPage from "./NotFound";
import Section from "components/Section";
import ProfilePage from "./Profile";
import SettingsPage from "./Settings";
import AssessmentsPage from "./Assessments";
import TalentReview from "./TalentReview";
import {
  getUrlSuffix,
  getUserId,
  setTalentOnboarded,
  getTalentOnboarded,
} from "../../utils/localStorageService";
import { getTalentDataFromUrlSuffix } from "utils/api";
import { NavBar } from "./components";

const Page = ({adminLogged}) => {
  const { id: suffix } = useParams();
  const [talentData, setTalentData] = useState();
  const [talentUserLinks, setTalentUserLinks] = useState();
  const [isPublicProfile, setIsPublicProfile] = useState(true);
  const loggedInUserId = getUserId();
  const userSuffix = getUrlSuffix();
  const { pathname, search } = useLocation();
  const history = useHistory();

  const referrer = useMemo(() => {
    if (!pathname) return;
    return `${pathname}${search}`
  }, [pathname, search]);

  useEffect(() => {
    if (!talentData) return;
    if (getTalentOnboarded() === true) return;
    const userId = getUserId();
    if (!userId) {
      return;
    }
    if (!talentData.skills_survey_submitted_at) {
      history.push(`/talent/onboard?user=${userId}`);
      setTalentOnboarded(false);
    } else {
      setTalentOnboarded(true);
    }
  }, [talentData, history]);

  useEffect(() => {
    if (!suffix || suffix === "null") {
      window.location.href = "/404";
    }
    getTalentDataFromUrlSuffix(suffix)
      .then((response) => {
        setTalentData(response);
        setTalentUserLinks(response?.user?.user_links || []);
      })
      .catch(() => {
        window.location.href = "/404";
      });
  }, [suffix]);

  useEffect(() => {
    const forcePublic = window?.location?.search?.includes("public=true");
    setIsPublicProfile(talentData?.user?.id !== loggedInUserId || forcePublic);
  }, [talentData, loggedInUserId]);

  return (
    <Section
      id="talent_tech_page"
      className="font-lato bg-alice-blue-lighter min-h-full flex flex-col"
    >
      <div className="flex-1 justify-center">
        {talentData && (
          <NavBar talentData={talentData} isPublicProfile={isPublicProfile} />
        )}
        {talentData &&
          <Switch>
            <Route path="/engagements/review/:id">
              <TalentReview />
            </Route>
            <Route exact path="/404">
              <NotFoundPage />
            </Route>
            <Route exact path="/talent/:id/profile">
              <ProfilePage
                talentData={talentData}
                setTalentData={setTalentData}
                talentUserLinks={talentUserLinks}
                setTalentUserLinks={setTalentUserLinks}
                isPublicProfile={isPublicProfile}
              />
            </Route>
            <Route path="/talent/:id/settings">
              {(!loggedInUserId || loggedInUserId !== talentData?.user?.id) ? (
                (talentData && !talentData.user?.last_login) ?
                  (<Redirect to={{
                    pathname: "/talent/register",
                    state: { from: referrer }
                  }} />) : !adminLogged &&
                  (<Redirect to={{
                    pathname: "/login",
                    state: { from: referrer }
                  }} />)
              ) : (suffix === userSuffix) ? (
                <SettingsPage
                  talentData={talentData}
                  setTalentData={setTalentData}
                  isPublicProfile={isPublicProfile}
                />
              ) : (!userSuffix && !suffix) ? (
                <Redirect to={`/404`} />
              ) : (
                <Redirect to={`/talent/${userSuffix}/settings`} />
              )}
            </Route>
            <Route exact path="/talent/:id/expertise">
              {suffix === userSuffix ? (
                <AssessmentsPage
                  talentData={talentData}
                  setTalentData={setTalentData}
                  isPublicProfile={isPublicProfile}
                />
              ) : userSuffix ? (
                <Redirect to={`/talent/${userSuffix}/expertise`} />
              ) : (
                <Redirect to={`/404`} />
              )}
            </Route>
            <Route exact path="/talent/:id">
              <ProfilePage
                talentData={talentData}
                setTalentData={setTalentData}
                talentUserLinks={talentUserLinks}
                setTalentUserLinks={setTalentUserLinks}
                isPublicProfile={isPublicProfile}
              />
            </Route>
            <Route exact path="*">
              <NotFoundPage />
            </Route>
          </Switch>
        }
      </div>
    </Section>
  );
};

export default Page;
