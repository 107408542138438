import React from "react";

const ThumbsDownIcon = ({
  isSelected
}) => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_6399)">
        <path d="M25.2882 15C25.6813 15 26 14.6512 26 14.221V2.77902C26 2.34878 25.6813 2 25.2882 2H21V15H25.2882Z" fill={isSelected ? "#3049c5" : "#E8EBF8"} />
        <path d="M0 10.3296C0 11.3059 0.523552 12.1621 1.30409 12.631C1.00504 13.0637 0.829645 13.5887 0.829645 14.1537C0.829645 15.6321 2.02999 16.8349 3.50537 16.8349H9.1538C8.98068 17.621 8.74392 18.8006 8.57906 20.0354C8.15002 23.2489 8.44324 25.0311 9.50175 25.644C10.1617 26.026 10.8732 26.1035 11.5052 25.8624C11.9935 25.676 12.6529 25.2178 13.0287 24.0933L14.5133 20.1983C15.2659 18.3358 17.5706 16.3805 19 15.2993V1.40156C16.3509 0.471455 13.5865 0 10.7663 0H4.61172C3.13634 0 1.93599 1.20281 1.93599 2.6812C1.93599 3.19892 2.08321 3.68286 2.33786 4.09312C1.44588 4.52842 0.829744 5.44624 0.829744 6.50538C0.829744 7.07048 1.00514 7.5953 1.30419 8.02806C0.52355 8.49697 0 9.3532 0 10.3296Z" fill={isSelected ? "#3049c5" : "#E8EBF8"} />
      </g>
      <defs>
        <clipPath id="clip0_1_6399">
          <rect width="26" height="26" fill={isSelected ? "#3049c5" : "#E8EBF8"} transform="matrix(-1 0 0 -1 26 26)"/>
        </clipPath>
      </defs>
    </svg>
  )
};

export default ThumbsDownIcon;
