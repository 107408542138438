import React from "react";
import { CompletedCheckIcon } from "components/icons";

const Toast = (props) => {
  return (
    <div className="w-70">
      <div className="flex items-center gap-4">
        <CompletedCheckIcon />
        {props.message ? <>{props.message}</> : <></>}
      </div>
    </div>
  );
};

export default Toast;
