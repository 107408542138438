import React, { useState } from "react";
import { admin_talent_clockify as $ } from "strings";
import { global as $$ } from "strings";
import Section from "components/Section";
import { B2DB } from "components/Typography";
import UilBuilding from "@iconscout/react-unicons/icons/uil-building";
import { Button } from "components/Buttons";
import CreateTalentForm from "./CreateTalentForm";
import { LoadPlaceholderGroup } from "components/LoadPlaceholder";
import InputTalentForm from "./InputTalentForm";
import { Input, Label } from "components/Form";
import {createTalentClockify, deleteTalentClockify} from "../../../../utils/adminApi";
import AlertSuccess from "../../../../components/AlertSuccess";
import AlertError from "../../../../components/AlertError";
import {useAlert} from "react-alert";

const Clockify = ({ data, setData }) => {
  const [status, setStatus] = useState();
  const alert = useAlert();

  if (!data) {
    return (
      <div className="bg-white px-4 py-6 mb-6">
        <LoadPlaceholderGroup />
      </div>
    );
  }

  const handleDelete = async () => {
    try {
      await deleteTalentClockify(data.id);
      setData({
        ...data,
        clockify_id: null,
        clockify_created_by_button: false,
        clockify_email: null,
      });
      alert.success(<AlertSuccess message={$.delete_success_message} />);
    } catch (e) {
      alert.error(<AlertError error={e} />);
    }
  };

  return (
    <Section id="admin_talent_clockify" className="bg-white px-4 py-6 mb-6">
      <B2DB className="flex items-center mb-4">
        <UilBuilding className="text-link mr-2" size="20" />
        {$.title}
      </B2DB>
      {!data.clockify_id && !status && (
        <div className="flex items-center space-x-4">
          <Button onClick={() => setStatus("input")}>{$.input_btn}</Button>
          {data.clockify_email ?
            <Button onClick={async () => {
              try {
                const response = await createTalentClockify(data?.id, {
                  name: data.first_name + " " + data.last_name,
                  email: data.clockify_email,
                });
                const processing = !!response.message;
                alert.success(<AlertSuccess
                  message={processing ? response.message : $$.successful_request_info}
                />, processing ? {} : {timeout: 2000});
                setData({
                  ...data,
                  clockify_id: response.clockify_id,
                });
              } catch (e) {
                alert.error(<AlertError error={e} />);
              }
            }}>{$.refresh_btn}</Button>
            : <Button onClick={() => setStatus("create")}>{$.create_btn}</Button>}
        </div>
      )}
      {status === "create" && (
        <CreateTalentForm data={data} setData={setData} setStatus={setStatus} />
      )}
      {status === "input" && (
        <InputTalentForm data={data} setData={setData} setStatus={setStatus} />
      )}
      {data.clockify_id && (
        <div className="w-full md:w-1/2">
          <div className="w-full">
            <Label>{$.clockify_id_input}</Label>
            <Input disabled value={data.clockify_id} />
          </div>
          <div className="w-full mt-4">
            <Label>{$.clockify_email_input}</Label>
            <Input disabled value={data.clockify_email} />
          </div>
          <div className="w-full mt-4">
            <Button onClick={handleDelete}>{$.delete_btn}</Button>
          </div>
        </div>
      )}
    </Section>
  );
};

export default Clockify;
