import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { admin_talent_attachments as $ } from "strings";
import $$ from "strings/talent";
import CancelUploadModal  from "./CancelUploadModal";
import DeleteAttachmentModal from "./DeleteAttachmentModal";
import { B2DB } from "components/Typography";
import Section from "components/Section";
import { AnchorBtn, Button, DownloadBtn, RemoveBtn, OutlinedButton } from "components/Buttons";
import LoadPlaceholder from "components/LoadPlaceholder";
import UilFileAlt from "@iconscout/react-unicons/icons/uil-file-alt";
import useCachedFetch from "hooks/useCachedFetch";
import {
  deleteTalentFileV2,
  getTalentFilesV2,
  postTalentFile,
  getUserLinks,
  postUserLink,
  deleteUserLink,
  getTalentAttachedFileDownloadUrl,
} from "utils/adminApi";
import { toFormat } from "utils/date";
import AlertError from "components/AlertError";
import { useAlert } from "react-alert";
import { useDropzone } from "react-dropzone";
import Select from "components/Select";
import { getAdminUser } from "utils/localStorageService";
import { Input } from "components/Form";
import { withHttp } from "utils/str";

const attachmentTypeOptions = [
  { label: $.resume_option, value: "Talent Resume", isURLOption: true, isFileOption: true },
  { label: $.portfolio_option, value: "Portfolio", isURLOption: true, isFileOption: true },
  { label: $.work_sample_option, value: "Work Sample", isURLOption: true, isFileOption: true },
  { label: $.pr_article_option, value: "PR Article", isURLOption: true, isFileOption: true },
  { label: $.blog_option, value: "Blog Website", isURLOption: true, isFileOption: true },
  { label: $.website_option, value: "Website", isURLOption: false, isFileOption: true },
  { label: $.freelance_profile_option, value: "Freelance Profile", isURLOption: false, isFileOption: true },
  { label: $.linkedin_option, value: "LinkedIn", isURLOption: false, isFileOption: false },
  { label: $.scheduling_link_option, value: "Scheduling Link", isURLOption: true, isFileOption: false },
  { label: $.twitter_option, value: "Twitter", isURLOption: false, isFileOption: false },
  { label: $$.general_website_label, value: 'general_website', isURLOption: true, isFileOption: false },
  { label: $$.x_twitter_label, value: 'x_twitter', isURLOption: true, isFileOption: false },
  { label: $$.facebook_label, value: 'facebook', isURLOption: true, isFileOption: false },
  { label: $$.tiktok_label, value: 'tiktok', isURLOption: true, isFileOption: false },
  { label: $$.linkedin_label, value: 'linkedin', isURLOption: true, isFileOption: false },
  { label: $$.dribble_label, value: 'dribble', isURLOption: true, isFileOption: false },
  { label: $$.behance_label, value: 'behance', isURLOption: true, isFileOption: false },
  { label: $$.pinterest_label, value: 'pinterest', isURLOption: true, isFileOption: false },
  { label: $$.vimeo_label, value: 'vimeo', isURLOption: true, isFileOption: false },
  { label: $$.youtube_label, value: 'youtube', isURLOption: true, isFileOption: false },
  { label: $$.medium_label, value: 'medium', isURLOption: true, isFileOption: false },
  { label: $$.substack_label, value: 'substack', isURLOption: true, isFileOption: false },
  { label: $$.wordpress_label, value: 'wordpress', isURLOption: true, isFileOption: false },
];

const Attachments = ({ data, setData }) => {
  const { id } = useParams();
  const talentId = data?.id;
  const [saving, setSaving] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [fileType, setFileType] = useState();
  const [url, setUrl] = useState("");
  const alert = useAlert();
  const [form, setForm] = useState();
  const [talentLinks, setTalentLinks] = useState();
  const [modal, setModal] = useState();
  const [attachmentToDelete, setAttachmentToDelete] = useState();
  const user = getAdminUser();

  const attachments = useCachedFetch(
    "admin_talent_files",
    getTalentFilesV2,
    talentId,
    { 'talent__id': talentId }
  );

  useEffect(() => {
    if (!id) return;
    getTalentLinks(id);
  }, [id]);


  const linkedinExists = useMemo(() => {
    if (!talentLinks) return;
    return talentLinks.find(l => l.name.toLowerCase() === 'linkedin') ? true : false;
  }, [talentLinks]);

  const schedulingLinkExists = useMemo(() => {
    if (!talentLinks) return;
    return talentLinks.find(l => l.name === 'Scheduling Link') ? true : false;
  }, [talentLinks]);

  const getTalentLinks = async (id) => {
    try {
      const response = await getUserLinks(id);
      setTalentLinks(response?.data?.results);
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} onRetry={() => getTalentLinks(id)} />);
    }
  }

  const onAddSuccess = (newLink) => {
    const dataObj = {
      ...(data || []),
      attachments_count: data.attachments_count + 1,
    };
    if (newLink?.name === 'LinkedIn') {
      dataObj.linkedin_url = newLink.url;
    }
    if (newLink?.name === 'Scheduling Link') {
      dataObj.calendar_link = newLink.url;
    }
    if (newLink?.name === 'Website') {
      dataObj.personal_website = newLink.url;
    }
    setData(dataObj);
  }

  const onDeleteSuccess = (deletedLink) => {
    setModal();
    const dataObj = {
      ...(data || []),
      attachments_count: data.attachments_count - 1
    };
    if (deletedLink?.name === 'LinkedIn' && deletedLink.url) {
      dataObj.linkedin_url = null;
    }
    if (deletedLink?.name === 'Scheduling Link' && deletedLink.url) {
      dataObj.calendar_link = null;
    }
    if (deletedLink?.name === 'Website' && deletedLink.url) {
      dataObj.personal_website = null;
    }
    setData(dataObj);
  }

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: [
      ".doc",
      ".docx",
      ".pdf",
      ".xls",
      ".xlsx",
      ".ppt",
      ".pptx",
      ".txt",
      ".png",
      ".jpeg",
      ".jpg",
    ],
    multiple: false,
  });

  const onDeleteLink = async (link) => {
    let links = talentLinks;
    try {
      links = [...talentLinks].filter(
        (l) => l.id !== link.id
      );
      setTalentLinks(links);
      await deleteUserLink(link.id);
      onDeleteSuccess(link);
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} onRetry={onDeleteLink} />);
      setTalentLinks(links);
    }
  }

  const onDeleteFile = async (file) => {
    const call = async () => {
      try {
        const res = [...attachments.data.results].filter((a) => a.id !== file.id);
        attachments.setData((d) => ({ ...d, results: res }))
        await deleteTalentFileV2(file.id);
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
    onDeleteSuccess();
  };

  const onSaveFile = async () => {
    setSaving(true);

    const reader = new FileReader();

    reader.onabort = () => {
      alert.error("file reading was aborted");
      console.error("file reading was aborted");
    };
    reader.onerror = () => {
      alert.error("file reading has failed");
      console.error("file reading has failed");
    };
    reader.onload = () => {
      const binaryStr = reader.result;
      upload(
        selectedFile,
        binaryStr,
        async (response) => {
          attachments.setData((d) => ({
            ...d,
            results: [
              ...d.results,
              {
                ...response,
                created_by: user.first_name + " " + user.last_name,
              },
            ],
          }));
          setSelectedFile(null);
          setFileType(null);
          setForm(null);
          setSaving(false);
        },
        () => {}
      );
    };

    reader.readAsDataURL(selectedFile);
  };

  const onSaveUrl = async () => {
    let links = talentLinks;
    try {
      const newLink = await postUserLink({
        name: fileType.value,
        url,
        user: id
      });
      setUrl("");
      setFileType(null);
      setForm(null);
      setSaving(false);
      setTalentLinks([...links, newLink]);
      onAddSuccess(newLink);
    } catch (e) {
      setSaving(false);
      console.error(e);
      alert.error(<AlertError error={e} />);
    }
  };

  const upload = async (file, binaryStr, onSuccess, onFailed) => {
    const idx = file.path.lastIndexOf(".");
    let ftype = "";
    if (idx > -1) {
      ftype = file.path.slice(idx + 1).toUpperCase();
    }
    try {
      const response = await postTalentFile({
        talent_id: id,
        filename: file.path,
        file_type: ftype,
        category: fileType.value,
        file: binaryStr.replace(/data:.*base64,/g, ""),
      });
      onSuccess(response);
      onAddSuccess();
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} />);
    }
  };

  const onCancel = () => {
    setForm();
    setUrl();
    setFileType();
    setSelectedFile();
    setModal();
    setAttachmentToDelete();
  }

  if (!data || !attachments) {
    return (
      <div className="bg-white flex-1 flex flex-col px-2 py-4">
        <LoadPlaceholder className="w-60 h-4 mt-10 mb-10" />
        <LoadPlaceholder className="w-60 h-4 mb-2" />
        <LoadPlaceholder className="w-40 h-4 mb-2" />
        <LoadPlaceholder className="w-32 h-4 mb-2" />
      </div>
    );
  }

  return (
    <Section id="admin_talent_attachments" className="flex mt-1 h-full flex-col w-3/4">
      {!form && (
        <div className="flex mb-1 h-12 items-center">
          <Button noMinW onClick={() => setForm("file")}>
            {$.attach_file_btn}
          </Button>
          <Button noMinW className="ml-1" onClick={() => setForm("url")}>
            {$.attach_url_btn}
          </Button>
        </div>
      )}
      {form === "file" && (
        <div className="flex items-center h-12 mb-1">
          <Button
            disabled={!selectedFile || !fileType || saving}
            noMinW
            onClick={onSaveFile}
          >
            {saving ? $.saving_btn : $.save_btn}
          </Button>
          <OutlinedButton noMinW className="ml-1 h-10" onClick={() => setModal('cancelUpload')}>
            {$.cancel_btn}
          </OutlinedButton>
          <div
            {...getRootProps()}
            className="bg-white text-sm rounded h-10 flex-1 mx-2 text-kasmir flex items-center px-2 border border-geyser"
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <div>{$.drop_files_message}</div>
            ) : selectedFile ? (
              <div className="text-midnight">{selectedFile.name}</div>
            ) : (
              <div>{$.drag_browse_message}</div>
            )}
          </div>
          <Select
            placeholder={$.file_placeholder}
            options={
              linkedinExists ?
                attachmentTypeOptions.filter(o => o.isFileOption === true && o.value.toLowerCase() !== "linkedin") :
                attachmentTypeOptions.filter(o => o.isFileOption === true)
            }
            onChange={(v) => setFileType(v)}
            value={fileType}
          />
        </div>
      )}
      {form === "url" && (
        <div className="flex items-center h-12 mb-1">
          <Button
            disabled={!url || !fileType || saving}
            noMinW
            onClick={onSaveUrl}
          >
            {saving ? $.saving_btn : $.save_btn}
          </Button>
          <OutlinedButton noMinW className="ml-1 h-10" onClick={() => setModal('cancelUpload')}>
            {$.cancel_btn}
          </OutlinedButton>
          <div className="text-sm rounded h-10 flex-1 mx-2">
            <Input value={url} onChange={(v) => setUrl(v.target.value)} />
          </div>
          <Select
            placeholder={$.file_placeholder}
            options={
              (linkedinExists && schedulingLinkExists) ? 
                attachmentTypeOptions.filter(o => o.isURLOption === true && o.value.toLowerCase() !== "linkedin" && o.value !== 'Scheduling Link') :
                linkedinExists ?
                  attachmentTypeOptions.filter(o => o.isURLOption === true && o.value.toLowerCase() !== "linkedin") :
                schedulingLinkExists ? 
                  attachmentTypeOptions.filter(o => o.isURLOption === true && o.value !== "Scheduling Link") :
                attachmentTypeOptions.filter(o => o.isURLOption === true)
            }
            onChange={(v) => setFileType(v)}
            value={fileType}
          />
        </div>
      )}
      <div className="bg-white flex-1 flex flex-col">
        <div className="flex items-center px-2 py-4">
          <B2DB className="flex flex-1 items-center">
            <UilFileAlt className="mr-2 text-link" size="18" />{$.title}
          </B2DB>
        </div>
        <div className="px-2 py-2">
          <div className="flex items-center text-sm text-kasmir font-bold">
            <div className="flex items-center flex-1">
              <div className="w-1/5">{$.name_label}</div>
              <div className="w-1/5">{$.attached_by_label}</div>
              <div className="w-1/5">{$.attached_on_label}</div>
              <div className="w-2/5">{$.info_label}</div>
            </div>
            <div className="w-1/12" />
          </div>
          {attachments?.data?.results && Array.isArray(attachments.data.results) &&
            attachments.data.results.map((f) => (
              <div
                key={f.id}
                className="flex items-center text-sm text-midnight"
              >
                <div className="flex items-center flex-1">
                  <div className="w-1/5 font-bold">
                    {attachmentTypeOptions.find(option => option.value.toLowerCase() === f.category?.toLowerCase())?.label || f.category || 'Other'}
                  </div>
                  <div className="w-1/5">{f.created_by || $.unknown_value}</div>
                  <div className="w-1/5">
                    {f.created_at ? toFormat(new Date(f.created_at), "MM/dd/yyyy h:mm a") : $.unknown_value}
                  </div>
                  <div className="w-2/5 break-words">
                    {(f.file_type === "TalentLink" || f.file_type === 'URL') ? f.url : f.filename}
                    {!!f.size && <>, {f.size}</>}
                  </div>
                </div>
                <div className="w-1/12 flex items-center justify-end">
                  {(f.file_type === "TalentLink" || f.file_type === 'URL') ? (
                    <AnchorBtn
                      colored
                      href={withHttp(f.url)}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  ) : (
                    <DownloadBtn
                      colored
                      download={f.filename}
                      href={withHttp(getTalentAttachedFileDownloadUrl(f.id))}
                    />
                  )}
                   <RemoveBtn colored onClick={() => {
                    setAttachmentToDelete(f);
                    setModal('deleteAttachment')
                  }}/>
                </div>
              </div>
            ))}
          {talentLinks && Array.isArray(talentLinks) &&
            talentLinks.map((l) => (
              <div
                key={l.id}
                className="flex items-center text-sm text-midnight"
              >
                <div className="flex items-center flex-1">
                  <div className="w-1/5 font-bold">
                    {attachmentTypeOptions.find(option => option.value.toLowerCase() === l.name?.toLowerCase())?.label || l.name || 'Other'}
                  </div>
                  <div className="w-1/5">{l.updated_by || $.unknown_value}</div>
                  <div className="w-1/5">
                    {l.updated_at ? toFormat(new Date(l.updated_at), "MM/dd/yyyy h:mm a") : $.unknown_value}
                  </div>
                  <div className="w-2/5 break-words">
                    {l.url}
                  </div>
                </div>
                <div className="w-1/12 flex items-center justify-end">
                  {l.url &&
                    <AnchorBtn
                      colored
                      href={withHttp(l.url)}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  }
                  <RemoveBtn colored onClick={() => {
                    setAttachmentToDelete(l);
                    setModal('deleteAttachment')
                  }}/>
                </div>
              </div>
            ))}
        </div>
      </div>
      {modal === "cancelUpload" &&
        <CancelUploadModal
          onClose={setModal}
          onCancel={onCancel}
        />
      }
      {modal === "deleteAttachment" && (
        <DeleteAttachmentModal
          onClose={setModal}
          onDelete={() => attachmentToDelete.file_type ? onDeleteFile(attachmentToDelete) : onDeleteLink(attachmentToDelete)}
        />
      )}
    </Section>
  )
}

export default Attachments;
