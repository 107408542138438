import React from "react";
import notes from "assets/notes.svg";

const EmptyNotes = () => {
  return (
    <div className="flex h-full flex-col items-center justify-center gap-2">
      <img src={notes} alt="no_notes" className="h-32 w-32" />
      <h1 className="text-base font-semibold text-midnight">No notes yet</h1>
      <p className="w-70 text-center text-sm text-kasmir">
        There are no notes for this talent yet. Use the input field below to add
        your first note and click 'Post Note' to save.
      </p>
    </div>
  );
};

export default EmptyNotes;
