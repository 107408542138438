import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import { admin_talent_overview as $ } from "strings";
import { Prompt } from "react-router-dom";
import RichTextEditor from "components/RichTextEditorNew";
import { isBodyEmpty } from "components/RichTextEditor";
import { getAdminUser } from "utils/localStorageService";
import AlertError from "components/AlertError";
import { useAlert } from "react-alert";
import { serialize } from "utils/htmlSerializer";
import { postTalentNote } from "utils/adminApi";
import { usePageCache } from "contexts/pageCacheContext";
import useConfirmClose from "hooks/useConfirmClose";
import { Button } from "pages/talent/components";
import DropdownButtonMenu from "components/DropdownMenuButton";

const getPhoneTemplate = () => {
  const template = [
    { type: "heading-two", children: [{ text: "Talent Team Phone Screen" }] }
  ];
  $.phone_template.forEach((i) => {
    template.push({
      type: "bulleted-list",
      children: [
        { text: i, bold: true },
        { text: ":", bold: false }
      ]
    });
  });

  return template;
};
const getTechScreenTemplate = () => {
  const template = [
    { type: "heading-two", children: [{ text: "Tech Screen Notes" }] }
  ];
  $.tech_screen_template.forEach((i) => {
    template.push({
      type: "bulleted-list",
      children: [
        { text: i, bold: true },
        { text: ":", bold: false }
      ]
    });
  });

  return template;
};

const NoteForm = ({ data, optimisticallyAdd, reload }) => {
  const user = getAdminUser();
  const { id } = useParams();
  const alert = useAlert();
  const [saving, setSaving] = useState();
  const { set, cache } = usePageCache();
  const [values, setValues] = useState(
    cache["admin_project_overview_note_draft__" + id] || {
      body: [{ type: "paragraph", children: [{ text: "" }] }]
    }
  );
  const [isFocused, setIsFocused] = useState(false);

  const updateVal = (k, v) => {
    setValues((vs) => ({ ...vs, [k]: v }));
  };

  const isEmpty = isBodyEmpty(values.body);
  const { canClose, confirmMsg } = useConfirmClose(!isEmpty);

  useEffect(() => {
    if (id) {
      set("admin_project_overview_note_draft__" + id, values);
    }
  }, [values, id]);

  const onSubmit = async () => {
    setSaving(true);

    let body = isEmpty ? [] : values.body;

    const toSend = {
      body: serialize({ children: body }),
      talent: id,
      created_by_id: user.id,
      created_by: user.first_name + " " + user.last_name
    };

    const call = async () => {
      try {
        const n = await postTalentNote(toSend);
        setValues({ body: [{ type: "paragraph", children: [{ text: "" }] }] });
        optimisticallyAdd({
          note_body: n.body,
          created_by_name: n.created_by,
          id: n.id,
          created_timestamp: n.created_on,
          updated_at: n.updated_at,
          updated_by_id: n.created_by_id,
          talent_ref: n.talent
        });

        set("admin_talent_overview_note_draft__" + id, null);
        setIsFocused(false);
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} />);
      }
      setSaving(false);
      await reload();
    };

    await call();
  };

  const onChangeTemplate = (template) => {
    if (template === "phone") {
      updateVal(
        "body",
        isEmpty ? getPhoneTemplate() : [...values.body, ...getPhoneTemplate()]
      );
    } else if (template === "tech") {
      updateVal(
        "body",
        isEmpty
          ? getTechScreenTemplate()
          : [...values.body, ...getTechScreenTemplate()]
      );
    }
  };

  const onDiscard = () => {
    if (canClose()) {
      updateVal("body", [{ type: "paragraph", children: [{ text: "" }] }]);
      setValues({
        body: [{ type: "paragraph", children: [{ text: "" }] }]
      });
      set("admin_talent_overview_note_draft__" + id, null);
    }
  };

  return (
    <div className={clsx("flex w-full flex-col")}>
      <Prompt when={!isEmpty} message={confirmMsg} />
      <RichTextEditor
        placeholder="Start typing to add a talent note..."
        value={values.body}
        onChange={(v) => updateVal("body", v)}
        isStageChangeNote={false}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className={"max-h-64"}
        bottomChildren={
          <div className="flex w-full flex-row items-center gap-2.5 px-2 pb-1">
            <DropdownButtonMenu
              label="Autofill Template"
              options={[
                {
                  label: "Recruiter Phone Screen",
                  onClick: () => onChangeTemplate("phone")
                },
                {
                  label: "Tech Screen",
                  onClick: () => onChangeTemplate("tech")
                }
              ]}
            />
            <Button onClick={onSubmit} disabled={saving || isEmpty}>
              Post note
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default NoteForm;
