import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { admin_talent_overview as $ } from "strings";
import Section from "components/Section";
import NoteForm from "./NoteForm";
import { getTalentNotesV2 } from "utils/adminApi";
import Note from "./Note";
import { getAdminUserId } from "utils/localStorageService";
import DebouncedInput from "components/DebouncedInput";
import LoadPlaceholder from "components/LoadPlaceholder";
import { Button } from "pages/talent/components";
import EmptyNotes from "./EmptyNotes";
import PinnedIcon from "assets/icons/Pinned";
import SearchIcon from "assets/icons/Search";
import useCachedFetch from "hooks/useCachedFetch";
import { FilterMultiSelectDropdown } from "components/DropdownMenuButton";

const NOTE_TYPE_FILTERS = [
  { label: "Show All", value: "all", filter: () => true },
  {
    label: "Recruiter Screen",
    value: "recruiter",
    filter: (type, bodyLower) => {
      if (bodyLower === "status_history") return false;
      return bodyLower.includes(
        "What types of projects would they be interested in from a consulting perspective?".toLowerCase()
      );
    }
  },
  {
    label: "Tech Screen",
    value: "tech_screen",
    filter: (type, bodyLower) => {
      if (type === "status_history") return false;
      return (
        bodyLower.includes("tech screen") || bodyLower.includes("techscreen")
      );
    }
  },
  {
    label: "Probing Questions",
    value: "probing_questions",
    filter: (_, bodyLower) => {
      return bodyLower.includes("probing question");
    }
  },
  {
    label: "Other",
    value: "other",
    filter: (type, bodyLower) => {
      return !NOTE_TYPE_FILTERS.filter(
        (f) => f.value !== "all" && f.value !== "other"
      ).some((f) => f.filter(type, bodyLower));
    }
  }
];

const Notes = ({ data }) => {
  const [notes, setNotes] = useState();
  const [noteTypeFilter, setNoteTypeFilter] = useState(
    NOTE_TYPE_FILTERS.map((f) => f.value)
  );
  const [search, setSearch] = useState("");
  const { id } = useParams();
  const userId = getAdminUserId();
  const [showPinnedOnly, setShowPinnedOnly] = useState(false);

  const apiCall = useCallback(() => {
    if (!id) return;

    return getTalentNotesV2({
      talent_id: id,
      page_size: 9999
    });
  }, [id]);

  const { data: notesResults, reload } = useCachedFetch(
    "admin_talent_notes",
    apiCall,
    id
  );

  useEffect(() => {
    if (notesResults?.results) setNotes(notesResults.results);
  }, [notesResults]);

  const displayedNotes = useMemo(() => {
    return (
      notes
        ?.filter((n) => {
          if (search) {
            // Strip HTML tags before searching
            const plainText = n.note_body.replace(/<[^>]*>/g, "");
            if (!plainText.toLowerCase().includes(search.toLowerCase())) {
              return false;
            }
          }
          if (showPinnedOnly) {
            return n.pinned;
          }
          return true;
        })
        .filter((n) => {
          const body = n.note_body.toLowerCase();

          return noteTypeFilter.some((filter) => {
            return (
              NOTE_TYPE_FILTERS.find((f) => f.value === filter)?.filter(
                n.type,
                body
              ) ?? true
            );
          });
        })
        .sort((a, b) => {
          return b.created_timestamp - a.created_timestamp;
        }) ?? []
    );
  }, [notes, search, showPinnedOnly, noteTypeFilter]);

  // These helper functions let us optimistically set the ui
  const optimisticallyAdd = (note) => {
    setNotes([note, ...notes]);

    // Scroll the new note into view
    let el = document.getElementById(note.id);
    el?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "nearest"
    });
  };

  const optimisitcallyRemove = (id) => {
    setNotes(notes.filter((n) => n.id !== id));
  };

  const optimisticallyUpdate = (id, note) => {
    setNotes(notes.map((n) => (n?.id === id ? { ...n, ...note } : n)));
  };

  const pinnedCount = displayedNotes?.filter((n) => n.pinned).length ?? 0;

  return (
    <Section id="admin_talent_notes" className="mt-px flex h-full">
      <div className="h-full w-full bg-white p-2.5">
        <div className="flex h-full flex-1 flex-col rounded-md bg-lightest-grey">
          {/** Header content */}
          <div className="bottom-1 flex w-full flex-col gap-2.5 border-b-2 border-geyser px-2.5 py-2 pt-4">
            <div className="flex w-full flex-row justify-between">
              <div className="text-lg font-bold text-midnight">Notes</div>
              <Button
                cancel
                noMinW
                onClick={() => setShowPinnedOnly(!showPinnedOnly)}
              >
                {showPinnedOnly ? (
                  "Show All"
                ) : (
                  <div className="flex flex-row items-center gap-2">
                    <PinnedIcon fill="#3049C5" size="16" />
                    See pinned notes {pinnedCount > 0 && ` (${pinnedCount})`}
                  </div>
                )}
              </Button>
            </div>
            <div className="flex w-full flex-row gap-2.5">
              <div className="w-full pb-1">
                <DebouncedInput
                  className="placeholder-text-kasmir focus:outline-none flex h-8 w-full appearance-none items-center rounded-md border-geyser bg-white px-3 text-sm text-midnight focus:border-2 focus:border-link"
                  placeholder={$.notes_search_placeholder}
                  value={search}
                  onChange={(s) => setSearch(s)}
                  leftIcon={<SearchIcon size="12" fill="#C3C3C3" />}
                />
              </div>
              <div className="w-60">
                <FilterMultiSelectDropdown
                  options={NOTE_TYPE_FILTERS}
                  values={noteTypeFilter}
                  setValues={setNoteTypeFilter}
                />
              </div>
            </div>
          </div>

          <div
            className="min-h-0 flex-1 gap-2.5 overflow-y-auto px-2.5 py-2"
            id="scrollableDiv"
            style={{
              overflow: "auto",
              display: "flex",
              flexDirection: "column-reverse"
            }}
          >
            {!notes ? (
              <div className="flex-1 px-6 pt-6">
                <LoadPlaceholder className="my-4 h-4 w-60" />
                <LoadPlaceholder className="mb-4 h-4 w-60" />
                <LoadPlaceholder className="mb-2 h-4 w-40" />
              </div>
            ) : (
              displayedNotes?.map((n, i) => (
                <Note
                  key={i}
                  note={n}
                  optimisticallyUpdate={optimisticallyUpdate}
                  reload={reload}
                  editable={n.updated_by_id === userId}
                  optimisitcallyRemove={optimisitcallyRemove}
                  highlightedKeyword={search}
                />
              ))
            )}
            {notes && notes.length === 0 && <EmptyNotes />}
          </div>
          <div className="p-2.5">
            {data && notes && (
              <NoteForm
                data={data}
                optimisticallyAdd={optimisticallyAdd}
                reload={reload}
              />
            )}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Notes;
