import React, { useRef, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import UilTimes from "@iconscout/react-unicons/icons/uil-times-circle";

const INPUT_DEBOUNCE_TIME = 250;

/**
 * Renders an input with a debounced onChange event. Useful for search fields, so it waits until finish writing to trigger an event, like an API call.
 * It uses lodash.debounce (https://www.npmjs.com/package/lodash.debounce).
 * @param {string} value - Input value.
 * @param {boolean} resetValue - Flag to indicate whether to reset the input content.
 * @param {callback} onChange - Callback to catch new selected values, this is debounced.
 * @param {React.Component} leftIcon - Optional icon component to show on the left side of the input.
 * @param {object} props - Extra props to pass to the input, styling classes for example.
 */
const DebouncedInput = React.forwardRef(
  ({ value, resetValue, onChange, leftIcon, ...props }, ref) => {
    const [inputValue, setValue] = useState(value || "");
    const debouncedOnChange = useRef();

    useEffect(() => {
      debouncedOnChange.current = debounce(
        (newValue) => onChange(newValue),
        INPUT_DEBOUNCE_TIME
      );
    }, [onChange]);

    useEffect(() => {
      if (resetValue) {
        setValue("");
      }
    }, [resetValue]);

    const handleChange = (e) => {
      const text = e.target.value;
      setValue(text);
      debouncedOnChange.current(text);
    };

    return (
      <div className="relative">
        {leftIcon && (
          <div className="absolute bottom-0 left-2.5 top-0 flex items-center">
            {leftIcon}
          </div>
        )}
        <input
          onWheel={(e) => e.target.blur()}
          ref={ref}
          {...props}
          onChange={handleChange}
          value={inputValue}
          className={`${props.className || ""} ${leftIcon ? "pl-7" : ""}`}
        />
        {!!inputValue && (
          <button
            className="focus:outline-none absolute bottom-0 right-0 top-0 mr-2 flex items-center justify-center text-kasmir"
            onClick={() => handleChange({ target: { value: "" } })}
          >
            <UilTimes size="18" />
          </button>
        )}
      </div>
    );
  }
);

export default React.memo(DebouncedInput);
