import React from "react";
import $ from "strings/talent";
import Behance from "components/icons/links/Behance.svg";
import Dribble from "components/icons/links/Dribble.svg";
import Facebook from "components/icons/links/Facebook.svg";
import GeneralWebsite from "components/icons/links/GeneralWebsite.svg";
import Instagram from "components/icons/links/Instagram.svg";
import LinkedIn from "components/icons/links/LinkedIn.svg";
import Medium from "components/icons/links/Medium.svg";
import Pinterest from "components/icons/links/Pinterest.svg";
import Substack from "components/icons/links/Substack.svg";
import TikTok from "components/icons/links/TikTok.svg";
import Vimeo from "components/icons/links/Vimeo.svg";
import WordPress from "components/icons/links/WordPress.svg";
import XTwitter from "components/icons/links/XTwitter.svg";
import Youtube from "components/icons/links/Youtube.svg";
import { withHttp } from "utils/str";

const LINK_TYPES = [
  {label: $.general_website_label, value: 'general_website', icon: GeneralWebsite},
  {label: $.x_twitter_label, value: 'x_twitter', icon: XTwitter},
  {label: $.facebook_label, value: 'facebook', icon: Facebook},
  {label: $.tiktok_label, value: 'tiktok', icon: TikTok},
  {label: $.linkedin_label, value: 'linkedin', icon: LinkedIn},
  {label: $.dribble_label, value: 'dribble', icon: Dribble},
  {label: $.behance_label, value: 'behance', icon: Behance},
  {label: $.pinterest_label, value: 'pinterest', icon: Pinterest},
  {label: $.vimeo_label, value: 'vimeo', icon: Vimeo},
  {label: $.youtube_label, value: 'youtube', icon: Youtube},
  {label: $.medium_label, value: 'medium', icon: Medium},
  {label: $.substack_label, value: 'substack', icon: Substack},
  {label: $.wordpress_label, value: 'wordpress', icon: WordPress},
]

const LinkType = ({ link }) => {
  const { name, url } = link;

  return (
    <a
      className="bg-lightest-grey p-1 rounded-sm cursor-pointer"
      href={withHttp(url)}
      target="_blank"
      title={`${LINK_TYPES.find(v => v.value === name.toLowerCase())?.label || 'General Website'} | ${url}`}
      rel="noopener noreferrer"
    >
      <img
        src={LINK_TYPES.find(v => v.value === name.toLowerCase())?.icon || GeneralWebsite}
        alt={`${name || 'Link'} icon`}
      />
    </a>
  );
}

export default LinkType;
