import { groupProjectsByStatus } from "../utils";
import { statusOptions } from "../constants";

export const groupAndSortProjects = (projects) => {
  if (!projects) {
    return {};
  }
  const grouped = groupProjectsByStatus(projects);

  // Sort candidates by the reverse status order they are in the statusOptions array
  grouped.candidate = grouped.candidate.sort((a, b) => {
    return (
      statusOptions.findIndex((status) => status.value === b.status) -
      statusOptions.findIndex((status) => status.value === a.status)
    );
  });

  // For all other statuses, sort by project_last_updated
  Object.keys(grouped).forEach((status) => {
    if (status !== "candidate") {
      grouped[status] = grouped[status].sort((a, b) => {
        return (
          new Date(b.project_last_updated) - new Date(a.project_last_updated)
        );
      });
    }
  });

  return grouped;
};
