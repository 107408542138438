const setToken = ({ access, refresh }) => {
  if (access) {
    localStorage.setItem("access_token", access);
  }
  if (refresh) {
    localStorage.setItem("refresh_token", refresh);
  }
};

const getAccessToken = () => localStorage.getItem("access_token");

const getRefreshToken = () => localStorage.getItem("refresh_token");

const getUserId = () => localStorage.getItem("user_id");

const setUserId = (userId) => localStorage.setItem("user_id", userId);

const getUrlSuffix = () => localStorage.getItem("url_suffix");

const setUrlSuffix = (urlSuffix) => localStorage.setItem("url_suffix", urlSuffix);

const getTalentOnboarded = () => localStorage.getItem("talent_onboarded") === "true";

const setTalentOnboarded = (onboarded) => localStorage.setItem("talent_onboarded", onboarded);

const getUser = () => JSON.parse(localStorage.getItem("user"));

const setUser = (user) => localStorage.setItem("user", JSON.stringify(user));

const setAdminToken = ({ access, refresh }) => {
  if (access) {
    localStorage.setItem("admin_access_token", access);
  }
  if (refresh) {
    localStorage.setItem("admin_refresh_token", refresh);
  }
};

const getAdminAccessToken = () => localStorage.getItem("admin_access_token");

const getAdminRefreshToken = () => localStorage.getItem("admin_refresh_token");

const getAdminUserId = () => localStorage.getItem("admin_user_id");

const setAdminUserId = (userId) =>
  localStorage.setItem("admin_user_id", userId);

const getAdminUser = () => JSON.parse(localStorage.getItem("admin_user"));

const setAdminUser = (user) =>
  localStorage.setItem("admin_user", JSON.stringify(user));

const getAdminFlags = () =>
  JSON.parse(localStorage.getItem("admin_flags")) || {};

const setAdminFlags = (flags) =>
  localStorage.setItem("admin_flags", JSON.stringify(flags));


const saveLoginToken = (response) => {
  if (response.user) {
    setAdminToken(response);
    setAdminUser(response.user);
    setAdminUserId(response.user.id);
  }
  if (response.talent) {
    setToken(response);
    setUserId(response.talent.user.id);
    setTalentOnboarded(!!response.talent.skills_survey_submitted_at);
    setUrlSuffix(response.talent.url_suffix);
  }
};

const authKeys = [
  "access_token",
  "refresh_token",
  "user_id",
  "user",
  "url_suffix",
  "talent_onboarded",
  "admin_access_token",
  "admin_refresh_token",
  "admin_user_id",
  "admin_user",
];

const impersonatePrefix = "impersonator__";

const clearLoginToken = () => {
  for (const key of authKeys) {
    localStorage.removeItem(key);
    localStorage.removeItem(`${impersonatePrefix}${key}`);
  }
};

const impersonateStart = (response) => {
  for (const key of authKeys) {
    const value = localStorage.getItem(key);
    if (value) {
      localStorage.setItem(`${impersonatePrefix}${key}`, value);
      localStorage.removeItem(key);
    }
  }
  saveLoginToken(response);
};

const impersonateStop = () => {
  for (const key of authKeys) {
    localStorage.removeItem(key);
    const impersonateKey = `${impersonatePrefix}${key}`;
    const value = localStorage.getItem(impersonateKey);
    if (value) {
      localStorage.setItem(key, value);
      localStorage.removeItem(impersonateKey);
    }
  }
};

const getImpersonatorAdminToken = () => localStorage.getItem(`${impersonatePrefix}admin_access_token`);


export {
  setToken,
  getAccessToken,
  getRefreshToken,
  getUserId,
  setUserId,
  getUrlSuffix,
  setUrlSuffix,
  getUser,
  setUser,
  setAdminToken,
  getAdminAccessToken,
  getAdminRefreshToken,
  getAdminUserId,
  setAdminUserId,
  getAdminUser,
  setAdminUser,
  getAdminFlags,
  setAdminFlags,
  getTalentOnboarded,
  setTalentOnboarded,
  saveLoginToken,
  clearLoginToken,
  impersonateStart,
  impersonateStop,
  getImpersonatorAdminToken,
};
