import React from "react";

const PinnedIcon = ({ size = 22, fill = "#16325C" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1686_114461)">
        <path
          d="M16.9345 5.79091L12.7664 1.62559C12.5734 1.43628 12.3387 1.29488 12.0811 1.21276C11.8235 1.13064 11.5502 1.1101 11.2833 1.15277C11.0163 1.19545 10.7631 1.30015 10.5439 1.45848C10.3247 1.6168 10.1458 1.82433 10.0214 2.06438C9.87728 2.3465 9.81512 2.66338 9.84196 2.97905C9.86881 3.29472 9.98359 3.59654 10.1733 3.85028L7.35235 6.67122C6.74646 6.17028 5.976 5.91244 5.19064 5.94778C4.40527 5.98313 3.66109 6.30915 3.10266 6.86249L2.55703 7.40809C2.38179 7.58455 2.28345 7.82315 2.28345 8.07184C2.28345 8.32053 2.38179 8.55913 2.55703 8.73559L9.87797 16.0565C10.0539 16.2317 10.292 16.3301 10.5403 16.3301C10.7886 16.3301 11.0268 16.2317 11.2027 16.0565L11.7511 15.5081C12.3071 14.9517 12.635 14.2075 12.6704 13.4218C12.7058 12.636 12.4461 11.8653 11.9423 11.2612L14.7633 8.43747C16.516 9.63764 18.4352 7.3352 16.9345 5.79091Z"
          fill={fill}
        />
        <path
          d="M4.8296 11.804L1.87647 15.7415C1.77767 15.8791 1.7311 16.0474 1.74512 16.2163C1.75914 16.3852 1.83283 16.5435 1.95298 16.663C2.07313 16.7825 2.23189 16.8552 2.40083 16.8683C2.56977 16.8814 2.73784 16.8339 2.87492 16.7343L6.80679 13.7811L4.8296 11.804Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1686_114461">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.602295)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PinnedIcon;
