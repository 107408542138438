import $ from "../../../strings/talent.json";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { AddBtn, EditBtn, HideBtn } from "components/Buttons";
import { CompanyExperienceListItem } from "components/ExperiencesListItems";
import ImportRSUProjectForm from "./ImportRSUProjectForm";
import {
  getTalentDataFromUrlSuffix,
  // getTalentUserLinksPreview,
  patchTalentTalentCreatedProject
} from "../../../utils/api";
import AddTalentProjectForm from "./AddTalentProjectForm";
import { Button, PointedTooltip } from "../components";
import placeholderImg from "../assets/projects_placeholder.png";
import Pill from "../components/Pill";

const DrawerElement = ({
  className,
  onClick,
  children,
  disabled = false,
  ...props
}) => (
  <div
    className={clsx(
      "cursor-pointer select-none rounded-sm p-1 text-sm hover:bg-lightest-grey",
      disabled ? "cursor-not-allowed text-caded" : "",
      className
    )}
    onClick={disabled ? (e) => e.stopPropagation() : onClick}
    {...props}
  >
    {children}
  </div>
);

const HIDE_PROJECTS_ABOVE_NUM = 3;

const Projects = ({
  talentData,
  setTalentData,
  isPublicProfile,
  setAddNewProject
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [addRSUOpen, setAddRSUOpen] = useState(false);
  const [addNonRSUOpen, setAddNonRSUOpen] = useState(false);
  const [talentProjects, setTalentProjects] = useState({});
  const [modalWasOpen, setModalWasOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState({});
  const [showHideProjectTooltip, setShowHideProjectTooltip] = useState("");
  const [RSUProjects, setRSUProjects] = useState({});
  const [userLinksWithPreview, setUserLinksWithPreview] = useState([]);
  const [hideProjects, setHideProjects] = useState(true);

  window.document.onclick = () => {
    setDrawerOpen(false);
  };

  window.document.onkeydown = (e) => {
    if (e.key === "Escape") {
      setDrawerOpen(false);
    }
  };

  const clearHideProjectTooltip = () => {
    // bug fix - hard to reproduce but this should make the tooltip disappear
    if (showHideProjectTooltip) {
      setShowHideProjectTooltip("");
    }
  };

  window.document.onscroll = clearHideProjectTooltip;
  window.document.onmousemove = clearHideProjectTooltip;

  // links display removed tmp
  // const refreshUserLinksWithPreview = () => {
  //   getTalentUserLinksPreview().then(res => {
  //     const links = {};
  //     res.results.forEach(link => {
  //       links[link.id] = link.preview_url || link.url;
  //     });
  //     setUserLinksWithPreview(links);
  //   });
  // };
  //
  // useEffect(() => {
  //   refreshUserLinksWithPreview();
  // }, [talentData]);

  useEffect(() => {
    if (setAddNewProject) {
      setAddNewProject(() => () => setAddNonRSUOpen(true));
    }
  }, [setAddNewProject]);

  useEffect(() => {
    // populate talentProjects
    const existingProjectIds = {};
    if (talentData?.talent_created_projects) {
      const projects = {};
      let nProjects = 0;
      for (const project of talentData.talent_created_projects) {
        if (project.project_id) {
          existingProjectIds[project.project_id] = true;
        }
        if (isPublicProfile && project.is_hidden) {
          // skip hidden projects in public profile
          // only relevant when talent is viewing their own profile in public mode
          // because when not authenticated, those projects are not fetched
          continue;
        }
        if (hideProjects && nProjects >= HIDE_PROJECTS_ABOVE_NUM) {
          continue;
        }
        const key = project.company?.name || "Company unknown";
        if (!projects[key]) {
          projects[key] = [];
        }
        projects[key].push(project);
        nProjects++;
      }
      setTalentProjects(projects);
    }

    // populate RSUProjects
    if (talentData?.rsu_projects) {
      const projectObject = {};
      for (const project of talentData?.rsu_projects) {
        if (!existingProjectIds[project.id]) {
          projectObject[project.id] = project;
        }
      }
      setRSUProjects(projectObject);
    }
  }, [talentData, isPublicProfile, hideProjects]);

  useEffect(() => {
    // setModalWasOpen to true if either modal is open so that we know to refresh the talent data
    if (addRSUOpen || addNonRSUOpen) {
      setModalWasOpen(true);
    }
  }, [addRSUOpen, addNonRSUOpen]);

  useEffect(() => {
    // refresh talent data if modal was open and is now closed because the user might have added/changed a project
    if (!addRSUOpen && !addNonRSUOpen && modalWasOpen) {
      getTalentDataFromUrlSuffix(talentData.url_suffix).then(setTalentData);
    }
  }, [addRSUOpen, addNonRSUOpen, modalWasOpen]);

  // Edit project buttons component definition
  const EditProjectButtons = ({ project }) => {
    return (
      <div className="ml-auto flex gap-x-2.5">
        <EditBtn
          onClick={() => {
            setAddNonRSUOpen(true);
            setSelectedProject(project);
          }}
        />
        <PointedTooltip
          onClick={(e) => e.stopPropagation()}
          hidden={showHideProjectTooltip !== project.id || project.is_hidden}
          msg={$.hide_project_tooltip_msg}
        >
          <HideBtn
            onMouseEnter={(e) => {
              e.stopPropagation();
              setShowHideProjectTooltip(project.id);
            }}
            onMouseMove={(e) => {
              e.stopPropagation();
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setShowHideProjectTooltip("");
            }}
            show={Boolean(project.is_hidden)}
            onClick={async () => {
              await patchTalentTalentCreatedProject(project.id, {
                is_hidden: !project.is_hidden
              });
              const newTalentData = await getTalentDataFromUrlSuffix(
                talentData.url_suffix
              );
              setTalentData(newTalentData);
            }}
          />
        </PointedTooltip>
      </div>
    );
  };

  return (
    <>
      {isPublicProfile && !Object.entries(talentProjects)?.length ? (
        <></>
      ) : (
        <div className="rounded-extra-large bg-white p-4">
          <div className="relative mb-4 flex h-7.5 items-center gap-x-2">
            <div className="text-lg font-bold text-midnight">
              {$.profile_projects_title}
            </div>
            <Pill value={talentData?.talent_created_projects?.length || 0} />
            <div
              className="ml-auto h-7.5"
              onClick={(e) => {
                e.stopPropagation();
                setDrawerOpen(!drawerOpen);
              }}
              hidden={isPublicProfile}
            >
              <AddBtn className="w-full" />
            </div>
            {!isPublicProfile && drawerOpen && (
              <div className="absolute -bottom-20 right-0 rounded-lg border border-lightest-grey bg-white p-1 text-kasmir">
                <DrawerElement
                  className="mb-px"
                  title={
                    Object.keys(RSUProjects).length === 0
                      ? "No RSU projects available to import"
                      : null
                  }
                  disabled={Object.keys(RSUProjects).length === 0}
                  onClick={(e) => {
                    if (Object.keys(RSUProjects).length === 0) {
                      e.stopPropagation();
                      alert.error("No RSU projects available to import");
                    } else {
                      e.stopPropagation();
                      setAddRSUOpen(true);
                      setDrawerOpen(false);
                    }
                  }}
                >
                  Import RSU Project
                </DrawerElement>
                <DrawerElement
                  onClick={(e) => {
                    e.stopPropagation();
                    setAddNonRSUOpen(true);
                    setSelectedProject({});
                    setDrawerOpen(false);
                  }}
                >
                  Add non-RSU Project
                </DrawerElement>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-2.5 text-midnight">
            {!Object.entries(talentProjects).length &&
              !Object.entries(RSUProjects).length &&
              !isPublicProfile && (
                <div className="flex flex-col text-center">
                  <div className="flex h-56 justify-center">
                    <img
                      src={placeholderImg}
                      className="object-contain"
                      alt="Add a project"
                    />
                  </div>
                  <div className="mx-auto w-3/5 pb-2 text-center text-kasmir">
                    {$.projects_empty}
                  </div>
                </div>
              )}
            {Object.entries(talentProjects).map(
              ([companyName, projects], i) => (
                <CompanyExperienceListItem
                  key={i}
                  companyName={companyName}
                  projects={projects}
                  showCompletenessBadge={!isPublicProfile}
                  editButtons={
                    !isPublicProfile
                      ? (project) => <EditProjectButtons project={project} />
                      : undefined
                  }
                />
              )
            )}
          </div>
          {hideProjects &&
            talentData?.talent_created_projects?.length >
              HIDE_PROJECTS_ABOVE_NUM && (
              <div className="mt-4 flex">
                <Button
                  secondary
                  className="mx-auto"
                  onClick={() => setHideProjects(false)}
                >
                  See More<span className="font-normal">&nbsp;&darr;</span>
                </Button>
              </div>
            )}
          {addRSUOpen && (
            <ImportRSUProjectForm
              setIsOpen={setAddRSUOpen}
              RSUProjects={RSUProjects}
            />
          )}
          {addNonRSUOpen && (
            <AddTalentProjectForm
              talentData={talentData}
              setIsOpen={setAddNonRSUOpen}
              initialFormValues={selectedProject}
              userLinksWithPreview={userLinksWithPreview}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Projects;
