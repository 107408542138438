import React from "react";

const NavRightIcon = () => {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.349998 17.5C0.349998 26.9717 8.02832 34.65 17.5 34.65C26.9717 34.65 34.65 26.9717 34.65 17.5C34.65 8.02832 26.9717 0.35 17.5 0.35C8.02832 0.35 0.349998 8.02832 0.349998 17.5Z" stroke="#3049C5" strokeWidth="0.7"/>
        <path d="M8.40039 17.5H25.9004M25.9004 17.5L22.3393 21M25.9004 17.5L22.3393 14" stroke="#3049C5" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
};

export default NavRightIcon;


