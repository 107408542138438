import React, { useState } from "react";
import clsx from "clsx";

const PointedTooltip = ({
  children,
  msg,
  onClick,
  className,
  hidden,
  disabled,
  small = false,
  pointed = true,
  translate = "-translate-x-24",
  selfManaged = false // if true, the tooltip will manage its own show/hide state
}) => {
  const [shown, setShown] = useState(false);

  let translation;

  const handleMouseEnter = () => {
    if (disabled) return;
    if (selfManaged) {
      setShown(true);
    }
  };

  const handleMouseLeave = () => {
    if (disabled) return;
    if (selfManaged) {
      setShown(false);
    }
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {(selfManaged ? !shown : hidden === true) ? (
        <>{children}</>
      ) : (
      <div className="relative">
          {children}
          <div
            onClick={onClick}
            className={`absolute bottom-full mb-4 transform rounded-lg border border-lightest-grey bg-white p-1.5 text-center text-xs text-kasmir ${translate} ${className} ${small ? "w-30": "w-60"}`}
          >
            {msg}
            {pointed && (
              <div className="absolute left-1/2 h-4 w-4 -translate-x-1/2 -translate-y-px rotate-45 transform rounded-br rounded-tl-3xl border-b border-r border-geyser bg-white"></div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PointedTooltip;
