import $ from "../../../strings/talent.json";
import clsx from "clsx";
import {FormErrorMessage, Label, Modal, Sublabel} from "../components";
import React, {useEffect, useState} from "react";
import {Form, Formik} from "formik";
import * as yup from "yup";
import magnifier from "../../../assets/magnifier_x.svg";
import {Checkbox, Dropdown, Input, Textarea} from "../../../components/Form";
import {
  deleteTalentAttachedFile,
  deleteTalentTalentCreatedProject,
  deleteTalentUserLink,
  getTalentTalentCreatedCompanies,
  patchTalentAttachedFile,
  patchTalentTalentCreatedProject,
  patchTalentUserLink,
  postTalentAttachedFile,
  postTalentTalentCreatedCompanyFromURL,
  postTalentTalentCreatedProject,
  postTalentUserLink
} from "../../../utils/api";
import {AddTalentProjectLinkModal} from "./AddTalentProjectLinkModal";
import { LinkPreview } from "components/LinkPreview";
import { AddTalentProjectFileModal } from "./AddTalentProjectFileModal";
import { getProjectCompletion } from "../../../utils/misc";
import completeness_red from "assets/completeness_red.png";
import completeness_yellow from "assets/completeness_yellow.png";
import completeness_blue from "assets/completeness_blue.png";
import {components} from "react-select";
import companyLogoPlaceholder from "../../../assets/company_logo_placeholder.png";


const COMMITMENT_OPTIONS = [
  { label: $.full_time_freelancer_option, value: "Full-time freelancer" },
  { label: $.part_time_freelancer_option, value: "Part-time freelancer" },
  { label: $.full_time_employee_option, value: "Full-time employee" },
  { label: $.part_time_employee_option, value: "Part-time employee" },
];

const EMPLOYEE_COUNT_OPTIONS = [
  { label: "1-10", value: "1-10" },
  { label: "11-50", value: "11-50" },
  { label: "51-100", value: "51-100" },
  { label: "101-250", value: "101-250" },
  { label: "251-500", value: "251-500" },
  { label: "501-1,000", value: "501-1,000" },
  { label: "1,001-5,000", value: "1,001-5,000" },
  { label: "5,001-10,000", value: "5,001-10,000" },
  { label: "10,001+", value: "10,001+" }
];

const MONTH_OPTIONS = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 }
];

const YEAR_OPTIONS = Array.from({length: 100}, (_, i) => ({
  label: new Date().getFullYear() - i,
  value: new Date().getFullYear() - i
}));

const EMPTY_FILE_DATA = {file: '', title: '', filename: '', description: '', file_type: ''};
const EMPTY_LINK_DATA = {id: '', url: '', title: '', description: ''};

const getYear = (dateString) => {
  const parts = dateString.split('-');
  return parts.length === 3 && !isNaN(parts[0]) ? parseInt(parts[0], 10) : null;
};

const getMonth = (dateString) => {
  const parts = dateString.split('-');
  return parts.length === 3 && !isNaN(parts[1]) ? parseInt(parts[1], 10) : null;
};

const AddTalentProjectForm = ({ talentData, setIsOpen, initialFormValues, userLinksWithPreview }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [skillsOptions, setSkillsOptions] = useState([]);
  const [toolsOptions, setToolsOptions] = useState([]);
  const [initialCompanyOptions, setInitialCompanyOptions] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [confirmCloseModalOpen, setConfirmCloseModalOpen] = useState(false);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [addingNewCompany, setAddingNewCompany] = useState(false);
  const [companySearch, setCompanySearch] = useState('');
  const [companyDropdownOpen, setCompanyDropdownOpen] = useState(false);
  const [addURLModalOpen, setAddURLModalOpen] = useState(false);
  const [addAttachmentModalOpen, setAddAttachmentModalOpen] = useState(false);
  const [selectedLink, setSelectedLink] = useState(EMPTY_LINK_DATA);
  const [linksToSave, setLinksToSave] = useState([]);
  const [linksToDelete, setLinksToDelete] = useState([]);
  const [selectedFile, setSelectedFile] = useState(EMPTY_FILE_DATA);
  const [filesToSave, setFilesToSave] = useState([]);
  const [filesToDelete, setFilesToDelete] = useState([]);

  const groupQualifications = (qualifications) => {
    const skills = [];
    const tools = [];
    if (!!qualifications) {
      for (const q of qualifications) {
        if (q.type.toLowerCase() === 'skill') {
          skills.push({ label: q.name, value: q.id, type: q.type });
        } else if (q.type.toLowerCase() === 'tool') {
          tools.push({ label: q.name, value: q.id, type: q.type });
        }
      }
    }
    return { skills, tools };
  };

  useEffect(() => {
    const qualifications = talentData?.qualifications
      ?.map(q => q.qualification)
      ?.reduce((acc, item) => {
        if (!acc.some(existingItem => existingItem.id === item.id)) {
          acc.push(item);
        }
        return acc;
      }, [])
      ?.sort((a, b) => a.name.localeCompare(b.name))
      || [];
    if (qualifications.length > 0) {
      const { skills, tools } = groupQualifications(qualifications);
      setSkillsOptions(skills);
      setToolsOptions(tools);
    }
  }, [talentData]);

  useEffect(() => {
    // populate initial values
    const vals = {...initialFormValues};
    if (Object.keys(vals).length === 0) {
      vals.name = '';
      vals.commitment = '';
      vals.company = '';
      vals.company_website = '';
      vals.employees = '';
      vals.start_date_month = '';
      vals.start_date_year = '';
      vals.end_date_month = '';
      vals.end_date_year = '';
      vals.skills = [];
      vals.tools = [];
      vals.current_project = false;
      vals.links = [];
      vals.attached_files = [];
      vals.results = "";
    } else {
      const skillsAndTools = vals.skills_and_tools;
      const {skills, tools} = groupQualifications(skillsAndTools);
      vals.skills = skills.length ? skills : null;
      vals.tools = tools ? tools : null;
      delete vals.skills_and_tools;
      vals.commitment = vals.commitment || null;
      if (vals.company) {
        setInitialCompanyOptions([{ label: vals.company.name, value: vals.company.id }]);
        vals.company = vals.company.id;
      }
      vals.company_website = '';
      vals.employees = vals.employees || null;

      vals.start_date_month = vals.start_date ? getMonth(vals.start_date) : null;
      vals.start_date_year = vals.start_date ? getYear(vals.start_date) : null;
      vals.end_date_month = vals.end_date ? getMonth(vals.end_date) : null;
      vals.end_date_year = vals.end_date ? getYear(vals.end_date) : null;
      vals.current_project = !vals.end_date;
      vals.skillRatings = {};
      vals.results = vals.results || "";
    }
    setInitialValues(vals);
  }, [initialFormValues]);

  const validateEndDate = yup.number().nullable()
    .when(['current_project'], {
      is: false,
      then: (schema) => schema.required($.tpform_end_date_required),
    })
    .test('is-valid-end-date', $.tpform_end_date_invalid, function(value) {
      if (this.parent.current_project) {
        return true;
      }
      const startMonth = this.parent.start_date_month;
      const startYear = this.parent.start_date_year;
      if (!startMonth || !startYear || !value) {
        return true;
      }

      const startDate = new Date(startYear, startMonth - 1);
      const endDate = new Date(
        this.parent.end_date_year,
        this.parent.end_date_month - 1
      );

      return startDate <= endDate;
    });

  const validationSchema = yup.object().shape({
    name: yup.string().required($.tpform_project_name_required),
    company: yup.string().required($.tpform_company_name_required),
    company_website: yup.string().test(
      'is-required-or-valid-url',
      'Company website is required and must be a valid URL',
      function (value) {
        if (addingNewCompany) {
          return !!value && yup.string().url().isValidSync(value);
        }
        return true;
      }
    ),
    start_date_month: yup.number().required($.tpform_start_date_required),
    start_date_year: yup.number().required($.tpform_start_date_required),
    end_date_month: validateEndDate,
    end_date_year: validateEndDate,
    skills: yup.array().nullable().max(5, $.tpform_skills_max_selected),
    tools: yup.array().nullable().max(5, $.tpform_tools_max_selected),
  });

  return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={() => {}}
        enableReinitialize={true}
      >
        {({
          setFieldValue,
          values,
          touched,
          setTouched,
          setFieldTouched,
          errors,
          setFieldError,
          validateForm
        }) => {
          const onChange = async (e) => {
            let { name, value } = e.target;
            await setFieldValue(name, value);
            await setFieldTouched(name, value !== initialValues[name]);
            if (errors[name]) {
              setFieldError(name, null);
            }
          };

          const onChangeDropdown = async (option, action) => {
            const value = option.value !== undefined ? option.value : option;
            const e = { target: { name: action.name, value: value } };
            return await onChange(e);
          };

          const getTouchedValues = () => {
            const touchedValues = {};
            for (const key in touched) {
              if (touched[key]) {
                touchedValues[key] = values[key];
              }
            }
            return touchedValues;
          };

          const save = async () => {
            setIsUpdating(true);
            const validationErrors = await validateForm();
            if (Object.keys(validationErrors).length > 0) {
              setIsUpdating(false);
              return;
            }

            let touchedValues = getTouchedValues();
            const projectPayload = {};

            if (touchedValues.company_website) {
              const company = await postTalentTalentCreatedCompanyFromURL({
                website: touchedValues.company_website,
                name: initialCompanyOptions[0].label
              });
              setInitialCompanyOptions([{
                label: company.name,
                value: company.id
              }]);
              await setFieldValue('company', company.id);
              await setFieldTouched('company', true);
              touchedValues.company = company.id;
            }

            if (touchedValues.name) {
              projectPayload.name = touchedValues.name;
            }
            if (!!touchedValues.commitment) {
              projectPayload.commitment = touchedValues.commitment || null;
            }
            if (!!touchedValues.company) {
              projectPayload.company_id = touchedValues.company || null;
            }
            if (!!touchedValues.employees) {
              projectPayload.employees = touchedValues.employees || null;
            }
            if (!!touchedValues.start_date_month || !!touchedValues.start_date_year) {
              let month = touchedValues.start_date_month || values.start_date_month;
              month = month < 10 ? `0${month}` : `${month}`;
              const year = touchedValues.start_date_year || values.start_date_year;
              projectPayload.start_date = `${year}-${month}-03`;
            }
            if (touchedValues.current_project === true) {
              projectPayload.end_date = null;
            } else if (!!touchedValues.end_date_month || !!touchedValues.end_date_year) {
              let month = touchedValues.end_date_month || values.end_date_month;
              month = month < 10 ? `0${month}` : `${month}`;
              const year = touchedValues.end_date_year || values.end_date_year;
              projectPayload.end_date = `${year}-${month}-03`;
            }
            if (touchedValues.hide_dates != null) {
              projectPayload.hide_dates = touchedValues.hide_dates;
            }
          if (Object.keys(touchedValues).includes("results")) {
              projectPayload.results = touchedValues.results;
            }
            const skillsAndTools =  [...(values.skills || []), ...(values.tools || [])];
            projectPayload.skills_and_tools_ids = skillsAndTools.map(q => q.value);

            let talent_project_id = initialValues.id;
            if (!!Object.keys(projectPayload).length) {
              if (!initialValues.id) {
                const response = await postTalentTalentCreatedProject(projectPayload);
                talent_project_id = response.id;
              } else {
                await patchTalentTalentCreatedProject(initialValues.id, projectPayload);
              }
            }

            const linksToCreate = linksToSave.filter(l => !l.id);
            const linksToUpdate = linksToSave.filter(l => !!l.id);
            for (const link of linksToCreate) {
              await postTalentUserLink({...link, talent_project_id});
            }
            for (const link of linksToUpdate) {
              await patchTalentUserLink(link.id, {...link, talent_project_id});
            }
            for (const link of linksToDelete) {
              await deleteTalentUserLink(link.id);
            }

            const filesToCreate = filesToSave.filter(f => !f.id);
            const filesToUpdate = filesToSave.filter(f => !!f.id);
            for (const file of filesToCreate) {
              await postTalentAttachedFile({
                ...file,
                category: 'Work Sample',
                talent_project_id
              });
            }
            for (const file of filesToUpdate) {
              const dt = {...file, talent_project_id};
              delete dt.file; // patching file content is not supported
              await patchTalentAttachedFile(file.id, dt);
            }
            for (const file of filesToDelete) {
              await deleteTalentAttachedFile(file.id);
            }

            setIsUpdating(false);
            setIsOpen(false);
          };

          const cancelProject = () => {
            const touchedValues = getTouchedValues();
            if (Object.keys(touchedValues).length > 0) {
              setConfirmCloseModalOpen(true);
            } else {
              setIsOpen(false);
            }
          };

          const NoCompanyResults = () => {
            return (
              <div className="p-1.25 flex flex-col text-kasmir text-sm font-lato">
                <div className="flex p-1.5">
                  <img
                    className="mr-1.25"
                    src={magnifier}
                    alt="No results"
                  />
                  <span className="cursor-default">No results</span>
                </div>
              </div>
            );
          };

          const startAddingNewCompany = () => {
            setAddingNewCompany(true);
            setInitialCompanyOptions([
              {label: companySearch, value: 'tmp1'}
            ]);
            setFieldValue('company', 'tmp1').then();
            // close the company dropdown
            setCompanyDropdownOpen(false);
          };

          const CompanyDropdownMenu = (props) => (
            <components.Menu {...props}>
              {props.children}
              <div className="text-sm font-lato">
                <button
                  className="underline text-electric-indigo hover:text-electric-indigo-lighter text-xs p-1.5"
                  onClick={startAddingNewCompany}
                >{addingNewCompany ? 'Update company name' : 'Add company'}
                </button>
              </div>
            </components.Menu>
          );

          const CompanyDropdownOption = (props) => {
            return (
              <components.Option {...props}>
                <div className="flex items-center">
                  <img
                    src={props.data.logo}
                    onError={(e) => e.target.src = companyLogoPlaceholder}
                    alt={props.data.label}
                    className="w-4 h-4 rounded-full mr-3"
                  />
                  <div className="flex flex-grow justify-between">
                    <div>{props.data.label}</div>
                    <div>
                      {props.data.website}
                    </div>
                  </div>
                </div>
              </components.Option>
            );
          };

          const ProjectCompletionIndicator = () => {
            let start_date = null;
            if (values.start_date_month && values.start_date_year) {
              let month = values.start_date_month;
              month = (month && month < 10) ? `0${month}` : `${month}`;
              const year = values.start_date_year;
              start_date = `${year}-${month}-03`;
            }
            const completion = getProjectCompletion({
              name: values.name,
              experience_type: values.commitment,
              company: values.company,
              company_employee_count: values.employees,
              start_date: values.start_date || start_date,
              end_date: values.end_date,
              results: values.results,
              skills_and_tools: [...(values.skills || []), ...(values.tools || [])],
              work_sample: values.links?.length || values.attached_files?.length
            });
            return (
              <div
                className={clsx(
                  "text-sm font-bold flex z-top bg-white rounded-lg",
                  completion >= 80 ? "text-electric-indigo"
                    : completion >= 50 ? "text-orange-darker"
                    : "text-red"
                )}
                title={$.project_completion_indicator_tooltip}
              >
                <img
                  className="mr-1.25"
                  src={completion >= 80 ? completeness_blue : completion >= 50 ? completeness_yellow : completeness_red}
                  alt="Completeness"
                />
                {completion}%&nbsp;complete
              </div>
            );
          };

          return (
            <>
            <Modal
              title={initialValues.id ? $.edit_non_rsu_project_title : $.add_non_rsu_project_title}
              titleChildren={<ProjectCompletionIndicator />}
              onClose={cancelProject}
              onClickSave={save}
              buttonsDisabled={isUpdating}
              saveBtnText={$.save_btn}
              fullScreenOnMobile
              deleteBtnText={$.delete_project_btn}
              onClickDelete={() => setConfirmDeleteModalOpen(true)}
              showDelete={!!initialFormValues.id}
              className={clsx(
                confirmDeleteModalOpen || addURLModalOpen || addAttachmentModalOpen || confirmCloseModalOpen
                  ? 'hidden' : '')}
            >
              <Form className="form">
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col">
                    <Label>{$.project_name_label}</Label>
                    <Sublabel>{$.project_name_sublabel}</Sublabel>
                    <Input
                      name="name"
                      value={values.name}
                      error={errors.name}
                      onChange={onChange}
                    />
                    <FormErrorMessage error={errors.name}/>
                  </div>
                  <div className="flex flex-col">
                    <Label>{$.experience_type_label}</Label>
                    <Sublabel>{$.experience_type_sublabel}</Sublabel>
                    <Dropdown
                      name="commitment"
                      placeholder={$.experience_type_placeholder}
                      options={COMMITMENT_OPTIONS}
                      value={values.commitment}
                      error={errors.commitment}
                      onChange={onChangeDropdown}
                      className="font-bold"
                    />
                    <FormErrorMessage error={errors.commitment}/>
                  </div>
                  <div className="flex flex-col">
                    <Label>{$.company_name_label}</Label>
                    <Sublabel>{$.company_name_sublabel}</Sublabel>
                    <Dropdown
                      name="company"
                      placeholder={$.company_name_placeholder}
                      async
                      loadOptions={async (search) => {
                        if (!search) {
                          return {options: [], hasMore: false};
                        }
                        const response = await getTalentTalentCreatedCompanies({search});
                        const options = response.results.map((c) => ({
                          label: c.name,
                          value: c.id,
                          logo: c.logo,
                          website: c.website,
                        }));
                        return {
                          options,
                          hasMore: false,
                        };
                      }}
                      onInputChange={async (search, {action, prevInputValue}) => {
                        if (action !== 'input-change') {
                          return;
                        }
                        setInitialCompanyOptions([
                          {label: search, value: 'tmp1'},
                        ]);
                        setCompanySearch(search);
                      }}
                      options={initialCompanyOptions}
                      value={values.company}
                      error={errors.company}
                      onChange={async (e, v) => {
                        setAddingNewCompany(false);
                        setInitialCompanyOptions([e]);
                        await onChangeDropdown(e, v);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !!e.target.value) {
                          startAddingNewCompany();
                          e.target.blur();
                        }
                      }}
                      className="font-bold"
                      onMenuOpen={() => setCompanyDropdownOpen(true)}
                      onMenuClose={() => setCompanyDropdownOpen(false)}
                      menuIsOpen={companyDropdownOpen}
                      components={{
                        NoOptionsMessage: NoCompanyResults,
                        Menu: CompanyDropdownMenu,
                        Option: CompanyDropdownOption,
                      }}
                    />
                    <FormErrorMessage error={errors.company}/>
                  </div>
                  {addingNewCompany && (
                    <div className="flex flex-col">
                      <Label>{$.company_website_label}</Label>
                      <Input
                        name="company_website"
                        value={values.company_website}
                        error={errors.company_website}
                        onBlur={async () => {
                          // strip whitespace and slashes
                          const website = values.company_website?.replace(/[\s/]+/g, '') || '';
                          if (website && !website.startsWith('http')) {
                            await setFieldValue('company_website', `https://${website}`);
                          }
                        }}
                        onChange={onChange}
                      />
                      <FormErrorMessage error={errors.company_website}/>
                    </div>)}
                  <div className="flex flex-col">
                    <Label>{$.company_employee_count_label}</Label>
                    <Sublabel>{$.company_employee_count_sublabel}</Sublabel>
                    <Dropdown
                      name="employees"
                      placeholder={$.company_employee_count_placeholder}
                      options={EMPLOYEE_COUNT_OPTIONS}
                      value={values.employees}
                      error={errors.employees}
                      onChange={onChangeDropdown}
                      className="font-bold"
                    />
                    <FormErrorMessage error={errors.employees}/>
                  </div>
                  <div className="flex flex-col">
                    <Label className="font-lato">{$.start_date_label}</Label>
                    <div className="flex flex-row gap-x-2.5">
                      <div className="flex flex-col w-1/2">
                        <Dropdown
                          name="start_date_month"
                          placeholder="Month"
                          options={MONTH_OPTIONS}
                          value={values.start_date_month}
                          error={errors.start_date_month}
                          onChange={onChangeDropdown}
                          className="font-bold"
                        />
                      </div>
                      <div className="flex flex-col w-1/2">
                        <Dropdown
                          name="start_date_year"
                          placeholder="Year"
                          options={YEAR_OPTIONS}
                          value={values.start_date_year}
                          error={errors.start_date_year}
                          onChange={onChangeDropdown}
                          className="font-bold"
                        />
                      </div>
                    </div>
                    <FormErrorMessage
                      error={errors.start_date_month || errors.start_date_year}
                      emptyHeight="0"/>
                    {!values.current_project && (<div>
                      <Label
                        className="font-lato mt-2">{$.end_date_label}</Label>
                      <div className="flex flex-row gap-x-2.5">
                        <div className="flex flex-col w-1/2">
                          <Dropdown
                            name="end_date_month"
                            placeholder="Month"
                            options={MONTH_OPTIONS}
                            value={values.end_date_month}
                            error={errors.end_date_month}
                            onChange={onChangeDropdown}
                            className="font-bold"
                          />
                        </div>
                        <div className="flex flex-col w-1/2">
                          <Dropdown
                            name="end_date_year"
                            placeholder="Year"
                            options={YEAR_OPTIONS}
                            value={values.end_date_year}
                            error={errors.end_date_year}
                            onChange={onChangeDropdown}
                            className="font-bold"
                          />
                        </div>
                      </div>
                      <FormErrorMessage
                        error={errors.end_date_month || errors.end_date_year}
                        emptyHeight="0"/>
                    </div>)}
                    <div className="flex flex-row mt-2">
                      <Checkbox
                        name="current_project"
                        outlined
                        value={values.current_project}
                        error={errors.current_project}
                        onChange={e => onChange({
                          target: {
                            name: 'current_project',
                            value: e
                          }
                        })}
                      >
                        {$.active_project_label}
                      </Checkbox>
                    </div>
                    <div className="flex flex-row mt-2">
                      <Checkbox
                        name="hide_dates"
                        outlined
                        value={values.hide_dates}
                        error={errors.hide_dates}
                        onChange={e => onChange({
                          target: {
                            name: 'hide_dates',
                            value: e
                          }
                        })}
                      >
                        {$.hide_dates_label}
                      </Checkbox>
                    </div>
                  </div>
                  <div className="flex flex-col mt-5">
                    <Label>{$.results_label}</Label>
                    <Sublabel>{$.results_sublabel}</Sublabel>
                    <Textarea
                      type="textarea"
                      name="results"
                      value={values.results || ''}
                      error={errors.results}
                      className="resize-none"
                      unbolded
                      placeholder={$.results_placeholder}
                      onChange={onChange}
                    />
                    {(touched.results && values.results.length < 150) && <FormErrorMessage error={$.tpform_results_required}/>}
                  </div>
                  <div className="flex flex-col">
                    <Label>{$.skills_used_label}</Label>
                    <Sublabel>{$.skills_used_sublabel}</Sublabel>
                    <Dropdown
                      id="skills"
                      name="skills"
                      placeholder={$.skills_used_placeholder}
                      options={skillsOptions}
                      value={values.skills}
                      error={errors.skills}
                      onChange={onChangeDropdown}
                      className="font-bold"
                      isMulti
                    />
                    <FormErrorMessage error={errors.skills}/>
                  </div>
                  <div className="flex flex-col">
                    <Label>{$.tools_used_label}</Label>
                    <Sublabel>{$.tools_used_sublabel}</Sublabel>
                    <Dropdown
                      name="tools"
                      placeholder={$.tools_used_placeholder}
                      options={toolsOptions}
                      value={values.tools}
                      error={errors.tools}
                      onChange={onChangeDropdown}
                      className="font-bold text-sm"
                      isMulti
                    />
                    <FormErrorMessage error={errors.tools}/>
                  </div>
                  {/* <div className="flex flex-col">
                    <Label>{$.work_sample_label}</Label>
                    <Sublabel>{$.work_sample_sublabel}</Sublabel>
                    <Dropdown
                      name="work_sample"
                      placeholder={`${$.work_sample_placeholder}`}
                      options={[
                        {
                          label: $.file_upload_option,
                          value: $.file_upload_option
                        },
                        {label: $.url_link_option, value: $.url_link_option},
                      ]}
                      onChange={e => {
                        if (e.value === $.file_upload_option) {
                          setAddAttachmentModalOpen(true);
                        } else if (e.value === $.url_link_option) {
                          setAddURLModalOpen(true);
                        }
                      }}
                      className="font-bold"
                    />
                  </div> */}
                  {/* {values.links && (<div className="flex flex-col gap-y-2.5">
                    {values.links.map((link, i) => (
                      <LinkPreview
                        onClick={() => {
                          setSelectedLink(link);
                          setAddURLModalOpen(true);
                        }}
                        className="cursor-pointer mr-auto hover:bg-link-water p-1 rounded-lg"
                        name={link.name}
                        url={userLinksWithPreview[link.id]}
                        key={i}
                      />
                    ))}
                  </div>)} */}
                  {values.attached_files && (
                    <div className="flex flex-col gap-y-2.5">
                      {values.attached_files.map((file, i) => (
                        <LinkPreview
                          onClick={() => {
                            setSelectedFile(file);
                            setAddAttachmentModalOpen(true);
                          }}
                          className="cursor-pointer mr-auto hover:bg-link-water p-1 rounded-lg"
                          name={file.title}
                          url={file.url}
                          key={i}
                        />
                      ))}
                    </div>)}
                </div>
              </Form>
            </Modal>
            <>
              {confirmCloseModalOpen && (<Modal
                title={$.discard_changes_title}
                onClose={() => setConfirmCloseModalOpen(false)}
                onClickSave={() => {
                  setConfirmCloseModalOpen(false);
                  setIsOpen(false);
                }}
                buttonsDisabled={isUpdating}
                saveBtnText={$.discard_changes_confirm_btn}
                cancelBtnText={$.discard_changes_cancel_btn}
              >
                <p className="text-base">{$.discard_changes_message_1}</p>
                <br/>
                <p className="text-base">{$.discard_changes_message_2}</p>
              </Modal>)}
              {confirmDeleteModalOpen && (<Modal
                title={$.delete_project_title}
                onClose={() => setConfirmDeleteModalOpen(false)}
                onClickSave={async () => {
                  await deleteTalentTalentCreatedProject(initialValues.id);
                  setConfirmDeleteModalOpen(false);
                  setIsOpen(false);
                }}
                buttonsDisabled={isUpdating}
                saveBtnText={$.delete_project_btn}
              >
                <p className="text-base">{$.delete_project_message_1}</p>
                <br/>
                <p className="text-base">{$.delete_project_message_2}</p>
              </Modal>)}
              {addAttachmentModalOpen &&
                <AddTalentProjectFileModal
                  initialValues={selectedFile}
                  closeModal={() => {
                    setAddAttachmentModalOpen(false);
                    setSelectedFile(EMPTY_FILE_DATA);
                  }}
                  saveFile={(file) => {
                    setFilesToSave((prev) => {
                      return [...prev.filter(f => !f.id || f.file !== file.file), file].sort((a, b) => a.title.localeCompare(b.title));
                    });
                    setFieldValue('attached_files',
                      [...values.attached_files.filter(f => !f.id || f.file !== file.file), file].sort((a, b) => a.title.localeCompare(b.title)
                    )).then();
                  }}
                  deleteFile={(file) => {
                    setFilesToSave((prev) => prev.filter(f => f.file !== file.file));
                    setFieldValue('attached_files', values.attached_files.filter(f => f.file !== file.file)).then();
                    setFilesToDelete((prev) => [...prev, file]);
                  }}
                />
              }
              {addURLModalOpen &&
                <AddTalentProjectLinkModal
                  initialValues={selectedLink}
                  closeModal={() => {
                    setAddURLModalOpen(false);
                    setSelectedLink(EMPTY_LINK_DATA);
                  }}
                  saveLink={(link) => {
                    const filterFn = l => l.name !== link.name && l.id !== link.id;
                    const sortFn = (a, b) => a.url.localeCompare(b.url);
                    setLinksToSave((prev) => {
                      // alphabetical by link
                      return [...prev.filter(filterFn), link].sort(sortFn);
                    });
                    setFieldValue('links',
                    [...values.links.filter(filterFn), link].sort(sortFn)).then();
                  }}
                  deleteLink={(link) => {
                    setLinksToSave((prev) => prev.filter(l => l.url !== link.url));
                    setFieldValue('links', values.links.filter(l => l.url !== link.url)).then();
                    setLinksToDelete((prev) => [...prev, link]);
                  }}
                />
              }
            </>
            </>
          );
        }}
      </Formik>
  );
};

export default AddTalentProjectForm;
