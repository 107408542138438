export const getProjectInfo = (project) => {
  let size = project.employees;
  if (size && !size.includes(" employees")) {
    size += " employees";
  }
  if (project.commitment && size) {
    return `${project.commitment} · ${size}`;
  } else if (project.commitment && !size) {
    return project.commitment;
  } else if (!project.commitment && size) {
    return size;
  } else {
    return null;
  }
};

export const getProjectTime = (project, isPublicProfile) => {
  const NO_DATES_MSG = null;
  if (isPublicProfile && project.hide_dates) {
    return NO_DATES_MSG;
  }
  const UTC = (d) => new Date(`${d}T00:00:00Z`);
  const simpleDate = (date) => {
    const d = new Date(`${date}T00:00:00Z`);
    return (
      d.toLocaleString("default", { month: "short" }) + " " + d.getFullYear()
    );
  };

  if (project.start_date && project.end_date) {
    let months = Math.round(
      (UTC(project.end_date) - UTC(project.start_date)) /
        (1000 * 60 * 60 * 24 * 30)
    );
    let years = Math.floor(months / 12);
    let time;
    if (years > 0) {
      months = months % 12;
      time = `${years} yr${years > 1 ? "s" : ""}`;
      if (months > 0) {
        time += ` ${months} mo${months > 1 ? "s" : ""}`;
      }
    } else if (months > 0) {
      time = `${months} mo${months > 1 ? "s" : ""}`;
    } else {
      time = "Less than a month";
    }
    return `${simpleDate(project.start_date)} - ${simpleDate(project.end_date)} · ${time}`;
  } else if (project.start_date && !project.end_date) {
    return `Since ${simpleDate(project.start_date)}`;
  } else if (!project.start_date && project.end_date) {
    return `Until ${simpleDate(project.end_date)}`;
  } else {
    return NO_DATES_MSG;
  }
};
