import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { postCandidateV2 } from "utils/adminApi";
import { fetchAccounts } from "utils/fetch";
import { admin_project_candidate_form as $ } from "strings";
import Select from "components/Select";
import AlertError from "components/AlertError";
import { Button, OutlinedButton, IconBtn } from "components/Buttons";
import { Textarea } from "components/Form";
import HubspotToast from "components/HubspotToast";
import { components } from "react-select";
import { useAlert } from "react-alert";
import UilTrashAlt from "@iconscout/react-unicons/icons/uil-trash-alt";
import UilRefresh from "@iconscout/react-unicons/icons/uil-refresh";
import UilPlus from "@iconscout/react-unicons/icons/uil-plus";
import UilExpandArrowsAlt from "@iconscout/react-unicons/icons/uil-expand-arrows-alt";
import UilCopy  from  "@iconscout/react-unicons/icons/uil-copy";

const gradeOptions = {
  8: "A+",
  7: "A",
  6: "A-",
  5: "B+",
  4: "B",
  3: "B-",
  2: "C",
};

const CandidateListActions = ({
  candidates,
  project,
  onSuccess,
  onSort,
  selectedSort,
  setSelectedSort,
  selectedCandidates,
  setSelectedCandidates,
  selectedStatuses,
  setSelectedStatuses,
  setModal
}) => {
  const alert = useAlert();
  const { id } = useParams();
  const [selectedCandidate, setSelectedCandidate] = useState();
  const [description, setDescription] = useState("");
  const [projectCandidateIds, setProjectCandidateIds] = useState([]);
  const [toolbarView, setToolbarView] = useState('default');
  const [saving, setSaving] = useState(false);

  const sortOptions = [
    { value: "name", label: "Name", order: "asc" },
    { value: "creator", label: "Creator", order: "asc" },
    { value: "availability_capacity", label: "Availability", order: "desc" },
    { value: "minimum_rate", label: "Minimum rate", order: "asc" },
    { value: "talent_grade", label: "Talent Grade", order: "desc" },
  ];

  useEffect(() => {
    const ids = candidates.map(c => c.candidate_user_id);
    setProjectCandidateIds([...ids]);
  }, [candidates]);

  useEffect(() => {
    if (!selectedCandidates) return;
    if (selectedCandidates.length && toolbarView === 'addCandidate') {
      setToolbarView('bulkActions') 
    } else if (toolbarView !== 'addCandidate') {
      setToolbarView(selectedCandidates.length ? 'bulkActions' : 'default')
    }
  }, [selectedCandidates]);

  const onAddCandidate = async () => {
    setSaving(true);
    try {
      await postCandidateV2({
        candidate: selectedCandidate.account.user.id,
        project: id,
        status: "new",
        applied: new Date(),
        description,
      });

      onSuccess();
      setSaving(false);
      setToolbarView('default');
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} onRetry={onAddCandidate} />);
    }
  };

  const onCancel = () => {
    setToolbarView('default');
    setSelectedCandidate(null);
  };

  const handleUnselectAll = () => {
    setSelectedCandidates([]);
    setSelectedStatuses([]);
    setToolbarView('default');
  }

  const handleSort = (v) => {
    setSelectedSort(v);
    onSort(v);
  };

  const onBatchChangeClick = () => {
    if (
      selectedCandidates.filter(c => c.status === 'scheduled')?.length > 0 ||
      selectedStatuses.indexOf('scheduled') > -1
    ) {
      alert.success(<HubspotToast type='candidate' objectId={project?.hubspot_deal_id} />, {timeout: 10000});
      return;
    } else {
      setModal("batchChangeStatus")
    }
  }

  return (
    <div className="candidate_list_actions flex w-full mb-1 items-center py-2">
      {toolbarView !== 'addCandidate' && (
        <Button onClick={() => {
          setToolbarView('addCandidate');
          setSelectedCandidates([]);
          setSelectedStatuses([]);
        }}>
          {$.add_candidate_title}
        </Button>
      )}
      {toolbarView === 'addCandidate' && (
        <div className="flex w-3/4">
          <div className="flex items-start justify-start mt-1 mr-1">
            <div className="group relative">
              <Button
                noMinW
                disabled={saving || !selectedCandidate || projectCandidateIds.indexOf(selectedCandidate.account.user.id) > -1}
                onClick={onAddCandidate}
              >
                {$.save_button}
              </Button>
              {(selectedCandidate && projectCandidateIds.indexOf(selectedCandidate.account.user.id) > -1) && 
                <div className="bottom-full left-1/4 trans-left-1/4 mb-1 w-content bg-cove text-white rounded px-2 py-1 text-center absolute z-30 font-bold text-xs hidden group-hover:inline">
                  {$.candidate_already_added_label}
                </div>
              }
            </div>
            <OutlinedButton noMinW className="ml-1 h-10" onClick={onCancel}>
              {$.cancel_button}
            </OutlinedButton>
          </div>
          <div className="flex flex-1 items-start justify-start flex-col">
            <Select
              className="w-full"
              placeholder={$.search_placeholder}
              onChange={(v) => setSelectedCandidate(v)}
              value={selectedCandidate}
              components={{
                SingleValue: ({ data, children, ...rest }) => (
                  <components.SingleValue {...rest}>
                    <div className="flex items-center">
                      <div className="text-midnight w-1/3 font-bold">
                        {children}
                      </div>
                      <div className="text-kasmir w-2/3 flex items-center font-bold text-sm">
                        <div className="w-6/12 truncate">
                          {data.account.current_role || ""}
                        </div>
                        <div className="w-3/12">
                          {data.account.minimum_rate
                            ? "Pay Rate: $" + data.account.minimum_rate
                            : ""}
                        </div>
                        <div className="w-2/12">
                          {data.account.availability_capacity && data.account.availability_utilization ?
                            data.account.availability_capacity - data.account.availability_utilization + " hrs/wk" :
                            data.account.availability_capacity ? data.account.availability_capacity + " hrs/wk":
                            ""
                          }
                        </div>
                        <div className="w-1/12">
                          {data.account.talent_grade
                            ? gradeOptions[data.account.talent_grade]
                            : ""}
                        </div>
                      </div>
                    </div>
                  </components.SingleValue>
                ),
                Option: ({ data, children, ...rest }) => (
                  <components.Option {...rest}>
                    <div className="flex items-center">
                      <div className="text-midnight w-1/3 font-bold">
                        {children}
                      </div>
                      <div className="text-kasmir w-2/3 flex items-center">
                        <div className="w-6/12 truncate">
                          {data.account.current_role || ""}
                        </div>
                        <div className="w-3/12">
                          {data.account.minimum_rate
                            ? "Pay Rate: $" + data.account.minimum_rate
                            : ""}
                        </div>
                        <div className="w-2/12">
                          {data.account.availability_capacity && data.account.availability_utilization ?
                            data.account.availability_capacity - data.account.availability_utilization + " hrs/wk" :
                            data.account.availability_capacity ? data.account.availability_capacity + " hrs/wk" :
                            "" 
                          }
                        </div>
                        <div className="w-1/12">
                          {data.account.talent_grade
                            ? gradeOptions[data.account.talent_grade]
                            : ""}
                        </div>
                      </div>
                    </div>
                  </components.Option>
                ),
              }}
              async
              loadOptions={fetchAccounts}
            />
            {selectedCandidate && (
              <div className="w-full">
                <Textarea
                  small
                  placeholder={$.description_placeholder}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {toolbarView === 'bulkActions' && (
        <div className="flex items-center w-3/4 h-10 bg-white rounded-sm gap-2 ml-4 px-2">
          <IconBtn onClick={handleUnselectAll}>
            <UilPlus style={{transform: 'rotate(45deg)'}} size="20" />
          </IconBtn>
          <div className="flex gap-2 text-kasmir text-sm font-bold items-center">
            <div className="bg-geyser px-3 rounded-full">{selectedCandidates.length}</div>
            <div>candidates selected</div>
          </div>
          <div className="flex gap-2 mb-0 ml-auto items-center">
          <OutlinedButton secondary onClick={() => setModal("batchDuplicate")}>
              <UilCopy size="20" />
              <span className="ml-1">Duplicate to Another Project</span>
            </OutlinedButton>
            <OutlinedButton secondary onClick={() => setModal("batchMove")}>
              <UilExpandArrowsAlt style={{transform: 'rotate(45deg)'}} size="16" />
              <span className="ml-1">Move</span>
            </OutlinedButton>
            <OutlinedButton onClick={onBatchChangeClick}>
              <UilRefresh size="20" />
              <span className="ml-1">Change Status</span>
            </OutlinedButton>
            <OutlinedButton onClick={() => setModal("deleteCandidate")} danger={true}>
              <UilTrashAlt size="20" />
              <span className="ml-1">Delete</span>
            </OutlinedButton>
          </div>
      </div>
      )}
      <div className="flex flex-end mr-0 ml-auto w-48">
        <Select
          className={"flex-1"}
          placeholder={$.sort_placeholder}
          onChange={(v) => handleSort(v)}
          value={selectedSort}
          options={sortOptions}
          components={{
            SingleValue: ({ data, children, ...rest }) => (
              <components.SingleValue {...rest}>
                <span className="font-bold text-kasmir">{$.sort_placeholder}:</span> {children}
              </components.SingleValue>
            )
        }}
        />
      </div>
    </div>
  );
};

export default CandidateListActions;
