import React from "react";

const StarLineThroughIcon = ({ size = 22, fill = "#0E1539" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 12 11"
      fill="none"
    >
      <path
        d="M10.6032 3.62608C11.1059 3.74377 11.3042 4.38102 10.9637 4.78488L9.17521 6.90613L9.42824 9.72013C9.47641 10.2559 8.95737 10.6497 8.4844 10.4363L6.00003 9.31541L3.51564 10.4363C3.08414 10.631 2.61432 10.3203 2.57179 9.85752C2.56771 9.81303 2.56757 9.76712 2.57179 9.72013L2.73288 7.9287L2.82483 6.90613L1.03637 4.78488C0.695884 4.38102 0.894132 3.74379 1.39689 3.62608L4.03764 3.00783L5.4167 0.575933C5.67925 0.112954 6.32081 0.112954 6.58336 0.575933L7.84194 2.79537L2.73288 7.9287L2.57179 9.72013C2.56757 9.76712 2.56771 9.81303 2.57179 9.85752L9.07931 3.26931L10.6032 3.62608Z"
        fill={fill}
      />
      <path
        d="M8.03752 2.566L9.60187 1.00166C9.74446 0.859067 9.97776 0.859067 10.1204 1.00166C10.2629 1.14425 10.2629 1.37757 10.1204 1.52016L8.72563 2.91491L7.72355 3.91699L7.2052 4.4353L4.75568 6.88482L4.23735 7.40315L3.2257 8.41479L1.64205 9.99845C1.49946 10.141 1.26613 10.141 1.12354 9.99845C0.980954 9.85586 0.980954 9.62255 1.12354 9.47996L2.61484 7.98867L3.80314 6.80038L4.36065 6.24287L6.56327 4.04023L7.12078 3.48274L8.03752 2.566Z"
        fill={fill}
      />
    </svg>
  );
};

export default StarLineThroughIcon;
