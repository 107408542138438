import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import $ from "strings/talent";
import $$ from "strings/talent";
import {
  ExpertiseIcon,
  LoginCredentialsIcon,
  ProfileSettingsIcon
} from "components/icons";
import { getUrlSuffix } from "../../../utils/localStorageService";
import ContactInformation from "./ContactInformation";
import LoginCredentials from "./LoginCredentials";
import EmploymentStatus from "./EmploymentStatus";
import OpportunityPreferences from "./OpportunityPreferences";
import SearchEngineOptimization from "./SearchEngineOptimization";
import Availability from "./Availability";
import Experience from "./Experience";
import toast, { Toaster } from "react-hot-toast";
import Toast from "../components/Toast";

import Modal from "../components/Modal";
import NavigationPrompt from "react-router-navigation-prompt";
import { Loader } from "../onboard/screens";
import { SidebarNavSection } from "../components/SidebarNavSection";

export const SETTINGS_SCREENS = {
  contact: {
    icon: <ExpertiseIcon />,
    activeIcon: <ExpertiseIcon active={true} />,
    title: $.contact_title
  },
  credentials: {
    icon: <LoginCredentialsIcon />,
    activeIcon: <LoginCredentialsIcon active={true} />,
    title: $.credentials_title
  },
  profile_settings: {
    icon: <ProfileSettingsIcon />,
    activeIcon: <ProfileSettingsIcon active={true} />,
    title: $.profile_settings_title,
    subsections: {
      seo: {
        title: $$.seo_section_title,
        page_title: $$.seo_page_title
      },
      employment: {
        title: $$.employment_status_title
      },
      opportunity: {
        title: $$.opportunity_preferences_title
      },
      availability: {
        title: $$.availability_title
      },
      experience: {
        title: $$.experience_title
      }
    }
  }
};

const SettingsPage = ({ talentData, setTalentData }) => {
  const [activeScreen, setActiveScreen] = useState();
  const [activeSubsection, setActiveSubsection] = useState("seo");
  const [pageSubtitle, setPageSubtitle] = useState();
  const [showNavPrompt, setShowNavPrompt] = useState(false);

  const { search } = useLocation();

  const renderActiveScreen = () => {
    const pageUrl = window.location.href;
    if (pageUrl.includes("tab=contact")) {
      setActiveScreen("contact");
    } else if (pageUrl.includes("tab=credentials")) {
      setActiveScreen("credentials");
    } else if (pageUrl.includes("tab=profile")) {
      setActiveScreen("profile_settings");
      if (pageUrl.includes("seo")) {
        setActiveSubsection("seo");
      } else if (pageUrl.includes("employment")) {
        setActiveSubsection("employment");
      } else if (pageUrl.includes("opportunity")) {
        setActiveSubsection("opportunity");
      } else if (pageUrl.includes("availability")) {
        setActiveSubsection("availability");
      } else if (pageUrl.includes("experience")) {
        setActiveSubsection("experience");
      }
    } else {
      setActiveScreen("contact");
    }
  };

  useEffect(() => {
    renderActiveScreen();
  }, []);

  useEffect(() => {
    renderActiveScreen();
  }, [activeScreen, showNavPrompt, search]);

  useEffect(() => {
    if (!activeScreen) return;
    let title;

    if (SETTINGS_SCREENS[activeScreen].subsections) {
      const subsection =
        SETTINGS_SCREENS[activeScreen].subsections[activeSubsection];
      title = subsection.page_title || subsection.title;
    } else {
      title = SETTINGS_SCREENS[activeScreen].title;
    }
    setPageSubtitle(title);
  }, [activeScreen, activeSubsection]);

  const displayToast = (sectionStr) => {
    toast(<Toast message={`${sectionStr} saved successfully`} />);
  };

  const history = useHistory();
  const userSuffix = getUrlSuffix();

  const onNavClick = (screen) => {
    if (!showNavPrompt) {
      if (
        activeScreen === "profile_settings" &&
        screen !== "profile_settings"
      ) {
        setActiveSubsection("seo");
      }
      setActiveScreen(screen);
    }
    history.push(`/talent/${userSuffix}/settings?tab=${screen}`);
  };

  const onSubsectionClick = (subsection) => {
    if (!showNavPrompt) {
      setActiveSubsection(subsection);
    }
    history.push(
      `/talent/${userSuffix}/settings?tab=profile_settings:${subsection}`
    );
  };

  return (
    <div className="h-full w-full">
      {talentData ? (
        <div className="flex flex-col gap-4 p-4 md:flex-row">
          <div className="md:w-1/4">
            <SidebarNavSection
              title={$.settings_navlink}
              screensMap={SETTINGS_SCREENS}
              activeScreen={activeScreen}
              onClick={onNavClick}
              onSubsectionClick={onSubsectionClick}
              activeSubsection={activeSubsection}
            />
          </div>
          <div className="md:w-3/4">
            <div className="rounded-xl bg-white p-3.5 md:p-5">
              <div className="mb-3 text-2xl font-bold text-midnight sm:mb-6">
                {pageSubtitle}
              </div>
              <div className="w-full">
                {{
                  contact: (
                    <ContactInformation
                      talentData={talentData}
                      setTalentData={setTalentData}
                      onUpdate={() => displayToast("Contact information")}
                      showNavPrompt={showNavPrompt}
                      setShowNavPrompt={setShowNavPrompt}
                    />
                  ),
                  credentials: (
                    <LoginCredentials
                      talentData={talentData}
                      onUpdate={() => displayToast("Login credentials")}
                      setShowNavPrompt={setShowNavPrompt}
                    />
                  ),
                  profile_settings: {
                    seo: (
                      <SearchEngineOptimization
                        talentData={talentData}
                        setTalentData={setTalentData}
                        onUpdate={() => displayToast("SEO preferences")}
                        setShowNavPrompt={setShowNavPrompt}
                      />
                    ),
                    employment: (
                      <EmploymentStatus
                        talentData={talentData}
                        setTalentData={setTalentData}
                        onUpdate={() => displayToast("Employment status")}
                        setShowNavPrompt={setShowNavPrompt}
                      />
                    ),
                    opportunity: (
                      <OpportunityPreferences
                        talentData={talentData}
                        setTalentData={setTalentData}
                        onUpdate={() => displayToast("Opportunity preferences")}
                        setShowNavPrompt={setShowNavPrompt}
                      />
                    ),
                    availability: (
                      <Availability
                        talentData={talentData}
                        setTalentData={setTalentData}
                        onUpdate={() => displayToast("Availability")}
                        setShowNavPrompt={setShowNavPrompt}
                      />
                    ),
                    experience: (
                      <Experience
                        talentData={talentData}
                        setTalentData={setTalentData}
                        onUpdate={() => displayToast("Experience")}
                        setShowNavPrompt={setShowNavPrompt}
                      />
                    )
                  }[activeSubsection]
                }[activeScreen] || <div />}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
      <Toaster
        toastOptions={{
          style: {
            border: "1px solid ##e5f2ea",
            borderRadius: "5px",
            backgroundColor: "#e5f2ea",
            maxWidth: 500
          }
        }}
      />
      <NavigationPrompt
        when={(currentLocation, nextLocation) => {
          const promptIf =
            showNavPrompt &&
            (currentLocation.pathname !== nextLocation.pathname ||
              currentLocation.search !== nextLocation.search);
          return promptIf;
        }}
      >
        {({ onConfirm, onCancel }) => (
          <Modal
            when={true}
            title="Discard Changes"
            onClose={onCancel}
            onClickCancel={onCancel}
            onClickSave={() => {
              onConfirm();
              setShowNavPrompt(false);
            }}
            saveBtnText="Discard Changes"
            showDelete={false}
            id="discard_changes_form--settings"
          >
            <div className="flex flex-col gap-4 p-2">
              <div className="text-base">
                {$.discard_changes_settings_message_1}
              </div>
              <div className="text-base">{$.discard_changes_message_2}</div>
            </div>
          </Modal>
        )}
      </NavigationPrompt>
    </div>
  );
};

export default SettingsPage;
