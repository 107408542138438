import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { admin_login as $ } from "strings";
import $$ from "strings/talent";
import { GoogleLogin } from "@react-oauth/google";
import { oauth } from "utils/adminApi";
import { loginPassword } from "utils/api";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import { Input } from "components/Form";
import { saveLoginToken } from "../../utils/localStorageService";
import { useHistory } from "react-router-dom";
import { Button, FormErrorMessage } from "./components";
import * as yup from "yup";
import { Form, Formik } from "formik";
import AuthLayout from "./components/AuthLayout";

const Login = ({ setLogged, setAdminLogged }) => {
  useEffect(() => {
    document.title = "Login";
  }, []);

  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();

  const handleLoginResponse = (response) => {
    saveLoginToken(response);
    if (location?.state?.from) {
      window.location.href = location.state.from;
    } else {
      if (response.user) {
        setAdminLogged(true);
        window.location.href = `/admin`;
      } else if (response.talent) {
        setLogged(true);
        if (!response.talent.skills_survey_submitted_at) {
          window.location.href = `/talent/onboard?user=${response.talent.user.id}`;
        } else {
          window.location.href = `/talent/${response.talent.url_suffix}`;
        }
    }
    }
  };

  const handleLoginError = (e) => {
    alert.error(<AlertError message={$$.invalid_credentials_copy} />);
  };

  const handleGoogleLogin = async (response) => {
    await oauth(response.credential)
      .then(handleLoginResponse)
      .catch(handleLoginError);
  };

  const handleGoogleError = (response) => {
    alert.error(<AlertError message={$.google_auth_error} />);
  };

  const initialValues = {
    email: "",
    password: ""
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email")
      .required("This field is required"),
    password: yup.string().required("This field is required")
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={() => {}}
      enableReinitialize={true}
    >
      {({
        setFieldValue,
        values,
        touched,
        setTouched,
        setFieldTouched,
        errors,
        setFieldError,
        validateForm
      }) => {
        const onChange = async (e) => {
          const { name, value } = e.target;
          await setFieldValue(name, value);
          await setFieldTouched(name, !!value);
          if (errors[name]) {
            setFieldError(name, null);
          }
        };

        const handlePasswordLogin = async () => {
          const errors = await validateForm();
          if (Object.keys(errors).length) {
            return;
          }
          loginPassword(values)
            .then(handleLoginResponse)
            .catch(handleLoginError);
        };

        return (
          <Form className="form h-full">
            <AuthLayout>
              <div className="flex flex-1 flex-col items-center justify-center gap-y-4 md:h-full md:gap-y-5">
                <h1 className="mb-2.5 text-center text-3xl font-semibold md:text-left">
                  Welcome back to Right Side Up!
                </h1>
                <div className="flex w-full justify-center">
                  <GoogleLogin
                    className="w-full"
                    onSuccess={handleGoogleLogin}
                    onError={handleGoogleError}
                    context="signin"
                    size="large"
                  />
                </div>
                <div className="mx-5 flex w-full items-center">
                  <hr className="w-auto flex-auto border-t text-gray-300" />
                  <span className="mx-4 flex-none text-sm font-normal text-gray-300">
                    OR
                  </span>
                  <hr className="w-auto flex-auto border-t text-gray-300" />
                </div>
                <div className="-mb-3 w-full">
                  <label className="w-full text-left text-sm">Email</label>
                  <Input
                    type="email"
                    fontSize="text-sm"
                    value={values.email}
                    onChange={(e) =>
                      onChange({
                        target: { name: "email", value: e.target.value }
                      })
                    }
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handlePasswordLogin().then();
                      }
                    }}
                  />
                  <FormErrorMessage error={errors.email} />
                </div>
                <div className="w-full">
                  <label className="w-full text-left text-sm">Password</label>
                  <Input
                    type="password"
                    fontSize="text-sm"
                    value={values.password}
                    onChange={(e) =>
                      onChange({
                        target: { name: "password", value: e.target.value }
                      })
                    }
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handlePasswordLogin().then();
                      }
                    }}
                  />
                  <div className="mt-2.5 flex flex-row">
                    <FormErrorMessage error={errors.password} />
                    <p className="w-full text-right text-sm text-kasmir">
                      <button
                        type="button"
                        onClick={() => history.push("/talent/reset-password")}
                        className="text-electric-indigo underline hover:text-electric-indigo-lighter"
                      >
                        Forgot your password?
                      </button>
                    </p>
                  </div>
                </div>
                <Button className="w-full" onClick={handlePasswordLogin} large>
                  Log in
                </Button>
                <p className="w-full py-2 text-center text-sm text-kasmir md:py-0">
                  New to RightSideUp?{" "}
                  <button
                    type="button"
                    onClick={() => {
                      window.open(
                        "https://www.rightsideup.com/for-talent",
                        "_blank"
                      );
                    }}
                    className="font-bold text-electric-indigo underline hover:text-electric-indigo-lighter"
                  >
                    Contact our Talent Team!
                  </button>
                </p>
              </div>
            </AuthLayout>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Login;
