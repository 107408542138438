import React from "react";
import clsx from "clsx";
import $ from "strings/talent";
import {
  Label,
  Sublabel
} from "components/Form";
import { Error } from "../../components/index";
import { SPECIALTY_OPTIONS } from "../constants";
import {
  AffiliateIcon,
  AnalyticsIcon,
  BrandMarketingIcon,
  ContentMarketingIcon,
  DesignIcon,
  EcommerceIcon,
  LeadershipIcon,
  LifecycleCrmIcon,
  MarketingOpsIcon,
  PerformanceMarketingIcon,
  ProductMarketingIcon,
  OfflineIcon
} from "components/icons/index";

const SpecialtyOption = ({ option, onClick, isSelected, isDisabled }) => {
  const getIcon = (value) => {
    switch (value) {
      case 'affiliate':
        return <AffiliateIcon isSelected={isSelected} />
      case 'analytics':
        return <AnalyticsIcon isSelected={isSelected} />
      case 'content_marketing':
        return <ContentMarketingIcon isSelected={isSelected} />
      case 'design':
        return <DesignIcon isSelected={isSelected} />
      case 'ecommerce':
        return <EcommerceIcon isSelected={isSelected} />
      case 'leadership':
        return <LeadershipIcon isSelected={isSelected} />
      case 'lifecycle_crm':
        return <LifecycleCrmIcon isSelected={isSelected} />
      case 'marketing_ops':
        return <MarketingOpsIcon isSelected={isSelected} />
      case 'performance_marketing':
        return <PerformanceMarketingIcon isSelected={isSelected} />
      case 'product_marketing':
        return <ProductMarketingIcon isSelected={isSelected} />
      case 'brand_marketing': 
        return <BrandMarketingIcon isSelected={isSelected} />
      case 'offline':
        return <OfflineIcon isSelected={isSelected} />
    }
  }

  return (
    <button
      key={option.label}
      className={clsx(
        "flex flex-col items-center justify-center h-28 gap-1 py-4 px-2 bg-white rounded-xl ",
        isSelected && "text-electric-indigo",
        isDisabled && "cursor-pointer opacity-75"
      )}
      style={{outline: isSelected ? '2px solid #3049c5' : ''}}
      onClick={isDisabled ? () => {return} : onClick}
    >
      {getIcon(option.value)}
      <div className="w-full h-1/2 flex pt-2 sm:pt-1 align-center justify-center text-center text-xs sm:text-sm">
        {option.label}
      </div>
    </button>
  )
}

const Specialties = ({
  setFieldValue,
  values,
  errors
}) => {
  const onClick = (option) => {
    const currentValues = values['specialties'];
    let currentValuesArr = currentValues?.length ? currentValues.split('|') : [];
    if (currentValuesArr.indexOf(option) === -1) {
      currentValuesArr.push(option);
    } else if (currentValuesArr.indexOf(option) > -1) {
      currentValuesArr.splice(currentValuesArr.indexOf(option), 1)
    }
    const newValues = currentValuesArr.sort().join('|')
    setFieldValue('specialties', newValues);
  }

  return (
    <div className="px-8 py-4 grow">
      <div className="py-2 mb-auto">
        <div className="text-darkest-navy text-2xl sm:text-3xl font-bold py-2">{$.specialties_title}</div>
        <div className="text-midnight text-base sm:text-lg font-normal">{$.expertise_subtitle}</div>
      </div>
      <div className="flex flex-col">
        <div className="py-px">
          <Label className="font-normal text-darkest-navy text-sm sm:text-base">{$.specialties_label}</Label>
          <Sublabel>{$.select_three_sublabel}</Sublabel>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 py-2">
          {SPECIALTY_OPTIONS.map(option => 
            <SpecialtyOption
              key={option.value}
              option={option}
              onClick={() => onClick(option.value)}
              isSelected={values['specialties']?.includes(option.value) ? true : false}
              isDisabled={values['specialties']?.split('|').length >= 3 && !values['specialties']?.includes(option.value)}
            />
          )}
        </div>
        {errors.specialties ? <Error msg={errors.specialties} /> : <div style={{height: '22px'}} />}
      </div>
    </div>
  )
};

export default Specialties;
