import React, { useEffect, useState } from "react";
import $ from "strings/talent";
import { Input } from "components/Form";
import {
  AvailabilityCalculator,
  FormErrorMessage,
  Label,
  SettingsActionBar,
  Sublabel
} from "../components";
import { Form, Formik, useFormikContext } from "formik";
import { patchTalentTalent } from "../../../utils/api";
import AlertError from "../../../components/AlertError";
import * as yup from "yup";
import DatePicker from "../../../components/DatePicker";
import { useAlert } from "react-alert";
import { Checkbox, Error } from "components/Form";

const WORKING_TIMEZONES_OPTIONS = [
  { label: $.est_option, value: "eastern" },
  { label: $.cst_option, value: "central" },
  { label: $.mst_option, value: "mountain" },
  { label: $.pst_option, value: "pacific" }
];

const Availability = ({
  talentData,
  setTalentData,
  onUpdate,
  setShowNavPrompt
}) => {
  const [userId, setUserId] = useState();
  const [initialValues, setInitialValues] = useState({
    hoursAvailable: 0,
    hoursExpirationDate: 0
  });
  const [isUpdating, setIsUpdating] = useState(false);

  const PromptUpdater = () => {
    const { dirty } = useFormikContext();
    useEffect(() => {
      const pageUrl = window.location.href;
      if (typeof dirty !== "undefined") {
        if (
          dirty === true &&
          pageUrl.includes("tab=profile_settings:availability")
        ) {
          setShowNavPrompt(true);
        } else {
          setShowNavPrompt(false);
        }
      }
    }, [dirty]);

    return null;
  };

  const getDefaultHoursExpirationDate = () => {
    let d = new Date();
    d.setDate(d.getDate() + 60);

    return Date.parse(d);
  }

  useEffect(() => {
    if (!talentData) {
      return;
    }
    setInitialValues({
      hoursAvailable: talentData.availability_capacity,
      hoursExpirationDate: getDefaultHoursExpirationDate(),
      workingTimezones: talentData.working_timezones
    });
    setUserId(talentData.user?.id);
  }, [talentData]);

  const alert = useAlert();

  const updateTalent = async (values) => {
    const data = {
      availability_capacity: values.hoursAvailable,
      availability_expiration_date: new Date(values.hoursExpirationDate),
      working_timezones: values.workingTimezones
    };
    try {
      const response = await patchTalentTalent(userId, data);
      setTalentData(response);
      setInitialValues(values);
      onUpdate();
    } catch (e) {
      console.error({ e, data });
      alert.error(
        <AlertError error="Something went wrong. Please try again, or contact us if the issue continues." />
      );
    }
  };

  const validationSchema = yup.object().shape({
    hoursAvailable: yup
      .number()
      .required("Available hours required.")
      .min(0, "Hours cannot be less than zero.")
      .max(40, "Max hours reached! Please enter 40 hours or fewer per week."),
    hoursExpirationDate: yup // unix timestamp
      .number()
      .test("is-valid-date", "Invalid date", (value) => {
        try {
          new Date(value).toISOString();
          return true;
        } catch (e) {
          return false;
        }
      })
      .required("Reminder date required")
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={() => {}}
      enableReinitialize={true}
    >
      {({
        setFieldValue,
        values,
        touched,
        setTouched,
        setFieldTouched,
        errors,
        setFieldError,
        validateForm
      }) => {
        const onChange = async (e) => {
          let { name, value } = e.target;
          if (name === "hoursAvailable" && value && typeof value === "string") {
            value = parseInt(value);
          }
          if (
            name === "hoursExpirationDate" &&
            value &&
            value instanceof Date
          ) {
            value = Math.round(value);
          }
          await setFieldValue(name, value);
          await setFieldTouched(name, value !== initialValues[name]);
          if (errors[name]) {
            setFieldError(name, null);
          }
        };

        const onCheckboxClick = async (fieldName, option, value) => {
          const currentValues = values[fieldName];
          let currentValuesArr = currentValues?.length
            ? currentValues.split("|")
            : [];
          if (value === true && currentValuesArr.indexOf(option) === -1) {
            currentValuesArr.push(option);
            if (errors[fieldName]) {
              setFieldError(fieldName, null);
            }
          } else if (value === false && currentValuesArr.indexOf(option) > -1) {
            currentValuesArr.splice(currentValuesArr.indexOf(option), 1);
          }
          const newValues = currentValuesArr.sort().join("|");
          await setFieldValue(fieldName, newValues);
          await setFieldTouched(
            fieldName,
            newValues !== initialValues[fieldName]
          );
        };

        return (
          <Form className="form">
            <div className="mb-2">
              <Label>{$.availability_label}</Label>
              <Sublabel>{$.availability_sublabel}</Sublabel>
              <div className="mt-2 w-1/2">
                <Input
                  type="number"
                  name="hoursAvailable"
                  maxLength="100"
                  placeholder={$.amount_placeholder}
                  onChange={onChange}
                  value={values.hoursAvailable}
                  error={errors.hoursAvailable}
                  fontSize={"text-sm sm:text-base"}
                  disabled={isUpdating}
                />
              </div>
              <FormErrorMessage error={errors.hoursAvailable} />
              <AvailabilityCalculator hoursAvailable={values.hoursAvailable} hoursUtilized={talentData.hoursUtilized} showLoader={touched.hoursAvailable} />
            </div>
            <div className="mb-2">
              <Label>{$.availability_expiration_date_label}</Label>
              <Sublabel>{$.availability_expiration_date_sublabel}</Sublabel>
              <DatePicker
                isV2
                className="w-full sm:w-1/2"
                placeholderText={$.availability_expiration_date_placeholder}
                value={
                  values.hoursExpirationDate
                    ? new Date(values.hoursExpirationDate)
                    : null
                }
                format="MMMM dd, yyyy"
                monthly={false}
                isClearable={false}
                onChange={(v) =>
                  onChange({
                    target: { name: "hoursExpirationDate", value: v }
                  })
                }
                disabled={isUpdating}
              />
              <FormErrorMessage error={errors.hoursExpirationDate} />
            </div>
            <div className="my-1">
              <Label className="text-sm font-normal text-darkest-navy sm:text-base">
                {$.working_timezones_label}
              </Label>
              <Sublabel>{$.select_all_sublabel}</Sublabel>
              <div className="my-px">
                {WORKING_TIMEZONES_OPTIONS.map((option) => (
                  <Checkbox
                    outlined
                    key={option.value}
                    value={
                      values["workingTimezones"]?.includes(option.value)
                        ? true
                        : false
                    }
                    onChange={(v) =>
                      onCheckboxClick("workingTimezones", option.value, v)
                    }
                  >
                    <div className="font-sm my-1 ml-1 font-lato text-darkest-navy">
                      {option.label}
                    </div>
                  </Checkbox>
                ))}
                {!touched.workingTimezones && errors.workingTimezones ? (
                  <Error msg={errors.workingTimezones} />
                ) : (
                  <div style={{ height: "22px" }} />
                )}
              </div>
            </div>
            {Object.values(touched).some(Boolean) && (
              <SettingsActionBar
                cancelDisabled={isUpdating}
                saveDisabled={isUpdating}
                onCancel={async () => {
                  for (let [key, value] of Object.entries(initialValues)) {
                    await setFieldValue(key, value);
                  }
                  await setTouched({});
                }}
                onSave={async () => {
                  setIsUpdating(true);
                  const errors = await validateForm();
                  if (!Object.keys(errors).length) {
                    await updateTalent(values);
                  }
                  setIsUpdating(false);
                }}
              />
            )}
            <PromptUpdater />
          </Form>
        );
      }}
    </Formik>
  );
};

export default Availability;
