import React from "react";

const HideIcon = ({ fill = "#54698D", size = "18" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1626_23141)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.806587 8.594C2.82195 5.16708 6.11051 3.12119 9.60247 3.12119C10.8343 3.12119 12.0408 3.37586 13.175 3.85596L15.9177 1.11328C16.1677 0.863282 16.5768 0.863282 16.8268 1.11328C17.0768 1.36327 17.0768 1.77235 16.8268 2.02235L14.3815 4.46768L12.6246 6.22458L11.7158 7.13329L7.42116 11.4279L6.51241 12.3367L4.73874 14.1103L1.96221 16.8869C1.71221 17.1369 1.30313 17.1369 1.05314 16.8869C0.803142 16.6369 0.803142 16.2278 1.05314 15.9778L3.66774 13.3632C2.56387 12.488 1.58927 11.3658 0.806587 10.0352C0.532896 9.57057 0.532896 9.05877 0.806587 8.594ZM11.5678 5.46323C10.9778 5.16082 10.3097 4.99003 9.60247 4.99003C7.21789 4.99003 5.27797 6.93013 5.27797 9.3147C5.27797 10.0219 5.44879 10.6899 5.75114 11.2799L6.72859 10.3024C6.62178 9.99262 6.56374 9.66029 6.56374 9.31467C6.56374 7.63902 7.92699 6.27573 9.60247 6.27573C9.94813 6.27573 10.2805 6.33381 10.5903 6.44065L11.5678 5.46323ZM15.4611 5.20606L13.407 7.26021C13.7385 7.87172 13.927 8.57161 13.927 9.3147C13.927 11.6991 11.9871 13.6392 9.6025 13.6392C8.85941 13.6392 8.15955 13.4507 7.54804 13.1192L5.9349 14.7323C7.09667 15.2387 8.33614 15.508 9.6025 15.508C13.0945 15.508 16.3827 13.4621 18.3984 10.0352C18.6719 9.57057 18.6719 9.05877 18.3984 8.59397C17.5978 7.23282 16.5963 6.08982 15.4611 5.20606ZM12.4399 8.22729L8.51509 12.1521C8.8529 12.282 9.21948 12.3534 9.60247 12.3534C11.2779 12.3534 12.6412 10.9901 12.6412 9.3147C12.6412 8.93168 12.5698 8.5651 12.4399 8.22729Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1626_23141">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.602295)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HideIcon;
