import React, { useRef, useEffect, useState } from "react";
import clsx from "clsx";
import EmptyProjectState from "./EmptyProjectState";
import { ProjectListItem } from "./ProjectListItem";
import { statusGridConfig, getColSpanClass } from "./gridConfig";
import LoadPlaceholder from "components/LoadPlaceholder";
import { patchReviews } from "utils/adminApi";

const useHasScrollbar = ({ ref, loading, status }) => {
  const [hasScrollbar, setHasScrollbar] = useState(false);

  useEffect(() => {
    const checkForScrollbar = () => {
      if (ref.current) {
        const { clientHeight, scrollHeight } = ref.current;
        // Add a small buffer (1px) to account for potential rounding
        setHasScrollbar(scrollHeight > clientHeight + 1);
      }
    };

    // Initial check
    checkForScrollbar();

    // Check after a short delay to ensure content is rendered
    const timeoutId = setTimeout(checkForScrollbar, 100);

    // Re-check when content changes
    const resizeObserver = new ResizeObserver(() => {
      // Add a small delay to ensure accurate measurements
      setTimeout(checkForScrollbar, 0);
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      resizeObserver.disconnect();
      clearTimeout(timeoutId);
    };
  }, [loading, ref, status]); // check when any of these change

  return hasScrollbar;
};

/**
 * The header of the ProjectList. It displays columns like Project Name, Status, etc.
 * The columns are determined by the statusGridConfig to match the columns in the table.
 */
const HeaderRow = ({ status, hasScrollbar }) => {
  const colSpan = statusGridConfig[status].cols.reduce(
    (acc, col) => acc + col.colspan,
    0
  );

  return (
    <div
      className={clsx(
        "mb-3 grid w-full gap-4 px-2.5 py-2.5",
        // Add padding-right when scrollbar is present
        hasScrollbar && "pr-6"
      )}
      style={{ gridTemplateColumns: `repeat(${colSpan}, minmax(0, 1fr))` }}
    >
      {statusGridConfig[status].cols.map((col) => (
        <div
          className={clsx("text-sm", getColSpanClass(col.colspan))}
          key={col.label}
        >
          {col.label}
        </div>
      ))}
    </div>
  );
};

/**
 * List of projects for a given status. The status is used to determine which table content to display.
 * See the statusGridConfig for more information.
 */
export const ProjectList = ({
  projects,
  status,
  loading,
  onSelectProject,
  refresh
}) => {
  const contentRef = useRef(null);
  const hasScrollbar = useHasScrollbar({ ref: contentRef, loading, status });

  if (loading) {
    return (
      <div className="flex flex-col gap-4 px-6 pt-6">
        <LoadPlaceholder className="h-24 rounded-md" />
        <LoadPlaceholder className="h-24 rounded-md" />
        <LoadPlaceholder className="h-24 rounded-md" />
      </div>
    );
  }
  if (!projects || projects.length === 0) {
    return <EmptyProjectState status={status} />;
  }

  const onUpdateReview = async (id, params) => {
    await patchReviews(id, params);
    refresh();
  };

  return (
    <>
      <HeaderRow status={status} hasScrollbar={hasScrollbar} />
      <div
        ref={contentRef}
        className="flex flex-col gap-1.5 overflow-y-auto pb-4 text-midnight"
      >
        {projects.map((project) => (
          <ProjectListItem
            status={status}
            project={project}
            key={project.id}
            onSelectProject={onSelectProject}
            onUpdateReview={onUpdateReview}
          />
        ))}
      </div>
    </>
  );
};
