import React from "react";

const EnvelopeCheckedIcon = ({ fill = "#54698D" }) => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.01062 8.74248C7.01062 7.41873 8.08763 6.34173 9.41138 6.34173C9.95362 6.34173 10.4528 6.52436 10.8551 6.82886V4.58523C10.8551 3.38936 9.88575 2.41998 8.68988 2.41998H2.91525C1.71938 2.41923 0.75 3.38898 0.75 4.58486V8.02098C0.75 9.21686 1.71938 10.1862 2.91525 10.1862H7.49813C7.19363 9.78386 7.01062 9.28473 7.01062 8.74248ZM5.80275 6.32261C5.64787 6.32261 5.49263 6.29298 5.34488 6.23411L2.25112 4.99848C2.07787 4.92911 1.9935 4.73298 2.06288 4.55973C2.13225 4.38648 2.32913 4.30286 2.50163 4.37148L5.59538 5.60711C5.7285 5.66073 5.87662 5.66073 6.00975 5.60711L9.10388 4.37148C9.27713 4.30323 9.47363 4.38648 9.54263 4.55973C9.612 4.73298 9.52725 4.92911 9.35437 4.99848L6.26025 6.23411C6.11287 6.29336 5.95762 6.32261 5.80275 6.32261Z"
        fill={fill}
      />
      <path
        d="M9.41174 6.90387C8.39661 6.90387 7.57349 7.72699 7.57349 8.74212C7.57349 9.75724 8.39661 10.5804 9.41174 10.5804C10.4269 10.5804 11.25 9.75724 11.25 8.74212C11.25 7.72699 10.4269 6.90387 9.41174 6.90387ZM10.2484 8.69225L9.35061 9.41524C9.29024 9.46362 9.21561 9.48987 9.13911 9.48987C9.12636 9.48987 9.11361 9.48912 9.10049 9.48762C9.01086 9.47749 8.92911 9.43174 8.87361 9.36049L8.52111 8.91087C8.40599 8.76425 8.43186 8.55199 8.57811 8.43724C8.72511 8.32174 8.93699 8.34762 9.05211 8.49462L9.19386 8.67499L9.82536 8.16649C9.97086 8.04912 10.1835 8.07312 10.3001 8.21787C10.4164 8.36262 10.3935 8.57525 10.2484 8.69225Z"
        fill={fill}
      />
    </svg>
  );
};

export default EnvelopeCheckedIcon;
