import React from "react";

const ThumbsUpIcon = ({
  isSelected
}) => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_6389)">
        <path d="M0.711755 11C0.318659 11 0 11.3488 0 11.779V23.221C0 23.6512 0.318706 24 0.711755 24H5V11H0.711755Z" fill={isSelected ? "#3049c5" : "#E8EBF8"} />
        <path d="M26 15.6704C26 14.6941 25.4764 13.8379 24.6959 13.369C24.995 12.9363 25.1704 12.4113 25.1704 11.8463C25.1704 10.3679 23.97 9.1651 22.4946 9.1651H16.8462C17.0193 8.37901 17.2561 7.19941 17.4209 5.96457C17.85 2.75108 17.5568 0.96891 16.4983 0.356031C15.8383 -0.0259964 15.1268 -0.103489 14.4948 0.137621C14.0065 0.323988 13.3471 0.782187 12.9713 1.90674L11.4867 5.80171C10.7341 7.66421 8.42936 9.61954 7 10.7007V24.5984C9.64912 25.5285 12.4135 26 15.2337 26H21.3883C22.8637 26 24.064 24.7972 24.064 23.3188C24.064 22.8011 23.9168 22.3171 23.6621 21.9069C24.5541 21.4716 25.1703 20.5538 25.1703 19.4946C25.1703 18.9295 24.9949 18.4047 24.6958 17.9719C25.4764 17.503 26 16.6468 26 15.6704Z" fill={isSelected ? "#3049c5" : "#E8EBF8"} />
      </g>
      <defs>
        <clipPath id="clip0_1_6389">
          <rect width="26" height="26" fill={isSelected ? "#3049c5" : "#E8EBF8"} />
        </clipPath>
      </defs>
    </svg>
  )
};

export default ThumbsUpIcon;
