import React, { useMemo } from "react";
import clsx from "clsx";
import GenericNavigationImage from '../assets/navigation_generic.png';
import { navigationMappings } from "./FormMappings";
import Logo from "components/Logo";
import { CompletedCheckIcon, ErrorIcon } from "components/icons";
import { PhoneNumberUtil } from 'google-libphonenumber';
import { isEmpty } from 'lodash';
import EndImpersonationButton from "../components/EndImpersonationButton";
import {getImpersonatorAdminToken} from "../../../utils/localStorageService";

const NavItem = ({ step, activeStep, values, onClick, sectionHasErrors }) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  const navData = navigationMappings[step];
  const isSectionActive = navData['steps'].indexOf(activeStep) > -1;


  const sectionIsComplete = useMemo(() => {
    if (sectionHasErrors) return false;
    let isComplete = true;
    if (!values) return false;
    const requiredFields = navData.requiredFields;
    requiredFields.forEach(field => {
      // special condition for phone validation
      if (field === 'phone') {
        if (values.phone.length < 7) {
          isComplete = false;
        } else if (!phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(values.phone))) {
          isComplete = false;
        }
      }
      // special condition for objects
      if (typeof values[field] === 'object' && isEmpty(values[field])) {
        isComplete = false;
      }
      // handle all other incomplete values
      if ((!values[field] && values[field] !== false)) {
        isComplete = false;
      }
    })
    return isComplete;
  }, [step, values, sectionHasErrors]);


  const onSectionClick = () => {
    if (isSectionActive) return;
    onClick(navigationMappings[step]['steps'][0]);
  }

  return (
    <div className={clsx("flex flex-col justify-center w-full sm:px-4 sm:py-2 ", isSectionActive ? "flex-3 sm:flex-1" : "flex-1")}>
      <div
        className={clsx("flex cursor-pointer items-center", (isSectionActive && !navData.screens) && "sm:bg-lightest-grey sm:rounded-lg ", isSectionActive && " text-electric-indigo ", sectionHasErrors && "text-red")}
        onClick={onSectionClick}
      >
        <div className="h-full bg-lightest-grey sm:bg-transparent rounded-lg p-2">
          {sectionHasErrors ?
            <ErrorIcon /> :
          sectionIsComplete ?
            <CompletedCheckIcon /> :
          isSectionActive ?
            navigationMappings[step].activeIcon :
            navigationMappings[step].icon
          }
        </div>
        <div className={clsx("ml-2 text-sm sm:text-base font-semibold py-2", isSectionActive ? "text-electric-indigo " : sectionHasErrors ? "hidden sm:flex sm:text-red" : "hidden sm:flex sm:text-kasmir")}>
          {navigationMappings[step].title}
        </div>
      </div>
      {(isSectionActive && navData.screens) &&
        <div className="screenStep hidden sm:flex sm:flex-col sm:my-2">
          {Object.keys(navData.screens).map(screen =>
            <div
              key={screen}
              className="cursor-pointer"
              onClick={() => onClick(navData.screens[screen].step)}
            >
              <div className={
                clsx("flex ml-8 py-2 font-medium text-sm text-kasmir ",
                activeStep === navData.screens[screen].step && "bg-lightest-grey rounded-lg text-electric-indigo")
              }>
                <div className="pl-2">{navData.screens[screen].title}</div>
              </div>
            </div>
          )}
        </div>
      }
    </div>
  )
}
const Navigation = ({ activeStep, onClickNavItem, values, errors, stepsWithErrors }) => {
  const isImpersonating = !!getImpersonatorAdminToken();

  return (
    <div className="navigation z-10 sticky top-0 sm:flex-1 flex flex-col bg-alice-blue max-h-20 sm:h-full sm:max-h-full justify-center p-2 sm:p-4 sm:pr-0">
      <div className="bg-white rounded-xl h-full">
        <div className="flex-2 flex sm:flex-col justify-between sm:justify-start">
          <div className="w-1/6 sm:w-full h-16 sm:h-20 p-4 flex justify-between">
            <Logo className="w-11" />
            {isImpersonating &&
              <div className="hidden sm:flex">
                <EndImpersonationButton className="my-auto" />
              </div>
            }
          </div>
          <div className="w-5/6 sm:w-full m-2 flex sm:flex-col gap-2 sm:mt-4 sm:mx-2 sm:z-10">
            {Object.keys(navigationMappings).map(navStep =>
              <NavItem
                key={navStep}
                step={navStep}
                activeStep={activeStep}
                errors={errors}
                values={values}
                onClick={onClickNavItem}
                sectionHasErrors={navigationMappings[navStep].steps?.some(step => stepsWithErrors.indexOf(step) > -1)}
              />
            )}
          </div>
        </div>
        <div className="hidden sm:flex sm:absolute sm:left-2 sm:bottom-4 h-1/3 max-h-1/3 w-full max-w-full overflow-x-hidden justify-center">
          <img className="navImg h-full" alt="RSU icon" src={GenericNavigationImage} />
        </div>
      </div>
    </div>
  )
};

export default Navigation;
