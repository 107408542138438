import React, { useEffect, useState } from "react";
import Section from "components/Section";
import { CompanyExperienceListItem } from "components/ExperiencesListItems";
import { orderBy } from "lodash";

const Experiences = ({ data }) => {
  const [talentExperiences, setTalentExperiences] = useState();

  useEffect(() => {
    if (!data) return;
    populateTalentExperiences(data.results);
  }, [data]);

  const populateTalentExperiences = (experiences) => {
    const existingProjectIds = {};
    const projects = {};

    const orderedExperiences = orderBy(
      experiences,
      [
        // Projects with no date info last
        (project) => (project.end_date || project.start_date ? 0 : 1),
        // ongoing experiences first
        (project) => (project.end_date ? 1 : 0),
        // then by end date, then by start date - if no end date
        (project) => project.end_date || project.start_date
      ],
      ["asc", "asc", "desc"]
    );
    for (const project of orderedExperiences) {
      existingProjectIds[project.project_id] = true;
      const key = project.company?.name || "Company unknown";
      if (!projects[key]) {
        projects[key] = [];
      }
      projects[key].push({ ...project, managed_by_rsu: !!project.project_id });
    }
    setTalentExperiences(projects);
  };

  return (
    <Section
      id="admin_talent_experiences"
      className="mt-1 flex h-full w-full flex-col overflow-y-auto bg-white"
    >
      <div className="flex flex-col gap-2.5 text-midnight">
        {talentExperiences &&
          Object.entries(talentExperiences).map(
            ([companyName, projects], i) => (
              <CompanyExperienceListItem
                key={i}
                companyName={companyName}
                projects={projects}
                showCompletenessBadge={true}
                compact
              />
            )
          )}
      </div>
    </Section>
  );
};

export default Experiences;
