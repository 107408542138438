import React, { useState } from "react";
import clsx from "clsx";
import { toFormat } from "utils/date";
import { statusGridConfig, getColSpanClass } from "./gridConfig";
import UilDown from "@iconscout/react-unicons/icons/uil-angle-down";
import UilUp from "@iconscout/react-unicons/icons/uil-angle-up";
import { IconBtn } from "components/Buttons";
import EnvelopeCheckedIcon from "components/icons/EnvelopeChecked";
import ProfileIcon from "components/icons/Profile";
import { PointedTooltip, RatingInput } from "pages/talent/components";
import { lastUpdatedFormatted } from "../utils";
import { StarIcon } from "components/icons";
import EnvelopeSentIcon from "components/icons/EnvelopeSent";
import HideIcon from "components/icons/Hide";
import ShowIcon from "components/icons/Show";
import StarLineThroughIcon from "components/icons/StarLineThrough";

export const ProjectListItem = ({
  status,
  project,
  onSelectProject,
  onUpdateReview
}) => {
  const colSpan = statusGridConfig[status].cols.reduce(
    (acc, col) => acc + col.colspan,
    0
  );

  const latestNote = project.notes?.[0];

  return (
    <div
      className={`w-full cursor-pointer rounded-md bg-white px-2.5 py-3`}
      onClick={() => onSelectProject(project)}
    >
      <div className="flex flex-col gap-4">
        <div
          className={`grid w-full gap-4 text-kasmir`}
          style={{ gridTemplateColumns: `repeat(${colSpan}, minmax(0, 1fr))` }}
        >
          {statusGridConfig[status].cols.map((col) => (
            <div
              className={clsx(
                getColSpanClass(col.colspan),
                col.label === "Name" && "font-bold text-midnight"
              )}
              key={col.label}
            >
              {col.value(project)}
            </div>
          ))}
        </div>

        {latestNote &&
          ["candidate", "rejected", "inactive"].includes(status) && (
            <div className="flex flex-col">
              <ProjectListItemNote
                key={latestNote.id}
                type="Latest Note"
                author={latestNote.created_by ?? "Unknown"}
                content={latestNote.body}
                created_at={latestNote.created_at}
              />
            </div>
          )}

        {project.pitch_paragraph &&
          ["completed", "active"].includes(status) && (
            <div className="flex flex-col">
              <ProjectListItemNote
                type="Pitch Paragraph"
                author={project.pitch_paragraph.created_by ?? "Unknown"}
                content={project.pitch_paragraph.body}
                created_at={project.pitch_paragraph.created_at}
              />
            </div>
          )}

        {project.reviews?.map((review, index) => (
          <ProjectReviewListItem
            review={review}
            key={index}
            onUpdateReview={onUpdateReview}
          />
        ))}

        {project.pending_reviews?.map((review, index) => (
          <ProjectReviewListItem
            review={review}
            key={index}
            pending
            onUpdateReview={onUpdateReview}
          />
        ))}
      </div>
    </div>
  );
};

/**
 * A nested component that displays a note for a project.
 */
const ProjectListItemNote = ({ type, author, content, created_at }) => {
  const created_at_formatted = created_at
    ? toFormat(new Date(created_at), "MM/dd/yyyy")
    : undefined;
  return (
    <div className="flex w-full flex-col gap-2 rounded-md bg-lightest-grey p-2.5 text-sm">
      <div className="flex flex-row">
        <p className="font-bold">{type}</p>
        <p className="px-1.5 text-2xs font-bold">•</p>
        <p className="text-kasmir">{author}</p>
        <p className="ml-auto text-grey">{created_at_formatted}</p>
      </div>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

const ReviewTooltip = ({
  professionalismRating,
  dependabilityRating,
  qualityOfWorkRating,
  domainExpertiseRating
}) => {
  const ratings = {
    Professionalism: professionalismRating,
    Dependability: dependabilityRating,
    "Quality of Work": qualityOfWorkRating,
    "Domain Expertise": domainExpertiseRating
  };

  return (
    <div className="flex flex-col gap-1 p-1 text-sm font-medium text-midnight">
      {Object.entries(ratings).map(([label, rating], index) => (
        <div className="flex flex-row items-center" key={index}>
          <div className="mr-auto">{label}</div>
          <StarIcon size="16" isSelected />
          <div className="ml-2">{rating ? rating.toFixed(1) : "0.0"}</div>
        </div>
      ))}
    </div>
  );
};

const ProjectReviewListItem = ({
  review,
  startCollapsed,
  onUpdateReview,
  pending
}) => {
  const [collapsed, setCollapsed] = useState(startCollapsed || pending);
  const {
    public_feedback,
    private_feedback,
    is_recommended,
    engagement_poc_name,
    review_published_at,
    is_hidden,
    is_rating_excluded,
    domain_expertise_rating,
    professionalism_rating,
    dependability_rating,
    quality_of_work_rating
  } = review;

  const score = pending
    ? 0
    : (
        ((professionalism_rating || 0) +
          (dependability_rating || 0) +
          (quality_of_work_rating || 0) +
          (domain_expertise_rating || 0)) /
        4
      ).toFixed(1);

  const publicFeedbackPlaceholder = pending
    ? "No review received."
    : "No public review given.";
  const privateFeedbackPlaceholder = pending
    ? "No review received"
    : "No private review given.";

  const iconFill = pending ? "#C3C3C3" : "#0E1539";

  return (
    <div
      className="flex w-full flex-col gap-2 rounded-md bg-lightest-grey p-2.5 text-sm"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex flex-row items-center gap-1.5">
        <PointedTooltip
          selfManaged
          disabled={pending}
          translate="-translate-x-0"
          msg={
            <ReviewTooltip
              professionalismRating={professionalism_rating}
              dependabilityRating={dependability_rating}
              qualityOfWorkRating={quality_of_work_rating}
              domainExpertiseRating={domain_expertise_rating}
            />
          }
        >
          <div className="flex flex-row items-center gap-1.5">
            <p className="mr-4 font-bold">Review</p>
            {!pending && <p className="">{score}</p>}
            <RatingInput disabled value={score} starSize={18} starGap="gap-1" />
          </div>
        </PointedTooltip>
        {!pending && (
          <div
            className={clsx(
              is_recommended
                ? "bg-alice-blue text-electric-indigo"
                : "bg-gray-200 text-kasmir",
              "rounded-md px-2.5 py-1.5"
            )}
          >
            Would{!is_recommended && " not"} recommend the talent
          </div>
        )}
        <div className="w-1/2 ml-auto flex flex-row items-center justify-between">
          <div className="mr-5 flex flex-row gap-1.5">
            <div className="flex h-5 w-5 items-center rounded-md bg-alice-blue p-1">
              <ProfileIcon />
            </div>

            <div className="font-medium text-kasmir">Reviewer: </div>
            <div className="ml-1 font-semibold text-midnight">
              {engagement_poc_name}
            </div>
          </div>
          <div className="flex flex-row gap-1">
            <PointedTooltip
              disabled={pending}
              selfManaged
              small
              translate="-translate-x-10"
              msg={
                review_published_at
                  ? `Sent on ${lastUpdatedFormatted(review_published_at)}`
                  : "Notify talent and publish review."
              }
            >
              <IconBtn
                className={(pending || !!review.review_published_at) ? "cursor-not-allowed" : ""}
                bgColor="bg-white"
                disabled={pending || !!review.review_published_at}
                onClick={() => {
                  onUpdateReview(review.id, {
                    review_published_at: new Date()
                  });
                }}
              >
                {review_published_at ? (
                  <EnvelopeCheckedIcon fill={iconFill} />
                ) : (
                  <EnvelopeSentIcon fill={iconFill} />
                )}
              </IconBtn>
            </PointedTooltip>
            <PointedTooltip
              disabled={pending}
              selfManaged
              small
              translate="-translate-x-10"
              msg={`${review.is_rating_excluded ? "Include in" : "Exclude from"} talent average rating`}
            >
              <IconBtn
                bgColor="bg-white"
                disabled={pending}
                onClick={() => {
                  onUpdateReview(review.id, {
                    is_rating_excluded: !is_rating_excluded
                  });
                }}
              >
                {is_rating_excluded ? (
                  <StarLineThroughIcon fill={iconFill} size="14" />
                ) : (
                  <StarIcon fill={iconFill} size="12" />
                )}
              </IconBtn>
            </PointedTooltip>
            <PointedTooltip
              disabled={pending}
              selfManaged
              small
              translate="-translate-x-10"
              msg={`${review.is_hidden ? "Show on" : "Hide from"} public profile`}
            >
              <IconBtn
                onClick={() => {
                  onUpdateReview(review.id, { is_hidden: !is_hidden });
                }}
                bgColor="bg-white"
                disabled={pending}
              >
                {is_hidden ? (
                  <HideIcon fill={iconFill} size="13" />
                ) : (
                  <ShowIcon fill={iconFill} size="13" />
                )}
              </IconBtn>
            </PointedTooltip>
            <IconBtn
              bgColor="bg-white"
              onClick={() => setCollapsed(!collapsed)}
            >
              {collapsed ? (
                <UilDown size="16" color="#0E1539" />
              ) : (
                <UilUp size="16" color="#0E1539" />
              )}
            </IconBtn>
          </div>
        </div>
      </div>
      {!collapsed && (
        <div className="flex flex-row gap-4 text-kasmir">
          <div className="w-1/2 rounded-md bg-white p-2.5">
            <p className="mb-2 font-bold text-midnight">Private feedback</p>
            <div
               className="whitespace-pre-wrap"
              dangerouslySetInnerHTML={{
                __html: private_feedback || privateFeedbackPlaceholder
              }}
            />
          </div>
          <div className="w-1/2 rounded-md bg-white p-2.5">
            <p className="mb-2 font-bold text-midnight">Public feedback</p>
            <div
              className="whitespace-pre-wrap"
              dangerouslySetInnerHTML={{
                __html: public_feedback || publicFeedbackPlaceholder
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
