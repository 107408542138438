import React from "react";

const EnvelopeSentIcon = ({ fill = "#54698D" }) => {
  return (
    <svg
      width="14"
      height="9"
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.67939 4.32534L12.7 1.51917C12.3557 0.796555 11.4813 0.253449 10.686 0.253703C10.1889 0.25091 8.8325 0.391828 7.95068 0.573117C7.06633 0.734601 5.75465 1.11191 5.29482 1.30056C4.5585 1.59788 3.94912 2.42866 3.90088 3.22771L8.67939 4.32534Z"
        fill={fill}
      />
      <path
        d="M13.3109 3.53981C13.2239 3.08712 13.1073 2.64064 12.9618 2.20325L9.06279 4.92461C8.98877 4.97605 8.90485 5.01151 8.81637 5.02873C8.72789 5.04596 8.6368 5.04457 8.54889 5.02465L3.91357 3.96028C3.94228 4.42067 4.00114 4.87868 4.08979 5.33137C4.26295 6.2142 4.48537 6.84211 4.67656 7.29838C5.00182 8.04766 5.90039 8.6187 6.71518 8.6187C7.21232 8.62124 8.56869 8.48032 9.45025 8.29928C10.3349 8.13754 11.6465 7.76024 12.1064 7.57184C12.862 7.26715 13.4818 6.40209 13.5029 5.58553C13.5097 5.08864 13.481 4.42391 13.3109 3.53981Z"
        fill={fill}
      />
      <path
        d="M0.982639 6.77067C0.862773 6.77048 0.747271 6.72567 0.658635 6.64498C0.569999 6.56429 0.514577 6.45348 0.503168 6.33416C0.491759 6.21484 0.525179 6.09555 0.596918 5.99952C0.668656 5.90349 0.773575 5.83761 0.891232 5.81471L2.32809 5.53541C2.45285 5.51303 2.58142 5.54066 2.68598 5.61231C2.79054 5.68397 2.86269 5.7939 2.88685 5.91833C2.911 6.04277 2.88521 6.17171 2.81505 6.27728C2.74489 6.38285 2.63599 6.45656 2.51192 6.48248L1.07531 6.76178C1.04478 6.76772 1.01374 6.7707 0.982639 6.77067Z"
        fill={fill}
      />
      <path
        d="M1.72703 4.4312C1.60714 4.43108 1.4916 4.38632 1.40292 4.30564C1.31423 4.22497 1.25876 4.11417 1.24732 3.99483C1.23588 3.87549 1.26928 3.75616 1.34102 3.66011C1.41276 3.56405 1.51769 3.49815 1.63537 3.47525L2.63525 3.28126C2.76002 3.25888 2.88858 3.28651 2.99314 3.35816C3.0977 3.42982 3.16986 3.53975 3.19401 3.66418C3.21817 3.78862 3.19237 3.91756 3.12221 4.02313C3.05205 4.1287 2.94316 4.20241 2.81908 4.22833L1.82047 4.42232C1.78969 4.42833 1.75839 4.43131 1.72703 4.4312Z"
        fill={fill}
      />
      <path
        d="M2.11106 8.7463C1.99119 8.74611 1.87569 8.70131 1.78705 8.62062C1.69842 8.53992 1.643 8.42912 1.63159 8.3098C1.62018 8.19048 1.6536 8.07118 1.72534 7.97516C1.79707 7.87913 1.90199 7.81325 2.01965 7.79035L3.45651 7.51105C3.51909 7.49798 3.58364 7.49747 3.64641 7.50956C3.70919 7.52166 3.76894 7.54611 3.82218 7.58149C3.87543 7.61688 3.92111 7.66249 3.95657 7.71569C3.99203 7.76888 4.01656 7.82859 4.02874 7.89135C4.04092 7.95411 4.04051 8.01867 4.02752 8.08126C4.01454 8.14386 3.98924 8.20325 3.9531 8.25599C3.91696 8.30872 3.8707 8.35375 3.81701 8.38845C3.76331 8.42315 3.70326 8.44683 3.64033 8.45812L2.20373 8.73742C2.1732 8.74336 2.14216 8.74634 2.11106 8.7463Z"
        fill={fill}
      />
    </svg>
  );
};

export default EnvelopeSentIcon;
