import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { SPECIALTY_OPTIONS } from "../onboard/constants";
import {
  AffiliateIcon,
  AnalyticsIcon,
  BrandMarketingIcon,
  ContentMarketingIcon,
  DesignIcon,
  EcommerceIcon,
  LeadershipIcon,
  LifecycleCrmIcon,
  MarketingOpsIcon,
  PerformanceMarketingIcon,
  ProductMarketingIcon,
  OfflineIcon
} from "components/icons/index";
import ActionBar from "./ActionBar";

const SpecialtyOption = ({ option, onClick, isSelected, isDisabled }) => {
  const getIcon = (value) => {
    switch (value) {
      case "affiliate":
        return <AffiliateIcon isSelected={isSelected} />;
      case "analytics":
        return <AnalyticsIcon isSelected={isSelected} />;
      case "content_marketing":
        return <ContentMarketingIcon isSelected={isSelected} />;
      case "design":
        return <DesignIcon isSelected={isSelected} />;
      case "ecommerce":
        return <EcommerceIcon isSelected={isSelected} />;
      case "leadership":
        return <LeadershipIcon isSelected={isSelected} />;
      case "lifecycle_crm":
        return <LifecycleCrmIcon isSelected={isSelected} />;
      case "marketing_ops":
        return <MarketingOpsIcon isSelected={isSelected} />;
      case "performance_marketing":
        return <PerformanceMarketingIcon isSelected={isSelected} />;
      case "product_marketing":
        return <ProductMarketingIcon isSelected={isSelected} />;
      case "brand_marketing":
        return <BrandMarketingIcon isSelected={isSelected} />;
      case "offline":
        return <OfflineIcon isSelected={isSelected} />;
      default:
        return null;
    }
  };

  return (
    <button
      key={option.label}
      className={clsx(
        "flex h-28 flex-col items-center justify-center gap-1 rounded-xl bg-lightest-grey px-2 py-4",
        isSelected && "text-electric-indigo",
        isDisabled && "cursor-pointer opacity-75"
      )}
      style={{ outline: isSelected ? "2px solid #3049c5" : "" }}
      onClick={
        isDisabled
          ? () => {
              return;
            }
          : onClick
      }
    >
      {getIcon(option.value)}
      <div className="align-center flex h-1/2 w-full justify-center pt-2 text-center text-xs sm:pt-1 sm:text-sm">
        {option.label}
      </div>
    </button>
  );
};

const Specialties = ({
  talentData,
  setTalentData,
  onUpdate,
  isUpdating,
  setShowNavPrompt
}) => {
  const { specialties } = talentData;
  const [values, setValues] = useState(specialties);
  const [displayActionBar, setDisplayActionBar] = useState(false);

  const PromptUpdater = () => {
    useEffect(() => {
      const pageUrl = window.location.href;
      if (displayActionBar === true && pageUrl.includes("tab=specialties")) {
        setShowNavPrompt(true);
      } else {
        setShowNavPrompt(false);
      }
    }, [displayActionBar]);

    return null;
  };

  useEffect(() => {
    if (!values) return;
    specialties === values
      ? setDisplayActionBar(false)
      : setDisplayActionBar(true);
  }, [values]);

  const onClick = (option) => {
    const currentValues = values;
    let currentValuesArr = currentValues?.length
      ? currentValues.split("|")
      : [];
    if (currentValuesArr.indexOf(option) === -1) {
      currentValuesArr.push(option);
    } else if (currentValuesArr.indexOf(option) > -1) {
      currentValuesArr.splice(currentValuesArr.indexOf(option), 1);
    }
    const newValues = currentValuesArr.sort().join("|");
    setValues(newValues);
  };

  const onClickCancel = () => {
    setValues(specialties);
  };

  const onClickSave = () => {
    const toSend = { specialties: values };
    const response = onUpdate(toSend);
    if (response) {
      setDisplayActionBar(false);
    }
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="grid grid-cols-2 gap-4 py-2 sm:grid-cols-3 md:grid-cols-4">
          {SPECIALTY_OPTIONS.map((option) => (
            <SpecialtyOption
              key={option.value}
              option={option}
              onClick={() => onClick(option.value)}
              isSelected={values?.includes(option.value) ? true : false}
              isDisabled={
                (values?.split("|").length >= 3 &&
                  !values?.includes(option.value)) ||
                isUpdating
              }
            />
          ))}
        </div>
      </div>
      <ActionBar
        displayActionBar={displayActionBar}
        isUpdating={isUpdating}
        onClickCancel={onClickCancel}
        onClickSave={onClickSave}
      />
      <PromptUpdater />
    </>
  );
};

export default Specialties;
