import React from "react";

const InfoIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.99958 0.5C4.47746 0.5 0 4.97746 0 10.4996C0 16.0217 4.47746 20.5 9.99958 20.5C15.5217 20.5 20 16.0217 20 10.4996C20 4.97746 15.5217 0.5 9.99958 0.5Z" fill="white"/>
        <path d="M9.95198 6.97657C10.4792 6.97657 10.9065 6.54429 10.9065 6.01105C10.9065 5.47781 10.4792 5.04553 9.95198 5.04553C9.4248 5.04553 8.99744 5.47781 8.99744 6.01105C8.99744 6.54429 9.4248 6.97657 9.95198 6.97657Z" fill="#0E1539"/>
        <path d="M12.0453 13.6662H10.6817V8.60353C10.6817 8.35249 10.4782 8.14898 10.2271 8.14898H8.63622C8.45539 8.14898 8.28197 8.22164 8.1541 8.35098C8.02624 8.48031 7.9544 8.65573 7.9544 8.83864C7.9544 9.02154 8.02624 9.19696 8.1541 9.3263C8.28197 9.45563 8.45539 9.52829 8.63622 9.52829H9.31804V13.6662H7.9544C7.77357 13.6662 7.60015 13.7389 7.47228 13.8682C7.34442 13.9976 7.27258 14.173 7.27258 14.3559C7.27258 14.5388 7.34442 14.7142 7.47228 14.8435C7.60015 14.9729 7.77357 15.0455 7.9544 15.0455H12.0453C12.2261 15.0455 12.3996 14.9729 12.5274 14.8435C12.6553 14.7142 12.7271 14.5388 12.7271 14.3559C12.7271 14.173 12.6553 13.9976 12.5274 13.8682C12.3996 13.7389 12.2261 13.6662 12.0453 13.6662Z" fill="#0E1539"/>
    </svg>
  )
};

export default InfoIcon;
