import React, { useMemo, useState, useEffect, useRef } from "react";
import clsx from "clsx";
import $ from "strings/talent"
import { HideBtn, TransparentButton } from "components/Buttons";
import { Modal, Pill, PointedTooltip, RatingInput } from "../components";
import { NavLeftIcon, NavRightIcon, StarIcon } from "components/icons";
import {
  patchCandidateReview
} from "utils/api";
import placeholderImg from "../assets/skills_placeholder.png";

const ReviewTooltip = ({
  professionalismRating,
  dependabilityRating,
  qualityOfWorkRating,
  domainExpertiseRating
}) => {
  const ratings = {
    Professionalism: professionalismRating,
    Dependability: dependabilityRating,
    "Quality of Work": qualityOfWorkRating,
    "Domain Expertise": domainExpertiseRating
  };

  return (
    <div className="flex flex-col gap-1 p-1 text-sm font-medium text-midnight">
      {Object.entries(ratings).map(([label, rating], index) => (
        <div className="flex flex-row items-center" key={index}>
          <div className="mr-auto">{label}</div>
          <StarIcon size="16" isSelected />
          <div className="ml-2">{rating ? rating.toFixed(1) : "0.0"}</div>
        </div>
      ))}
    </div>
  );
};

const ReviewNavigation = ({onClickPrev, onClickNext, prevDisabled, nextDisabled}) => (
  <div className="w-full flex items-center justify-center gap-2">
    <TransparentButton
      disabled={prevDisabled}
      onClick={onClickPrev}
    >
      <NavLeftIcon />
    </TransparentButton>
    <TransparentButton
      disabled={nextDisabled}
      onClick={onClickNext}
    >
      <NavRightIcon />
    </TransparentButton>
  </div>
)
const ReviewDetail = ({review, onClose}) => {
  const averageRating = useMemo(() => {
    const {
      dependability_rating,
      professionalism_rating,
      domain_expertise_rating,
      quality_of_work_rating
    } = review;
    const total = Number(
      dependability_rating +
      professionalism_rating +
      domain_expertise_rating +
      quality_of_work_rating
    )
    return total / 4;
  }, [review]);

  return (
    <Modal
      title='Full Review'
      onClose={onClose}
      onClickCancel={onClose}
      showActionBar={false}
      id="talent_review_read_more"
    >
      <div className="flex-1 min-h-0 overflow-y-auto pt-1.5 pb-2.5 text-midnight text-sm whitespace-pre-wrap">
        <div className="flex flex-col">
          <div className="font-bold">{review.reviewer.first_name} {review.reviewer.last_initial}</div>
          <div className="text-electric-indigo">{review.reviewer.company} {review.reviewer.role ? `- ${review.reviewer.role}` : ''}</div>
        </div>
        <div className="text-sm text-kasmir my-4">{review.public_feedback}</div>
        <div className="flex items-center">
          <div className="text-sm font-bold pr-1">{averageRating}</div>
          <RatingInput
            value={averageRating}
            disabled={true}
          />
          <div className="ml-auto mr-px">
            {(new Date(review.created_at).toLocaleDateString('en-US'))}
          </div>
        </div>
      </div>
    </Modal>
  )
}

const Review = ({review, visible, onClickReadMore, isPublicProfile}) => {
  const [isHidden, setIsHidden] = useState(review.is_hidden);
  const [isTruncated, setIsTruncated] = useState();
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      setIsTruncated(textRef.current.scrollHeight > 160);
    }
  }, [review.public_feedback, visible]);
  
  const averageRating = useMemo(() => {
    const {
      dependability_rating,
      professionalism_rating,
      domain_expertise_rating,
      quality_of_work_rating
    } = review;
    const total = Number(
      dependability_rating +
      professionalism_rating +
      domain_expertise_rating +
      quality_of_work_rating
    )
    return total / 4;
  }, [review]);

  return (
    <div
      key={review.id}
      style={{height: "300px"}}
      className={clsx("rounded-xl p-4 flex flex-col max-h-72", isHidden ? "bg-lighter-grey" :"bg-lightest-grey", visible ? "visible" : "hidden")}>
      <div className="flex">
        <div className="flex flex-col">
          <div className="font-bold">{review.reviewer.first_name} {review.reviewer.last_initial}</div>
          <div className="text-electric-indigo">{review.reviewer.company} {review.reviewer.role ? `- ${review.reviewer.role}` : ''}</div>
        </div>
        {!isPublicProfile && <div className="ml-auto mr-px">
          <PointedTooltip
            selfManaged
            translate="-translate-x-25"
            msg={`${isHidden ? "Show on" : "Hide from"} public profile`}
          >
            <HideBtn
              show={Boolean(isHidden)}
              onClick={async () => {
                await patchCandidateReview(review.id, {
                  is_hidden: !isHidden
                });
                setIsHidden(!isHidden)
              }}
            />
          </PointedTooltip>
        </div>}
      </div>
      <div className="text-kasmir my-2 whitespace-pre-wrap overflow-hidden" style={{maxHeight: '200px'}}>
        <div className="relative overflow-hidden line-clamp-5" ref={textRef} style={{maxHeight: '160px', textOverflow: "ellipsis"}}>
          {review.public_feedback}
        </div>
        {isTruncated &&
          <div className="my-2">
            <a className="text-electric-indigo underline cursor-pointer" onClick={onClickReadMore}>
              Read More
            </a>
          </div>
        }
      </div>
      <div className="flex mt-auto">
        <div className="text-sm font-bold pr-1">{averageRating}</div>
        <PointedTooltip
          selfManaged
          translate="-translate-x-0"
          msg={
            <ReviewTooltip
              professionalismRating={review.professionalism_rating}
              dependabilityRating={review.dependability_rating}
              qualityOfWorkRating={review.quality_of_work_rating}
              domainExpertiseRating={review.domain_expertise_rating}
            />
          }
        >
          <RatingInput value={averageRating} disabled={true} />
        </PointedTooltip>
        <div className="ml-auto mr-px">
          {(new Date(review.created_at).toLocaleDateString('en-US'))}
        </div>
      </div>
    </div>
  )
}

const Reviews = ({ talentData, isPublicProfile }) => {
  const [modal, setModal] = useState();
  const [firstVisibleReviewId, setFirstVisibleReviewId] = useState(0);
  const onClickPrev = () => setFirstVisibleReviewId(firstVisibleReviewId - 3);
  const onClickNext = () => setFirstVisibleReviewId(firstVisibleReviewId + 3);
  const { reviews } = talentData;

  const visibleReviews = useMemo(() => {
    if (!reviews) return;
    return isPublicProfile ? reviews.filter(r => r.is_hidden !== true) : reviews;
  }, [reviews])
  
  return (
    <div>
    { isPublicProfile && !visibleReviews?.length ? <></> :
    <div className="bg-white rounded-extra-large p-4 flex flex-col gap-4"> 
      <div className="flex gap-2 items-center">
        <div className="text-lg font-bold text-midnight">Reviews</div>
        <Pill value={visibleReviews?.length || 0} />
      </div>
      {visibleReviews?.length ? <>
        <div className="flex flex-col sm:flex-row gap-4 text-sm sm:grid sm:grid-cols-3 sm:grid-rows-1">
          {visibleReviews?.map((r, i) =>
            <Review
              review={r}
              visible={firstVisibleReviewId <= i  && i <= (firstVisibleReviewId + 2)}
              onClickReadMore={() => setModal(r.id)}
              isPublicProfile={isPublicProfile}
            />
          )}
        </div>
        {visibleReviews?.length > 3 &&
          <ReviewNavigation
            onClickPrev={onClickPrev}
            prevDisabled={firstVisibleReviewId === 0}
            onClickNext={onClickNext}
            nextDisabled={Math.abs((visibleReviews.length - 1) - firstVisibleReviewId) < 3}
          />
        }
        </> : 
        !isPublicProfile &&
        <div className="flex flex-col">
          <div className="h-56 flex justify-center">
            <img src={placeholderImg} className="object-contain" alt="Reviews" />
          </div>
          <div className="mx-auto w-3/5 text-kasmir text-center pb-2">
            {$.reviews_placeholder}
          </div>
        </div>
        }
      </div>
    }
    {modal && <ReviewDetail review={visibleReviews?.find(r => r.id === modal)} onClose={() => setModal()} />}
    </div>
  )
};

export default Reviews;