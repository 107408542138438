import {
  lastUpdatedFormatted,
  projectStageFormatted,
  candidateStatusFormatted
} from "../utils";

const zeroToNA = (value) => (value?.toString() === "0" ? "N/A" : value);

// Tailwind isn't able to handle dynamic classes that well, so we need to be 
// explicit about the column span classes.
export const getColSpanClass = (span) => {
  const spanClasses = {
    1: "col-span-1",
    2: "col-span-2",
    3: "col-span-3",
    4: "col-span-4",
    5: "col-span-5",
    6: "col-span-6"
  };
  return spanClasses[span] || "col-span-1";
};

const unstartedProjectCols = [
  {
    label: "Name",
    value: (p) => p.project_name,
    colspan: 4
  },
  {
    label: "Project Owner",
    value: (p) => p.project?.owners?.join(", "),
    colspan: 3
  },
  {
    label: "Candidate Status",
    value: (p) => candidateStatusFormatted(p.status),
    colspan: 2
  },
  {
    label: "Project Stage",
    value: (p) => projectStageFormatted(p.project_stage),
    colspan: 2
  },
  {
    label: "Estimated Hours",
    value: (p) => zeroToNA(p.project?.max_hours) ?? "",
    colspan: 2
  },
  {
    label: "Project Updated",
    value: (p) => lastUpdatedFormatted(p.project_last_updated),
    colspan: 2
  }
];

const startedProjectCols = [
  {
    label: "Name",
    value: (p) => p.project_name,
    colspan: 4
  },
  {
    label: "Project Owner",
    value: (p) => p.project?.owners?.join(", "),
    colspan: 3
  },
  {
    label: "Project Stage",
    value: (p) => projectStageFormatted(p.project_stage),
    colspan: 2
  },
  {
    label: "Estimated Hours",
    value: (p) => zeroToNA(p.utilization_hours) ?? "",
    colspan: 2
  },
  {
    label: "Pay Rate",
    value: (p) =>
      p.agreed_rate === null
        ? ""
        : p.agreed_rate.toString() === "0"
          ? "N/A"
          : `$${p.agreed_rate}`,
    colspan: 1
  },
  {
    label: "Project Updated",
    value: (p) => lastUpdatedFormatted(p.project_last_updated),
    colspan: 2
  }
];

/**
 * The columns for the project list. The status is used to determine which table content to display.
 * It's consolidated here to make it easier to keep the header and table content in sync, as well as
 * reduce duplication across the statuses.
 */
export const statusGridConfig = {
  candidate: { cols: unstartedProjectCols },
  active: { cols: startedProjectCols },
  completed: { cols: startedProjectCols },
  rejected: { cols: unstartedProjectCols },
  inactive: { cols: unstartedProjectCols }
};
