const IS_PROD = !process.env.REACT_APP_API_URL.includes("staging") &&
  !process.env.REACT_APP_API_URL.includes("localhost");
export const ALGOLIA_INDEX_NAMES = {
  talent: IS_PROD ? 'talent_index' : 'staging_talent_index',
  company: IS_PROD ? 'company_index' : 'staging_company_index',
  contact: IS_PROD ? 'contact_index' : 'staging_contact_index',
  project: IS_PROD ? 'project_index' : 'staging_project_index',
  // unused
  // project_notes: IS_PROD ? 'project_notes_index' : 'staging_project_notes_index',
  // contact_notes: IS_PROD ? 'contact_notes_index' : 'staging_contact_notes_index',
  // company_notes: IS_PROD ? 'company_notes_index' : 'staging_company_notes_index',
};
