import React, { useEffect } from "react";
import { admin_settings_email as $ } from "strings";
import { B2DB } from "components/Typography";
import Section from "components/Section";
import UilEnvelope from "@iconscout/react-unicons/icons/uil-envelope";
import { usePageCache } from "contexts/pageCacheContext";
import { Checkbox } from "components/Form";
import {
  getSettingsEmail,
  postSettingsEmail,
  patchSettingsEmail,
} from "utils/adminApi";
import { LoadPlaceholderGroup } from "components/LoadPlaceholder";
import useCachedFetch from "hooks/useCachedFetch";
import { getAdminUserId } from "utils/localStorageService";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";

const stageOptions = [
  { label: $.pitching_option, value: "pitching" },
  { label: $.shadow_pitched_option, value: "shadow_pitched" },
  { label: $.matching_no_support_option, value: "matching_no_support" },
  { label: $.matching_support_option, value: "matching_support" },
  { label: $.sourcing_option, value: "sourcing" },
  { label: $.submitted_option, value: "submitted" },
  { label: $.contracting_option, value: "contracting" },
  { label: $.pipelines_option, value: "pipelines" },
  { label: $.passive_option, value: "passive" },
  { label: $.filled_option, value: "filled" },
  { label: $.lost_option, value: "lost" },
  { label: $.completed_option, value: "completed" },
];

const Email = () => {
  const alert = useAlert();
  const { set } = usePageCache();
  const userId = getAdminUserId();

  useEffect(() => {
    set("url_settings", "/admin/settings/email");
  }, [set]);

  const { data, setData } = useCachedFetch(
    "admin_setting_email",
    getSettingsEmail,
    userId
  );

  useEffect(() => {
    const call = async () => {
      try {
        const response = await postSettingsEmail({
          user: userId,
        });
        setData(response);
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} />);
      }
    };
    if (data && !data.user) {
      call();
    }
  }, [data, alert, userId, setData]);

  if (!data || (data && !data.user)) {
    return (
      <div className="bg-white px-4 py-6 mb-6">
        <LoadPlaceholderGroup />
      </div>
    );
  }

  const changeValue = async (key, selectedValue) => {
    try {
      if (data.user) {
        /*
        value == 1 means all projects
        value == 0 means no subscription
        all other values indicate subscription for a specific project role:
            2 - Project Owner
            3 - Customer Engagement (CE)
            5 - Ops Support
        any other number means it's a combination of the above that is
        a result of multiplication of role numbers (hence prime numbers):
        eg. 6 = 2 x 3 -> Project Owner + CE role notifications
        This system was implemented to maintain backwards compatibility.
        */
        let currentValue = data[key];
        let newValue;
        if (currentValue === 0) {
          newValue = selectedValue
        } else if (selectedValue === currentValue) {
          newValue = 0
        } else if (selectedValue === 1) {
          newValue = 1
        } else if (currentValue % selectedValue === 0) {
          newValue = currentValue / selectedValue
        } else {
          newValue = currentValue * selectedValue
        }

        setData({ ...data, [key]: newValue });
        await patchSettingsEmail(data.id, { [key]: newValue });
      }
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} />);
    }
  };

  return (
    <Section id="admin_settings_email" className="bg-white">
      <div className="w-full flex flex-col xl:flex-row">
        <div className="w-full">
          <div className="bg-white px-4 py-6 mb-6">
            <div className="mb-4">
              <div className="flex items-center mb-2">
                <B2DB className="flex flex-1 items-center">
                  <UilEnvelope size="18" className="mr-1" />
                  {$.title}
                </B2DB>
              </div>
            </div>
            <div>
              <B2DB>{$.subtitle}</B2DB>
            </div>
            <div className="flex items-center mt-4 border-link-water border-b pb-2 space-x-2">
              <B2DB className="w-2/6">{$.stage_header}</B2DB>
              <B2DB className="w-1/6 text-center">{$.notify_header}</B2DB>
              <B2DB className="w-1/6 text-center">{$.owner_header}</B2DB>
              <B2DB className="w-1/6 text-center">{$.ce_header}</B2DB>
              <B2DB className="w-1/6 text-center">{$.ops_support_header}</B2DB>
            </div>
            {stageOptions.map((s) => (
              <div
                key={s.value}
                className="flex items-center py-2 space-x-2 border-link-water border-b hover:bg-link-water px-2"
              >
                <div className="w-2/6 font-bold text-kasmir text-sm ">
                  {s.label}
                </div>
                <div className="w-1/6 flex justify-center">
                  <Checkbox
                    onChange={() =>
                      changeValue(s.value, 1)
                    }
                    value={data[s.value] === 1}
                  />
                </div>
                <div className="w-1/6 flex justify-center">
                  <Checkbox
                    onChange={() =>
                      changeValue(s.value, 2)
                    }
                    value={data[s.value] !== 0 && data[s.value] % 2 === 0}
                  />
                </div>
                <div className="w-1/6 flex justify-center">
                  <Checkbox
                    onChange={() =>
                      changeValue(s.value, 3)
                    }
                    value={data[s.value] !== 0 && data[s.value] % 3 === 0}
                  />
                </div>
                <div className="w-1/6 flex justify-center">
                  <Checkbox
                    onChange={() =>
                      changeValue(s.value, 5)
                    }
                    value={data[s.value] !== 0 && data[s.value] % 5 === 0}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Email;
