import React from "react";

const NavLeftIcon = () => {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34.65 17.5C34.65 26.9717 26.9717 34.65 17.5 34.65C8.02832 34.65 0.35 26.9717 0.35 17.5C0.35 8.02832 8.02832 0.35 17.5 0.35C26.9717 0.35 34.65 8.02832 34.65 17.5Z" stroke="#3049C5" strokeWidth="0.7"/>
      <path d="M26.5996 17.5H9.09961M9.09961 17.5L12.6607 21M9.09961 17.5L12.6607 14" stroke="#3049C5" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
};

export default NavLeftIcon;


