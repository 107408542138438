import React from "react";
import Label from "./Label";
import $ from "strings/talent";
import LoaderSimple from "../assets/LoaderSimple.gif";

const AvailabilityCalculator = ({ hoursAvailable, hoursUtilized, showLoader }) => {
  return (
    <div className="flex flex-col gap-2 w-full sm:w-1/2 mt-2 mb-4 sm:mb-8 p-4 bg-lightest-grey border-1-geyser rounded-xl">
      <Label>{$.availability_calculator_title}</Label>
      <div className="text-sm">{$.availability_calculator_subtitle}</div>
        {hoursAvailable ? 
          <>
            <div className="w-full flex text-kasmir text-sm">
              <div>{$.availability_calculator_utilized_label}</div>
              <div className="font-bold mr-0 ml-auto">{hoursUtilized || 0}</div>
            </div>
            <div className="w-full flex text-kasmir text-sm">
            <div>{$.availability_calculator_available_label}</div>
            <div className="font-bold mr-0 ml-auto">{hoursAvailable - (hoursUtilized ? hoursUtilized : 0)}</div>
            </div>
          </> : showLoader &&
          <div className="flex w-full mx-0 my-auto justify-center items-center h-20">
            <img className="max-w-full max-h-full" src={LoaderSimple} alt="Calculating..." />
          </div>
      }
    </div>
  );
};

export default AvailabilityCalculator;
