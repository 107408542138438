import React, { useState, useEffect, useMemo } from "react";
import { admin_talent as $ } from "strings";
import { NavLink, Switch, Route, useParams, Link } from "react-router-dom";
import { BackLinkBtn, MergeButton, OutlinedButton } from "components/Buttons";
import UilUnion from "@iconscout/react-unicons/icons/uil-pathfinder-unite";
import UilArrowUpLeft from "@iconscout/react-unicons/icons/uil-arrow-up-left";
import UilTelegram from "@iconscout/react-unicons/icons/uil-telegram-alt";
import UilCopy from "@iconscout/react-unicons/icons/uil-copy";
import UilTrashAlt from "@iconscout/react-unicons/icons/uil-trash-alt";
import Overview from "./Overview/index";
import Notes from "./Notes/index";
import Attachments from "./Attachments/index";
import Experiences from "./Experiences";
import Projects from "./Projects";
import Roles from "./Stakeholders";
import { usePageCache } from "contexts/pageCacheContext";
import {
  getTalentOwners,
  getAccountV2,
  getTalentCreatedProjectsV2,
  getTalentQualificationsV2
} from "utils/adminApi";
import Section from "components/Section";
import { toFormat } from "utils/date";
import { getTalentProfileCompletion } from "utils/misc";
import useCachedFetch from "hooks/useCachedFetch";
import { LoadPlaceholderGroup } from "components/LoadPlaceholder";
import DeleteForm from "../DeleteForm";
import Clockify from "../Clockify";
import { getAdminFlags } from "utils/localStorageService";
import InvitationForm from "../InvitationForm";
import TalentHeader from "./Header";
import { useAlert } from "react-alert";

const Page = () => {
  const [modal, setModal] = useState();
  const { id } = useParams();
  const { cache } = usePageCache();
  const flags = getAdminFlags();
  const alert = useAlert();

  const { data, setData, pageState } = useCachedFetch(
    "admin_talent_v2",
    getAccountV2,
    id
  );

  const { data: experiencesData } = useCachedFetch(
    "admin_talent_experiences",
    getTalentCreatedProjectsV2,
    id
  );

  const owners = useCachedFetch("admin_talent_roles", getTalentOwners, id);

  const talentQualifications = useCachedFetch(
    "admin_talent_qualifications_v2",
    getTalentQualificationsV2,
    id,
    { page_size: 9999 }
  );

  const talentProfileCompletion = useMemo(() => {
    if (!data || !experiencesData || !talentQualifications) return;
    let talentData = {
      ...data,
      talent_created_projects: experiencesData?.results,
      qualifications: talentQualifications?.data?.results
    };
    return getTalentProfileCompletion(talentData);
  }, [data, experiencesData, talentQualifications]);

  const talentOwners = useMemo(() => {
    let commaSeparatedOwners = "";
    if (owners?.data && Array.isArray(owners?.data?.results)) {
      owners?.data?.results.forEach(({ owner }, i) => {
        commaSeparatedOwners += `${owner.first_name} ${owner.last_name}`;
        if (i !== owners?.data?.results.length - 1)
          commaSeparatedOwners += ", ";
      });
    }

    return commaSeparatedOwners;
  }, [owners.data]);

  useEffect(() => {
    if (!data) return;
    document.title = "Pipeline - " + data?.fullname;
  }, [data]);

  return (
    <Section id="admin_talent" className="flex h-full flex-col">
      <Switch>
        <Route path="/admin/talent/:id/projects">
          <div className="flex min-h-0 flex-1 flex-col">
            <Link
              to={`/admin/talent/${id}`}
              className="mx-2.5 mt-2.5 flex items-center rounded-xl bg-white p-2.5"
            >
              <UilArrowUpLeft size="18" className="mr-2" />
              <div className="text-sm">Back to Talent Overview</div>
            </Link>
            <div className="min-h-0 flex-1">
              <Projects data={data} />
            </div>
          </div>
        </Route>
        <Route path="*">
          <div className="-mt-px flex items-center justify-between">
            <BackLinkBtn to="/admin/talent" text={$.back_talent} />
            {!!data && (
              <div className="text-right text-xs font-light text-kasmir">
                {$.created_at_label}{" "}
                <span className="lowercase">
                  {!!data?.created_at &&
                    toFormat(new Date(data.created_at), "MM/dd/yyyy h:mma")}
                </span>
                <br />
                {$.updated_at_label}{" "}
                <span className="lowercase">
                  {!!data?.updated_at &&
                    toFormat(new Date(data.updated_at), "MM/dd/yyyy h:mma")}
                </span>
              </div>
            )}
          </div>
          <div className="flex flex-col bg-white">
            {pageState === "loading" || !data ? (
              <div className="p-2">
                <LoadPlaceholderGroup />
              </div>
            ) : (
              <>
                {data && (
                  <TalentHeader
                    data={data}
                    setData={setData}
                    talentOwners={talentOwners}
                    talentProfileCompletion={talentProfileCompletion}
                  />
                )}
                <div className="flex w-full bg-link-water">
                  <div className="border-link-ligher flex w-full flex-shrink-0 flex-row border-b border-t">
                    <div className="flex flex-1">
                      <NavLink
                        exact
                        to={
                          cache["url_talent_overview"] ||
                          `/admin/talent/${id}/overview`
                        }
                        className="flex h-5 items-center border-l border-caded px-2 text-sm font-medium text-kasmir"
                        activeClassName="font-bold"
                      >
                        {$.overview_link}
                      </NavLink>
                      <NavLink
                        exact
                        to={
                          cache["url_talent_notes"] ||
                          `/admin/talent/${id}/notes`
                        }
                        className="flex h-5 items-center border-l border-caded px-2 text-sm font-medium text-kasmir"
                        activeClassName="font-bold"
                      >
                        {$.notes_link}
                      </NavLink>
                      <NavLink
                        exact
                        to={
                          cache["url_talent_attachments"] ||
                          `/admin/talent/${id}/attachments`
                        }
                        className="flex h-5 items-center border-l border-caded px-2 text-sm font-medium text-kasmir"
                        activeClassName="font-bold"
                      >
                        {$.attachments_link} {` (${data.attachments_count})`}
                      </NavLink>
                      <NavLink
                        exact
                        to={
                          cache["url_talent_experiences"] ||
                          `/admin/talent/${id}/experiences`
                        }
                        className="flex h-5 items-center border-l border-caded px-2 text-sm font-medium text-kasmir"
                        activeClassName="font-bold"
                      >
                        {$.experiences_link}
                      </NavLink>
                      <NavLink
                        exact
                        to={
                          cache["url_talent_projects"] ||
                          `/admin/talent/${id}/projects`
                        }
                        className="flex h-5 items-center border-l border-caded px-2 text-sm font-medium text-kasmir"
                        activeClassName="font-bold"
                      >
                        {$.projects_v2_link}
                      </NavLink>
                      <NavLink
                        to={`/admin/talent/${id}/roles`}
                        className="flex h-5 items-center border-l border-caded px-2 text-sm font-medium text-kasmir"
                        activeClassName="font-bold"
                      >
                        {$.roles_link}
                      </NavLink>
                      {flags.clockify_talent_enabled && (
                        <NavLink
                          to={`/admin/talent/${id}/clockify`}
                          className="flex h-5 items-center border-l border-r border-caded px-2 text-sm font-medium text-kasmir"
                          activeClassName="font-bold"
                        >
                          {$.clockify_link}
                        </NavLink>
                      )}
                    </div>
                    <div className="flex items-center gap-2">
                      <MergeButton
                        to={`/admin/talent/${id}/merge`}
                        className="ml-2"
                      >
                        <UilUnion size="16" className="mr-1" />
                        {$.merge_btn}
                      </MergeButton>
                      <OutlinedButton onClick={() => setModal("invite")}>
                        <UilTelegram size="16" className="mr-1" />
                        {data.onboard_last_email_at
                          ? $.resend_invite_btn
                          : $.invite_btn}
                      </OutlinedButton>
                      <OutlinedButton
                        onClick={() => {
                          const baseURL = window.location.origin;
                          let suffix = data.url_suffix;
                          const publicURL = `${baseURL}/talent/${suffix}`;
                          navigator.clipboard.writeText(publicURL);
                          alert.success("Copied to clipboard", {
                            timeout: 2000
                          });
                        }}
                      >
                        <UilCopy size="16" className="mr-1" />
                        {$.copy_public_url_btn}
                      </OutlinedButton>
                      <button
                        type="button"
                        className="focus:outline-none group h-5"
                        onClick={() => setModal("delete")}
                      >
                        <span
                          className="focus:outline-none inline-flex h-5 items-center justify-center rounded bg-white px-1 text-sm font-bold text-red-dark hover:bg-red-dark hover:text-white active:bg-red-darker group-focus:ring"
                          tabIndex="-1"
                        >
                          <UilTrashAlt size="20" className="mr-1" />
                          {$.delete_talent_btn}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="min-h-0 flex-1">
            <div className="h-full flex-1">
              <Switch>
                <Route exact path="/admin/talent/:id">
                  <Overview
                    data={data}
                    setData={setData}
                    talentQualifications={talentQualifications}
                  />
                </Route>
                <Route path="/admin/talent/:id/overview">
                  <Overview
                    data={data}
                    setData={setData}
                    talentQualifications={talentQualifications}
                  />
                </Route>
                <Route path="/admin/talent/:id/notes">
                  <Notes data={data} />
                </Route>
                <Route path="/admin/talent/:id/attachments">
                  <Attachments data={data} setData={setData} />
                </Route>
                <Route path="/admin/talent/:id/experiences">
                  <Experiences data={experiencesData} />
                </Route>

                <Route path="/admin/talent/:id/roles">
                  <Roles
                    talentOwners={talentOwners}
                    owners={owners}
                    data={data}
                    setData={setData}
                  />
                </Route>
                <Route path="/admin/talent/:id/clockify">
                  <Clockify data={data} setData={setData} />
                </Route>
              </Switch>
            </div>
          </div>
        </Route>
      </Switch>
      {modal === "delete" && <DeleteForm data={data} onClose={setModal} />}
      {modal === "invite" && (
        <InvitationForm data={data} setData={setData} onClose={setModal} />
      )}
    </Section>
  );
};

export default Page;
