import React from "react";

const ProfileIcon = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.60229 8.5C10.7013 8.5 11.653 8.10582 12.4305 7.32809C13.2081 6.55049 13.6023 5.59905 13.6023 4.49987C13.6023 3.40107 13.2081 2.44951 12.4304 1.67166C11.6527 0.894184 10.7012 0.5 9.60229 0.5C8.50315 0.5 7.55174 0.894184 6.77417 1.67178C5.99659 2.44938 5.60229 3.40095 5.60229 4.49987C5.60229 5.59905 5.99659 6.55062 6.77429 7.32822C7.55199 8.10569 8.50353 8.5 9.60229 8.5Z"
        fill="#16325C"
      />
      <path
        d="M16.5684 13.4775C16.5457 13.1383 16.4999 12.7682 16.4324 12.3775C16.3643 11.9838 16.2766 11.6116 16.1716 11.2715C16.0632 10.9199 15.9157 10.5727 15.7334 10.24C15.5442 9.89463 15.3219 9.59392 15.0725 9.34647C14.8117 9.0876 14.4924 8.87947 14.1232 8.72766C13.7553 8.57664 13.3475 8.50013 12.9113 8.50013C12.74 8.50013 12.5744 8.57305 12.2545 8.78915C12.0576 8.92237 11.8272 9.07645 11.5702 9.24686C11.3503 9.39216 11.0526 9.52831 10.6847 9.65156C10.3259 9.77203 9.96153 9.83313 9.60191 9.83313C9.24229 9.83313 8.87806 9.77203 8.51882 9.65156C8.15139 9.52844 7.85361 9.3923 7.63405 9.24699C7.3794 9.07817 7.14896 8.9241 6.94911 8.78902C6.62956 8.57292 6.46377 8.5 6.29248 8.5C5.85617 8.5 5.44854 8.57664 5.08072 8.72779C4.71176 8.87934 4.39233 9.08747 4.13129 9.34661C3.88203 9.59419 3.65965 9.89476 3.47069 10.24C3.28851 10.5727 3.14102 10.9197 3.03246 11.2716C2.92761 11.6117 2.83991 11.9838 2.7718 12.3775C2.70433 12.7677 2.6585 13.1379 2.63584 13.4779C2.61356 13.811 2.60229 14.1567 2.60229 14.5059C2.60229 15.4147 2.88075 16.1504 3.42985 16.693C3.97216 17.2284 4.68974 17.5 5.56235 17.5H13.6422C14.5149 17.5 15.2322 17.2285 15.7746 16.693C16.3238 16.1508 16.6023 15.415 16.6023 14.5058C16.6022 14.155 16.5908 13.809 16.5684 13.4775Z"
        fill="#16325C"
      />
    </svg>
  );
};

export default ProfileIcon;
