import $ from "../strings/talent.json";
import clsx from "clsx";
import React from "react";
import Logo from "./Logo";
import { LinkPreview } from "./LinkPreview";
import { getProjectCompletion } from "../utils/misc";
import completeness_red from "assets/completeness_red.png";
import { QualificationsDisplay } from "./QualificationsDisplay";
import companyLogoPlaceholder from "assets/company_logo_placeholder.png";
import { getProjectInfo, getProjectTime } from "../utils/projects";
import { PointedTooltip } from "pages/talent/components";

const ProjectExperienceListItem = ({
  project,
  showCompletenessBadge,
  editButtons,
  compact
}) => {
  return (
    <div className="flex flex-col gap-3 rounded-large bg-white p-4 md:ml-16">
      <div className="flex flex-row">
        <div className={clsx(project.is_hidden ? "opacity-50" : "")}>
          <div className="mr-auto flex flex-row gap-x-2.5">
            <div className="text-base font-bold">{project.name}</div>
            {project.managed_by_rsu && (
              <PointedTooltip
                selfManaged
                msg="Project managed by RSU"
                pointed
                translate="-translate-x-25"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="px-1.5">
                  <div className="mb-1 flex h-6.5 w-6.5 rounded-full bg-alice-blue text-sm text-kasmir">
                    <Logo className="mx-auto mt-px w-4" />
                  </div>
                </div>
              </PointedTooltip>
            )}
            <div className="flex">
              {showCompletenessBadge && getProjectCompletion(project) <= 20 ? (
                <PointedTooltip
                  selfManaged
                  msg={`This project is only ${getProjectCompletion(project)}% complete`}
                  pointed
                  translate="-translate-x-28"
                  onClick={(e) => e.stopPropagation()}
                >
                  <img
                    className="mx-auto my-auto mt-1 h-5 w-5"
                    src={completeness_red}
                    alt="completeness"
                  />
                </PointedTooltip>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="text-sm text-kasmir">{getProjectTime(project)}</div>
          <div className="text-sm text-kasmir">{getProjectInfo(project)}</div>
        </div>
        {editButtons}
      </div>
      {project.results && (
        <div
          className={clsx(
            "text-sm text-kasmir",
            project.is_hidden ? "opacity-50" : ""
          )}
        >
          {project.results}
        </div>
      )}
      {(!!project.links?.length || !!project.attached_files?.length) && (
        <div
          className={clsx(
            "flex flex-col gap-y-2.5",
            project.is_hidden ? "opacity-50" : ""
          )}
        >
          {project.links.map((link, i) => (
            <LinkPreview className="mr-auto" name={link.name} key={i} />
          ))}
          {project.attached_files.map((file, i) => (
            <LinkPreview
              className="mr-auto"
              name={file.filename}
              url={file.url}
              key={i}
            />
          ))}
        </div>
      )}
      {!!project.skills_and_tools?.length && (
        <div className={clsx(project.is_hidden ? "opacity-50" : "")}>
          {!compact && (
            <div className="mb-2 text-sm font-bold">
              {$.skills_and_tools_subtitle}
            </div>
          )}
          {project.skills_and_tools && (
            <QualificationsDisplay qualifications={project.skills_and_tools} />
          )}
        </div>
      )}
    </div>
  );
};

export const CompanyExperienceListItem = ({
  companyName,
  projects,
  showCompletenessBadge,
  editButtons,
  compact
}) => {
  return (
    <div className="flex flex-col gap-y-2.5 rounded-large bg-lightest-grey p-3.5">
      <div className="flex h-15 flex-row gap-x-2.5">
        <img
          className="h-15 w-15 rounded-lg"
          src={projects[0]?.company?.logo || companyLogoPlaceholder}
          onError={(e) => (e.target.src = companyLogoPlaceholder)}
          alt="logo"
        />
        <div className="my-auto flex-auto">
          <div className="text-base font-bold">{companyName}</div>
          <div className="text-sm text-kasmir">
            {projects.length} project
            {projects.length > 1 ? "s" : ""}
          </div>
        </div>
      </div>
      {projects.map((project, i) => (
        <ProjectExperienceListItem
          project={project}
          showCompletenessBadge={showCompletenessBadge}
          key={i}
          editButtons={editButtons && editButtons(project)}
          compact={compact}
        />
      ))}
    </div>
  );
};
