import React, { useEffect, useCallback } from "react";
import clsx from "clsx";
import $ from "strings/talent";
import UilTimes from "@iconscout/react-unicons/icons/uil-times";
import Section from "components/Section";
import useConfirmClose from "hooks/useConfirmClose";
import { TransparentButton } from "components/Buttons";
import { Button } from "./index";

/**
 * Renders a modal close button.
 * @param {callback} onClick - Callback to trigger on click.
 */
const CloseBtn = ({ onClick }) => (
  <button onClick={onClick} className="focus:outline-none group flex h-full">
    <span
      className="focus:outline-none inline-flex h-8 w-8 items-center justify-center rounded text-lg text-cove hover:bg-link-water active:bg-geyser group-focus:ring"
      tabIndex="-1"
    >
      <UilTimes size="24" />
    </span>
  </button>
);

/**
 * Renders a modal. It includes some features like confirmation prompt on close and a close button. It is used in all forms.
 * @param {component} children - Children to render inside, like a form with all its inputs.
 * @param {callback} onClose - Callback to trigger on close button click.
 * @param {string} id - Identifier to use with the Section component.
 * @param {boolean} isDirty - Flag to enable the confirmation feature.
 * @param {boolean} fullScreenOnMobile - Flag to indicate if the modal should take full screen on mobile.
 */
const Modal = ({
  children,
  onClose,
  id,
  isDirty,
  title,
  titleChildren,
  onClickSave,
  onClickCancel,
  buttonsDisabled,
  saveBtnText,
  showDelete,
  cancelBtnText,
  deleteBtnText,
  onClickDelete,
  fullScreenOnMobile = false,
  showActionBar = true,
  className = ""
}) => {
  const { canClose, confirmMsg } = useConfirmClose(isDirty);

  const onEscape = useCallback(
    (event) => {
      if (event.keyCode === 27 && canClose()) {
        onClose();
      }
    },
    [canClose, onClose]
  );

  useEffect(() => {
    // Prevent scrolling
    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", onEscape);

    return () => {
      document.removeEventListener("keydown", onEscape);
      document.body.style.overflow = "auto";
    };
  }, [onEscape]);

  return (
    <div
      className={clsx(
        fullScreenOnMobile
          ? "h-dvh sm:h-screen sm:px-10 sm:py-12"
          : "h-screen px-10 py-12",
        "fixed left-0 top-0 z-50 flex w-screen items-center justify-center text-midnight"
      )}
    >
      <div
        onClick={() => {
          if (canClose()) {
            onClose();
          }
        }}
        className="absolute left-0 top-0 z-40 h-full w-full"
        style={{ backgroundColor: "rgba(31, 34, 39, 0.5)" }}
      />
      <Section
        id={id}
        className={
          "z-50 mx-auto flex max-h-full w-full lg:w-3/5 xl:w-3/5 2xl:w-1/2"
        }
      >
        <div
          className={clsx(
            fullScreenOnMobile
              ? "h-screen sm:h-auto sm:rounded-xl"
              : "h-auto rounded-xl",
            className,
            "mb-0 mt-0 flex max-h-full w-full flex-col overflow-hidden bg-white"
          )}
        >
          <div className="flex border-b-2 p-5">
            <div className="text-xl font-bold">{title}</div>
            <div className="ml-auto mr-0 flex items-center justify-end gap-x-4">
              {!!titleChildren && <div>{titleChildren}</div>}
              <CloseBtn
                onClick={() => {
                  if (canClose()) {
                    onClose();
                  }
                }}
              />
            </div>
          </div>
          <div
            className={clsx(
              "overflow-y-auto px-5 py-3.5",
              fullScreenOnMobile && "flex-grow pb-56 sm:pb-3.5"
            )}
          >
            {children}
          </div>
          {showActionBar &&
            <div
              className={clsx(
                fullScreenOnMobile
                  ? "fixed bottom-0 left-0 right-0 bg-white p-5 sm:static sm:p-5"
                  : "flex-col-reverse p-2.5",
                "flex w-full items-center gap-2.5 border-t-2 sm:flex-row"
              )}
            >
              {showDelete && (
                <TransparentButton
                  disabled={buttonsDisabled}
                  className="text-sm text-electric-indigo underline"
                  onClick={onClickDelete}
                >
                  <span>{deleteBtnText || "Delete"}</span>
                </TransparentButton>
              )}
              <div
                className={clsx(fullScreenOnMobile ? "ml-auto" : "sm:ml-auto")}
              >
                <Button
                  disabled={buttonsDisabled}
                  onClick={onClickCancel || onClose}
                  cancel
                >
                  <span>{cancelBtnText || $.cancel_btn}</span>
                </Button>
              </div>
              <Button disabled={buttonsDisabled} onClick={onClickSave}>
                <span>{saveBtnText || $.save_btn}</span>
              </Button>
            </div>
            }
          </div>
      </Section>
    </div>
  );
};

export default Modal;
