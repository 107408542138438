import React from "react";

const ShowIcon = ({ fill = "#54698D", size = "18" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1626_23132)">
        <path
          d="M9.60426 12.0657C11.2974 12.0657 12.6699 10.6931 12.6699 9.00001C12.6699 7.30688 11.2974 5.93433 9.60426 5.93433C7.91113 5.93433 6.53857 7.30688 6.53857 9.00001C6.53857 10.6931 7.91113 12.0657 9.60426 12.0657Z"
          fill={fill}
        />
        <path
          d="M18.5253 8.52098C16.433 4.68256 13.0006 2.83272 9.60182 2.81055C5.70483 2.81055 2.42732 5.25072 0.708524 8.47678C0.598567 8.68316 0.547901 9.16378 0.70864 9.52536C2.50831 12.8803 5.9322 15.1893 9.59881 15.1893C13.2654 15.1893 16.7429 12.859 18.5253 9.44304C18.6286 9.24508 18.6273 8.7314 18.5253 8.52098ZM9.59881 13.3479C7.34423 13.3479 5.26264 11.3397 5.26264 8.99787C5.26264 6.65603 7.25589 4.6879 9.60182 4.6879C11.9478 4.6879 13.9226 6.65603 13.9226 8.99787C13.9226 11.3397 12.0011 13.3479 9.59881 13.3479Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1626_23132">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.602295)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShowIcon;
