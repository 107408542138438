import React from "react";
import { admin as $ } from "strings";
import { usePageCache } from "contexts/pageCacheContext";
import { Sidebar, Link, Button } from "components/Sidebar";
import UilUsers from "@iconscout/react-unicons/icons/uil-users-alt";
import UilSuitcase from "@iconscout/react-unicons/icons/uil-suitcase";
import UilBuilding from "@iconscout/react-unicons/icons/uil-building";
import UilCog from "@iconscout/react-unicons/icons/uil-cog";
import UilUserSquare from "@iconscout/react-unicons/icons/uil-user-square";
import UilSignOut from "@iconscout/react-unicons/icons/uil-sign-out-alt";
import { clearLoginToken } from "utils/localStorageService";
import { getAdminFlags } from "utils/localStorageService";

const Nav = ({ setAdminLogged }) => {
  const { cache } = usePageCache();
  const flags = getAdminFlags();

  return (
    <Sidebar>
      <div className="flex-1 flex flex-col">
        <Link
          to="/admin/talent"
          Icon={UilUsers}
          title={$.admin_nav_talent + " (New)"}
        />
        {flags.projects_v2_enabled && (
          <Link
            cached={cache["url_project"]}
            to="/admin/project"
            Icon={UilSuitcase}
            title={$.admin_nav_projects + " (New)"}
          />
        )}
        <Link
          cached={cache["url_company"]}
          to="/admin/company"
          Icon={UilBuilding}
          title={$.admin_nav_company}
        />
        <Link
          cached={cache["url_contact"]}
          to="/admin/contacts"
          Icon={UilUserSquare}
          title={$.admin_nav_contacts}
        />
      </div>
      <div className="flex flex-col">
        {flags && flags.user_email_settings_enabled && (
          <Link
            cached={cache["url_settings"]}
            to="/admin/settings/email"
            Icon={UilCog}
            title={$.admin_nav_settings}
          />
        )}
        <Button
          title={$.admin_nav_signout}
          onClick={() => {
            clearLoginToken();
            setAdminLogged(false);
            window.location.href = "/login";
          }}
          Icon={UilSignOut}
        />
      </div>
    </Sidebar>
  );
};

export default Nav;
