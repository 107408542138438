import React, { useState, useEffect } from "react";
import { admin_talent_overview as $ } from "strings";
import { toFormat } from "utils/date";
import {
  deleteTalentNoteV2,
  deleteStatusNote,
  patchTalentNoteV2,
  patchStatusNote
} from "utils/adminApi";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import EditNoteForm from "./EditNoteForm";
import { urlify } from "utils/str";
import { B2BLink } from "components/Typography";
import DropdownButtonMenu from "components/DropdownMenuButton";
import PinnedIcon from "assets/icons/Pinned";
import { getUserId } from "utils/localStorageService";

const Note = ({
  note,
  editable,
  optimisticallyUpdate,
  optimisitcallyRemove,
  reload,
  highlightedKeyword
}) => {
  const alert = useAlert();
  const [editing, setEditing] = useState();

  const loggedInUserId = getUserId();

  useEffect(() => {
    if (editing) {
      let el = document.getElementById(note.id);
      el?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest"
      });
    }
  }, [editing, note.id]);

  const onDelete = async () => {
    const call = async () => {
      optimisitcallyRemove(note.id);

      try {
        if (note.type === "status_history") {
          await deleteStatusNote(note.id);
        } else {
          await deleteTalentNoteV2(note.talent_ref, note.type, note.id);
        }
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} />);
      }

      await reload();
    };

    await call();
  };

  const setPinned = async (pinned) => {
    optimisticallyUpdate(note.id, { ...note, pinned: pinned });
    try {
      if (note.type === "status_history") {
        await patchStatusNote(note.id, { pinned: pinned });
      } else {
        await patchTalentNoteV2(note.talent_ref, note.type, note.id, {
          pinned: pinned
        });
      }
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} />);
    }

    await reload();
  };

  const onTogglePin = async () => {
    await setPinned(!note.pinned);
  };

  const author =
    note.created_by_name === "Crelate_Populate_Talents_Script"
      ? $.crelate_note_label
      : `${note.created_by_name || note.updated_by_name || $.unknown_author_label}`;

  const noteBody = highlightedKeyword
    ? note.note_body
        .split(/(<[^>]*>)/g)
        .map((part, i) => {
          if (part.startsWith("<")) {
            return part;
          }
          return part.replace(
            new RegExp(highlightedKeyword, "gi"),
            (match) => `<span class="bg-yellow-200">${match}</span>`
          );
        })
        .join("")
    : note.note_body;



  const editOptions = [
    {
      label: "Edit",
      onClick: () => setEditing(true)
    },
    { label: "Delete", onClick: onDelete },
    { label: note.pinned ? "Unpin" : "Pin", onClick: onTogglePin }
  ].filter((o) => {
    if (o.label === "Edit") return editable;
    if (o.label === "Delete") return note.updated_by_id === loggedInUserId;

    return true;
  });

  if (editing) {
    return (
      <div id={note.id}>
        <EditNoteForm
          note={note}
          optimisticallyUpdate={optimisticallyUpdate}
          reload={reload}
          setEditing={setEditing}
        />
      </div>
    );
  }

  return (
    <div id={note.id} className="rounded-md bg-white p-2.5">
      <div>
        <div className="flex items-center justify-between text-sm font-bold text-midnight">
          <div className="flex w-full items-center">
            <div className="text-midnight">{author}</div>

            {note.project_name && note.project_ref && (
              <>
                <p className="px-1.5 text-2xs font-bold">•</p>
                <B2BLink to={`/admin/project/${note.project_ref}`}>
                  <div className="text-electric-indigo">
                    {note.project_name}
                  </div>
                </B2BLink>
              </>
            )}
            {note.pinned && (
              <div className="ml-3 flex h-5 w-5 items-center justify-center rounded-full bg-alice-blue pb-0.5">
                <PinnedIcon size="13" fill="#54698D" />
              </div>
            )}
            <div className="ml-auto flex flex-row items-center gap-2.5">
              {note.created_timestamp && (
                <div className="text-sm font-normal text-grey">
                  {toFormat(new Date(note.created_timestamp), "MM/dd/yyyy")}
                </div>
              )}
              <DropdownButtonMenu
                label="Actions"
                compact
                options={editOptions}
              />
            </div>
          </div>
        </div>
        <div className="rich-content relative mt-2.5 min-h-0 whitespace-pre-line text-sm font-normal text-midnight">
          <div dangerouslySetInnerHTML={{ __html: urlify(noteBody) }} />
        </div>
      </div>
    </div>
  );
};

export default Note;
