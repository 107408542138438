import React from "react";

const SearchIcon = ({ size = 22, fill = "#16325C" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_92_18361)">
        <path
          d="M15.8178 14.9381L11.913 11.0334C12.8836 9.86621 13.4683 8.36744 13.4683 6.73438C13.4683 3.02103 10.4474 0 6.73421 0C3.02094 0 0 3.02103 0 6.73438C0 10.4475 3.02094 13.4683 6.73421 13.4683C8.36719 13.4683 9.86605 12.8836 11.0332 11.913L14.9381 15.8178C15.0595 15.9393 15.2188 16 15.3779 16C15.5371 16 15.6963 15.9393 15.8179 15.8178C16.0608 15.5748 16.0608 15.181 15.8178 14.9381ZM1.24414 6.73438C1.24414 3.70705 3.70696 1.24414 6.73421 1.24414C9.76137 1.24414 12.2241 3.70705 12.2241 6.73438C12.2241 9.76146 9.76137 12.2241 6.73421 12.2241C3.70696 12.2241 1.24414 9.76146 1.24414 6.73438Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_92_18361">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchIcon;
