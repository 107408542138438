import React from "react";
import ProjectActive from "assets/project_active.svg";
import ProjectCandidate from "assets/project_candidate.svg";
import ProjectCompleted from "assets/project_completed.svg";
import ProjectRejected from "assets/project_rejected.svg";
import ProjectInactive from "assets/project_inactive.svg";

const statusOptions = {
  active: {
    header: "No active projects",
    subtitle: "This talent is not currently working on any projects.",
    icon: ProjectActive
  },
  candidate: {
    header: "No projects in consideration",
    subtitle: "This talent is not considered for any open projects.",
    icon: ProjectCandidate
  },
  completed: {
    header: "No completed projects",
    subtitle: "This talent hasn't completed any projects yet.",
    icon: ProjectCompleted
  },
  rejected: {
    header: "No rejected projects",
    subtitle: "This talent hasn't been rejected from any projects.",
    icon: ProjectRejected
  },
  inactive: {
    header: "No inactive projects",
    subtitle: "This talent hasn't been considered for any projects.",
    icon: ProjectInactive
  }
};

const EmptyProjectState = ({ status }) => {
  const { header, subtitle, icon } = statusOptions[status];
  return (
    <div className="flex h-full flex-col items-center justify-center">
      <img src={icon} alt={header} className="h-40 w-40" />
      <h1 className="text-2xl font-bold">{header}</h1>
      <p className="text-gray-500">{subtitle}</p>
    </div>
  );
};

export default EmptyProjectState;
